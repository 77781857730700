<template>
  <v-row>
    <!-- Chart Section -->
    <v-col cols="12" md="7" class="pa-8">
      <v-card class="white-card pa-0 mt-6">
        <v-row class="pa-6" :style="{ 'border-bottom': '2px solid lightgrey' }">
          <h3>{{ selectedCoin }} Price History</h3>
        </v-row>
        <v-col class="pa-0">
          <apexchart
            type="line"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-col>
        <v-col class="pa-4">
          <v-row>
            <v-col cols="12" sm="4">
              <strong>Rate:</strong>
              {{
                coinDetails.rate !== null ? coinDetails.rate.toFixed(2) : "N/A"
              }}
            </v-col>
            <v-col cols="12" sm="4">
              <strong>Volume:</strong>
              {{
                coinDetails.volume !== null
                  ? coinDetails.volume.toLocaleString()
                  : "N/A"
              }}
            </v-col>
            <v-col cols="12" sm="4">
              <strong>Market Cap:</strong>
              {{
                coinDetails.cap !== null
                  ? coinDetails.cap.toLocaleString()
                  : "N/A"
              }}
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
    <!-- News Section -->
    <v-col cols="12" md="5" class="pa-8">
      <v-card class="white-card pa-0 mt-6">
        <v-row class="pa-6" :style="{ 'border-bottom': '2px solid lightgrey' }">
          <h3>Crypto News</h3>
        </v-row>
        <v-col class="pa-0">
          <v-row
            v-for="(article, index) in newsArticles"
            :key="index"
            class="pl-4"
          >
            <v-col cols="12" sm="2" md="3" lg="2">
              <v-row justify="center">
                <v-img
                  :src="article.image_url"
                  class="white-card pointer"
                  :style="{ width: '80px', height: '80px' }"
                  @click="redirectToNewsUrl(article.news_url)"
                ></v-img>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="10"
              md="9"
              lg="10"
              :style="{ 'border-bottom': '1px solid lightgrey' }"
            >
              <v-col class="pa-0">
                <v-row>
                  <h5>{{ article.title }}</h5>
                </v-row>
                <v-row>
                  <h5>{{ article.source_name }}</h5>
                  <h5 class="grey-text mx-2">
                    {{ new Date(article.date).toLocaleString() }}
                  </h5>
                </v-row>
                <v-row>
                  <p>{{ article.text }}</p>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      newsArticles: [],
      coins: [],
      selectedCoin: "BTC", // Default value if local storage is empty
      series: [],
      chartOptions: {
        chart: {
          type: "line",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM yyyy",
          },
        },
        yaxis: {
          title: {
            text: "Price (ZAR)",
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
        title: {
          text: " Price History",
          align: "left",
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
      },
      coinDetails: {
        rate: null,
        volume: null,
        cap: null,
      },
    };
  },
  created() {
    this.initializeSelectedCoin();
    this.fetchNewsArticles();
    this.fetchCoins();
    this.setupLocalStorageWatcher();
  },
  beforeDestroy() {
    if (this.localStorageWatcher) {
      clearInterval(this.localStorageWatcher);
    }
  },
  watch: {
    selectedCoin(newValue) {
      this.fetchChartData();
    },
  },
  methods: {
    initializeSelectedCoin() {
      const storedCurrencyId = localStorage.getItem("selectedCurrencyId");
      if (storedCurrencyId) {
        this.selectedCoin = storedCurrencyId.toUpperCase();
      }
    },
    setupLocalStorageWatcher() {
      this.localStorageWatcher = setInterval(() => {
        const storedCurrencyId = localStorage.getItem("selectedCurrencyId");
        if (
          storedCurrencyId &&
          storedCurrencyId.toUpperCase() !== this.selectedCoin
        ) {
          this.selectedCoin = storedCurrencyId.toUpperCase();
        }
      }, 1000); // Check every second
    },
    async fetchNewsArticles() {
      try {
        const response = await fetch(
          "https://cryptonews-api.com/api/v1/category?section=general&items=4&page=1&token=6jl2qhlyf17wddnhjijnll1isjh5o7hgoktpuzf6"
        );
        const data = await response.json();
        this.newsArticles = data.data;
      } catch (error) {
        console.error("Error fetching news articles:", error);
      }
    },
    async fetchCoins() {
      try {
        const response = await fetch(
          "https://api.livecoinwatch.com/coins/list",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "09642166-1649-4860-8d5a-97cfbead51af",
            },
            body: JSON.stringify({
              currency: "ZAR",
              sort: "rank",
              order: "ascending",
              offset: 0,
              limit: 100,
              meta: true,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Coins response data:", data); // Log the response
        this.coins = data.map((coin) => ({
          name: coin.name,
          code: coin.code,
          display: `${coin.code} (${coin.name})`,
          image: coin.png32,
          rate: coin.rate,
          volume: coin.volume,
          cap: coin.cap,
        }));
        this.fetchChartData(); // Fetch chart data after coins are loaded
      } catch (error) {
        console.error("Fetching coins list failed:", error);
      }
    },
    async fetchChartData() {
      try {
        const now = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(now.getMonth() - 6);

        const response = await fetch(
          "https://api.livecoinwatch.com/coins/single/history",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "09642166-1649-4860-8d5a-97cfbead51af",
            },
            body: JSON.stringify({
              currency: "ZAR",
              code: this.selectedCoin,
              start: sixMonthsAgo.getTime(),
              end: now.getTime(),
            }),
          }
        );

        console.log("Fetch chart data response:", response); // Log the response

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Chart data response:", data); // Log the response

        const seriesData = data.history.map((entry) => ({
          x: new Date(entry.date).toISOString(),
          y: entry.rate,
        }));

        this.series = [
          {
            name: `${this.selectedCoin} Price`,
            data: seriesData,
          },
        ];
        console.log("Chart series:", this.series); // Log the series data

        const coinDetail = this.coins.find(
          (coin) => coin.code === this.selectedCoin
        );
        this.coinDetails = {
          rate: coinDetail ? coinDetail.rate : 0,
          volume: coinDetail ? coinDetail.volume : 0,
          cap: coinDetail ? coinDetail.cap : 0,
        };
        console.log("Coin details:", this.coinDetails); // Log the coin details
      } catch (error) {
        console.error("Fetching chart data failed:", error);
      }
    },
    redirectToNewsUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
  .active {
    background-color: #dae8fc;
    color: #0066db;
  }
  .white-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 16px;
  }
  .coin-image {
    border-radius: 50%;
    object-fit: cover;
    width: 24px; /* Reduced width */
    height: 24px; /* Reduced height */
  }
</style>
