<template>
  <v-row class="identity" justify="center">
    <v-col>
      <v-row justify="center" v-if="!verifying">
        <v-card rounded class="white-card full-width pb-8">
          <h3 class="mx-3">Identity Verification</h3>
          <v-row justify="center">
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(card, index) in cards"
              :key="index"
            >
              <v-card outlined class="pa-8 height-230">
                <h4>{{ card.title }}</h4>
                <h5 class="mt-6">{{ card.description }}</h5>
                <v-icon class="green-text mt-6">mdi-check-circle</v-icon>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="px-3">
            <v-card outlined class="pa-4">
              <v-row justify="center">
                <v-col cols="12" sm="6" md="8">
                  <v-row justify="start">
                    <h4>Verification</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-4">
                      Verify your identity and get access to all features of the
                      HODL platform, including buying and selling crypto. It
                      will take just a few minutes.
                    </h5>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-row class="full-height" justify="end">
                    <v-btn
                      @click="verifying = true"
                      rounded
                      class="dark-blue-btn px-2"
                    >
                      Verification
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row justify="start" class="px-3">
            <h4 class="mt-4">
              Features that are unlocked with a successful Identity verification
            </h4>
          </v-row>
          <v-row justify="start">
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-for="(feature, index) in features"
              :key="index"
            >
              <FeatureCard
                :icon="feature.icon"
                :heading="feature.heading"
                :description="feature.description"
                :comingSoon="feature.comingSoon"
              />
            </v-col>
          </v-row>
          <v-row justify="start" class="px-3">
            <h4 class="mt-4">Limits</h4>
          </v-row>
          <div>
            <v-row
              :style="{ 'background-color': '#F3F6FD' }"
              justify="start"
              class="px-3 mt-4 outlined"
            >
              <v-col cols="4">
                <h4 class="text-center fs-80">Savings Wallet</h4>
              </v-col>
              <v-col cols="4">
                <h4 class="text-center fs-80">Top Up</h4>
              </v-col>
              <v-col cols="4">
                <h4 class="text-center fs-80">Withdraw</h4>
              </v-col>
            </v-row>
            <v-row justify="start" class="px-3 outlined">
              <v-col cols="4" v-for="(limit, index) in limits" :key="index">
                <h4 class="text-center fs-80">{{ limit }}</h4>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row
              :style="{ 'background-color': '#F3F6FD' }"
              justify="start"
              class="px-3 mt-8 outlined"
            >
              <v-col cols="4">
                <h4 class="text-center fs-80">Credit Line Wallet</h4>
              </v-col>
              <v-col cols="4">
                <h4 class="text-center fs-80">Top Up</h4>
              </v-col>
              <v-col cols="4">
                <h4 class="text-center fs-80">Withdraw</h4>
              </v-col>
            </v-row>
            <v-row justify="start" class="px-3 outlined">
              <v-col cols="4" v-for="(limit, index) in limits" :key="index">
                <h4 class="text-center fs-80">{{ limit }}</h4>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-row>

      <!-- Verification page -->

      <v-row justify="center" v-if="verifying">
        <v-dialog v-model="showDialog" width="500px" height="500px">
          <v-sheet
            elevation="12"
            max-width="600"
            rounded="lg"
            width="100%"
            class="pa-4 text-center mx-auto"
          >
            <v-icon class="animated-icon" color="green" size="112"
              >mdi-check-circle</v-icon
            >
            <h2 class="text-h5 mb-6 my-3">VERIFICATION CONFIRMATION</h2>
            <p class="mb-4 text-medium-emphasis text-body-2">
              Are you sure you would like to proceed with verification
              <br />
            </p>
            <v-divider class="mb-4"></v-divider>
            <div class="text-center">
              <v-btn
                class="text-none my-2"
                color="#07185c"
                rounded
                outlined
                dark
                variant="flat"
                width="200px"
                @click="handleConfirmation"
                >Yes</v-btn
              >
              <v-btn
                class="text-none mx-2 my-2"
                color="#07185c"
                rounded
                outlined
                dark
                variant="outlined"
                @click="showDialog = false"
                width="200px"
                >No</v-btn
              >
            </div>
          </v-sheet>
        </v-dialog>
        <v-card rounded class="white-card full-width pb-8">
          <v-row justify="start">
            <h2 class="mx-3">Personal Info</h2>
          </v-row>
          <v-row justify="start"> </v-row>
          <div justify="end">Progress Completion</div>
          <v-progress-linear
            :value="progress"
            color="blue"
            height="20"
            class="mb-6"
          >
            <div class="progress-bar-percentage">
              {{ Math.round(progress) }}%
            </div>
          </v-progress-linear>
          <v-row justify="start">
            <h4 class="mt-4 mx-3">Legal Name & Residential Address</h4>
          </v-row>

          <v-row justify="start" v-if="currentCollection === 'companies'">
            <!-- Company Registration Number -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Company Registration Number</p>
              <v-text-field
                v-model="userData.companyRegNumber"
                :hide-details="true"
                rounded
                placeholder="Company Registration Number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Company VAT -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Company VAT</p>
              <v-text-field
                v-model="userData.companyVAT"
                :hide-details="true"
                rounded
                placeholder="Company VAT"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="start" v-if="currentCollection === 'trusts'">
            <!-- Trust Name -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Trust Name</p>
              <v-text-field
                v-model="userData.TrustName"
                :hide-details="true"
                rounded
                placeholder="Trust Name"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Trust Registration Number -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Trust Registration Number</p>
              <v-text-field
                v-model="userData.trustRegNumber"
                :hide-details="true"
                rounded
                placeholder="Trust Registration Number"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start">
            <!-- ID Number -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">ID Number</p>
              <v-text-field
                v-model="userData.idNumber"
                :disabled="userData.passportNumber"
                :hide-details="true"
                rounded
                placeholder="ID Number"
                outlined
                dense
                :rules="[
                  (v) =>
                    (!!v && /^[0-9]{13}$/.test(v)) ||
                    'Enter a valid 13-digit ID number',
                ]"
              ></v-text-field>
            </v-col>

            <!-- Passport Number -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Passport Number</p>
              <v-text-field
                v-model="userData.passportNumber"
                :disabled="userData.idNumber"
                :hide-details="true"
                rounded
                placeholder="Passport Number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- First Name -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">First Name*</p>
              <v-text-field
                v-model="userData.name"
                :hide-details="true"
                rounded
                placeholder="First Name"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Last Name -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Last Name*</p>
              <v-text-field
                v-model="userData.lastName"
                :hide-details="true"
                rounded
                placeholder="Last Name"
                outlined
                dense
                :rules="[
                  (v) =>
                    (!!v && /^[a-zA-Z]+$/.test(v)) || 'Enter a valid last name',
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Middle Name</p>
              <v-text-field
                v-model="userData.middleName"
                :hide-details="true"
                rounded
                placeholder="Middle Name"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- City -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">City*</p>
              <v-text-field
                v-model="userData.city"
                :hide-details="true"
                rounded
                placeholder="City"
                outlined
                dense
                :rules="[
                  (v) => (!!v && /^[a-zA-Z]+$/.test(v)) || 'Enter a valid City',
                ]"
              ></v-text-field>
            </v-col>

            <!-- Country -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Country*</p>
              <v-select
                v-model="userData.country"
                :items="countryOptions"
                item-text="text"
                item-value="value"
                :hide-details="true"
                rounded
                outlined
                dense
                placeholder="Select Country"
              >
                <template v-slot:selection="data">
                  <v-avatar left size="24">
                    <img
                      :src="data.item.flag"
                      :alt="data.item.text"
                      class="flag-icon"
                    />
                  </v-avatar>
                  {{ data.item.text }}
                </template>
                <template v-slot:item="data">
                  <v-avatar left size="24">
                    <img
                      :src="data.item.flag"
                      :alt="data.item.text"
                      class="flag-icon"
                    />
                  </v-avatar>
                  {{ data.item.text }}
                </template>
              </v-select>
            </v-col>

            <!-- Postal Code/ZIP -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Postal Code/ZIP*</p>
              <v-text-field
                v-model="userData.postalCode"
                :hide-details="true"
                rounded
                placeholder="Postal Code/ZIP"
                outlined
                dense
                :rules="[
                  (v) =>
                    (!!v && /^[0-9]{4}$/.test(v)) ||
                    'Enter a valid 4-digit postal code',
                ]"
              ></v-text-field>
            </v-col>

            <!-- Suburb/Complex/Building -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Suburb/Complex/Building</p>
              <v-text-field
                v-model="userData.suburb"
                :hide-details="true"
                rounded
                placeholder="Suburb/Complex/Building"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Province/State</p>
              <v-select
                v-model="userData.provinceState"
                :items="provinceOptions"
                :hide-details="true"
                rounded
                outlined
                dense
                placeholder="Select Province/State"
              ></v-select>
            </v-col>

            <!-- Street Number -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Street Number</p>
              <v-text-field
                v-model="userData.streetNumber"
                :hide-details="true"
                rounded
                placeholder="Street Number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- Street Name -->
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Street Name</p>
              <v-text-field
                v-model="userData.streetName"
                :hide-details="true"
                rounded
                placeholder="Street Name"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              @click="showDialog = true"
              class="dark-blue-btn mt-4 mx-3"
              rounded
              >Save</v-btn
            >
          </v-row>
          <v-row justify="start">
            <h4 class="mt-12 mx-3">Employment Details</h4>
          </v-row>
          <v-row justify="start">
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Name of Business</p>
              <v-text-field
                v-model="userData.companyName"
                :hide-details="true"
                rounded
                placeholder="Name of Business"
                outlined
                dense
                :rules="[
                  (v) =>
                    (!!v && /^[a-zA-Z]+$/.test(v)) ||
                    'Enter a valid Business Name',
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Employment Status</p>
              <v-select
                v-model="userData.employmentStatus"
                :items="employmentStatusOptions"
                :hide-details="true"
                rounded
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Source of Funds</p>
              <v-select
                v-model="userData.sourceOfFunds"
                :items="sourceOfFundsOptions"
                :hide-details="true"
                rounded
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Source of Crypto</p>
              <v-select
                v-model="userData.sourceOfCrypto"
                :items="sourceOfCryptoOptions"
                :hide-details="true"
                rounded
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              @click="showDialog = true"
              class="dark-blue-btn mt-4 mx-3"
              rounded
              >Save</v-btn
            >
          </v-row>
          <v-row justify="start">
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">Country Code</p>
              <v-text-field
                v-model="userData.cellCode"
                readonly
                placeholder="Country Code"
                rounded
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="grey-text mt-2">
                Mobile Phone Number (without country code)
              </p>
              <v-text-field
                v-model="userData.cellNumber"
                placeholder="Cell Number"
                rounded
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              @click="showDialog = true"
              class="dark-blue-btn mt-4 mx-3"
              rounded
              >Save</v-btn
            >
          </v-row>
          <v-row justify="left">
            <h4 class="mt-12 mx-3">Identity Documents</h4>
          </v-row>
          <v-row justify="left">
            <p class="grey-text mt-2 mx-3">
              Fill in and Upload ID Card (Front and Back)
            </p>
          </v-row>
          <v-row justify="start">
            <v-col cols="12" sm="6" class="py-0">
              <v-btn
                @click="openFileDialog('idCardFront')"
                class="pink-btn-border pointer mt-4"
                rounded
                >Upload Front</v-btn
              >
              <input
                type="file"
                ref="idCardFront"
                accept="image/*,application/pdf"
                style="display: none;"
                data-type="idCardFrontUrl"
                @change="handleFileChange($event)"
              />
              <transition name="fade">
                <v-row justify="start" v-if="userData.idCardFrontUploaded">
                  <p class="green-text mt-2">ID Card Front uploaded</p>
                </v-row>
                <v-row justify="start" v-else>
                  <p class="red-text mt-2">Not uploaded</p>
                </v-row>
              </transition>
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <v-btn
                @click="openFileDialog('idCardBack')"
                class="pink-btn-border pointer mt-4"
                rounded
                >Upload Back</v-btn
              >
              <input
                type="file"
                ref="idCardBack"
                accept="image/*,application/pdf"
                style="display: none;"
                data-type="idCardBackUrl"
                @change="handleFileChange($event)"
              />
              <transition name="fade">
                <v-row justify="start" v-if="userData.idCardBackUploaded">
                  <p class="green-text mt-2">ID Card Back uploaded</p>
                </v-row>
                <v-row justify="start" v-else>
                  <p class="red-text mt-2">Not uploaded</p>
                </v-row>
              </transition>
            </v-col>
          </v-row>

          <v-row justify="start">
            <v-col cols="12" sm="6" class="py-0">
              <v-btn
                @click="openFileDialog('residenceProofInput')"
                class="pink-btn-border pointer mt-4"
                rounded
                >Upload Proof of Residence</v-btn
              >
              <input
                type="file"
                ref="residenceProofInput"
                accept="image/*,application/pdf"
                style="display: none;"
                data-type="proofOfResidenceUrl"
                @change="handleFileChange($event)"
              />
              <transition name="fade">
                <v-row justify="start" v-if="userData.proofOfResidenceUploaded">
                  <p class="green-text mt-2">Proof of Residence uploaded</p>
                </v-row>
                <v-row justify="start" v-else>
                  <p class="red-text mt-2">Not uploaded</p>
                </v-row>
              </transition>
            </v-col>
          </v-row>

          <v-row justify="start">
            <v-col cols="12" sm="6" class="py-0">
              <v-btn
                @click="openFileDialog('bankProofInput')"
                class="pink-btn-border pointer mt-4"
                rounded
                >Upload Proof of Bank Account</v-btn
              >
              <input
                type="file"
                ref="bankProofInput"
                accept="image/*,application/pdf"
                style="display: none;"
                data-type="bankAccountProofUrl"
                @change="handleFileChange($event)"
              />
              <transition name="fade">
                <v-row justify="start" v-if="userData.bankAccountProofUploaded">
                  <p class="green-text mt-2">Proof of Bank Account uploaded</p>
                </v-row>
                <v-row justify="start" v-else>
                  <p class="red-text mt-2">Not uploaded</p>
                </v-row>
              </transition>
            </v-col>
          </v-row>

          <v-row justify="left">
            <p class="grey-text mt-6">
              Selfie pic of yourself holding your ID and a piece of paper with
              the following written on the paper
            </p>
          </v-row>
          <v-row justify="left">
            <li class="grey-text">*Your name and surname</li>
          </v-row>
          <v-row justify="left">
            <li class="grey-text">*ID number</li>
          </v-row>
          <v-row justify="left">
            <li class="grey-text">*Today's date</li>
          </v-row>
          <v-row justify="left">
            <li class="grey-text">
              *HODL OTC Please note that the ID number on the ID card must be
              clearly legible when zooming in on the selfie
            </li>
          </v-row>
          <v-row justify="start">
            <v-col cols="12" sm="6" class="py-0">
              <v-btn
                @click="openFileDialog('selfieInput')"
                class="pink-btn-border pointer mt-4"
                rounded
                >Upload Selfie</v-btn
              >
              <input
                type="file"
                ref="selfieInput"
                accept="image/*,application/pdf"
                style="display: none;"
                data-type="selfieUrl"
                @change="handleFileChange($event)"
              />
              <v-row justify="start" v-if="userData.selfieUploaded">
                <p class="green-text mt-2">Selfie uploaded</p>
              </v-row>
              <v-row justify="start" v-else>
                <p class="red-text mt-2">Not uploaded</p>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-col>
    <v-dialog width="400px" v-if="capturing" v-model="capturing">
      <v-row justify="center">
        <v-card class="full-width pa-8">
          <v-row justify="center">
            <!-- Display your custom QR code image -->
            <img
              src="../assets/images/qrcode.png"
              alt="Custom QR Code"
              style="width: 80px; height: 80px;"
            />
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text text-center">
              Make sure you are signed in on your device.
            </h5>
          </v-row>
          <v-row justify="center">
            <h4 class="grey-text text-center">Upload ID Card</h4>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-row justify="center">
                <v-btn
                  @click="openFileDialog('idCardFront')"
                  class="pink-btn-border pointer mt-4"
                  rounded
                  >Upload Front</v-btn
                >
                <input
                  type="file"
                  ref="idCardFront"
                  accept="image/*,application/pdf"
                  style="display: none;"
                  data-type="idCardFrontUrl"
                  @change="handleFileChange($event)"
                />
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row justify="center">
                <v-btn
                  @click="openFileDialog('idCardBack')"
                  class="pink-btn-border pointer mt-4"
                  rounded
                  >Upload Back</v-btn
                >
                <input
                  type="file"
                  ref="idCardBack"
                  accept="image/*,application/pdf"
                  style="display: none;"
                  data-type="idCardBackUrl"
                  @change="handleFileChange($event)"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-alert v-if="userData.someArray && userData.someArray.length > 0">
            <ul>
              <li v-for="message in validationMessages" :key="message">
                {{ message }}
              </li>
            </ul>
          </v-alert>
        </v-card>
      </v-row>
    </v-dialog>
  </v-row>
</template>

<script>
  import FeatureCard from "../components/Feature-Card.vue";
  import emailjs from "@emailjs/browser";
  import {
    getStorage,
    ref as storageRef,
    uploadBytes,
    getDownloadURL,
    getMetadata,
  } from "firebase/storage";
  import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
    updateDoc,
  } from "firebase/firestore";
  import { auth } from "../firebaseConfig.js";

  export default {
    data() {
      return {
        userData: {
          companyName: "",
          companyVAT: "",
          companyRegNumber: "",
          TrustName: "",
          trustRegNumber: "",
          idNumber: "",
          passportNumber: "",
          name: "",
          lastName: "",
          middleName: "",
          city: "",
          country: "",
          postalCode: "",
          suburb: "",
          provinceState: "",
          streetNumber: "",
          streetName: "",
          businessName: "",
          employmentStatus: "",
          sourceOfFunds: "",
          sourceOfCrypto: "",
          cellCode: "",
          cellNumber: "",
          PhoneNumber: "",
          capturing: false,
          idCardFrontUrl: "",
          idCardBackUrl: "",
          proofOfResidenceUrl: "",
          bankAccountProofUrl: "",
          selfieUrl: "",
          idCardFrontUploaded: false,
          idCardBackUploaded: false,
          proofOfResidenceUploaded: false,
          bankAccountProofUploaded: false,
          selfieUploaded: false,
        },
        cards: [
          {
            title: "Verify Email",
            description:
              "You already verified your email during the registration process",
          },
          {
            title: "Personal Info",
            description:
              "Submit your personal information and add а mobile phone number",
          },
          {
            title: "Identity Verification",
            description:
              "Complete Identity Verification to unlock all features",
          },
        ],
        features: [
          {
            icon: "arrow-right",
            heading: "Transfer Assets",
            description: "Top up and withdrawals",
            comingSoon: false,
          },
          {
            icon: "card-account-details",
            heading: "Buy Crypto with Card",
            description: "Use any Visa or Mastercard to buy crypto",
            comingSoon: true,
          },
          {
            icon: "chart-areaspline",
            heading: "Earn Interest",
            description: "Interest on your idle assets",
            comingSoon: false,
          },
          {
            icon: "card-account-details",
            heading: "Hodl Card",
            description: "Spend cash instantly",
            comingSoon: true,
          },
          {
            icon: "card-account-details",
            heading: "Borrow",
            description: "Cash or stablecoins",
            comingSoon: true,
          },
          {
            icon: "account-group",
            heading: "Referral Program",
            description: "Invite friends and earn crypto or cash",
            comingSoon: false,
          },
          {
            icon: "bitcoin",
            heading: "Bitcoin",
            description: "Buy, sell & swap",
            comingSoon: false,
          },
        ],
        limits: [
          "Crytocurrency",
          "Unlimited",
          "Crytocurrency",
          "Bank Transfer",
          "Bank Transfer",
          "Bank Transfer",
        ],
        currentCollection: "",
        showDialog: false,
        idCard: null,
        uploadSuccessMessage: "",
        idCardFront: null,
        idCardBack: null,
        proofOfResidence: null,
        bankAccountProof: null,
        selfie: null,
        verifying: false,
        capturing: false,
        sourceOfCryptoOptions: [
          "Mining",
          "NFT sales",
          "ICO Offerings",
          "Staking",
          "Trading",
          "Sales of Product or Services in Crypto",
          "Inheritance or Donations",
        ],
        employmentStatusOptions: [
          "Retired",
          "Self Employed",
          "Employed",
          "UnEmployed",
          "Pensioner",
        ],
        sourceOfFundsOptions: [
          "Salary",
          "Grants",
          "Donations",
          "Business practices",
          "Pension Grant",
          "Gifts",
          "Savings",
          "Investments",
          "Trust Distributions",
          "Loan",
          "Sale of Asset",
          "Retirement Funds / Provident Fund",
          "Government Employee",
        ],
        countryOptions: [
          {
            text: "Botswana",
            value: "Botswana",
            flag: "https://flagcdn.com/w320/bw.png",
          },
          {
            text: "Namibia",
            value: "Namibia",
            flag: "https://flagcdn.com/w320/na.png",
          },
          {
            text: "Nigeria",
            value: "Nigeria",
            flag: "https://flagcdn.com/w320/ng.png",
          },
          {
            text: "South Africa",
            value: "South Africa",
            flag: "https://flagcdn.com/w320/za.png",
          },
        ],
        provinces: {
          Botswana: [
            "Central",
            "Ghanzi",
            "Kgalagadi",
            "Kgatleng",
            "Kweneng",
            "North-East",
            "North-West",
            "South-East",
            "Southern",
          ],
          Namibia: [
            "Erongo",
            "Hardap",
            "Karas",
            "Kavango East",
            "Kavango West",
            "Khomas",
            "Kunene",
            "Ohangwena",
            "Omaheke",
            "Omusati",
            "Oshana",
            "Oshikoto",
            "Otjozondjupa",
            "Zambezi",
          ],
          Nigeria: [
            "Abia",
            "Adamawa",
            "Akwa Ibom",
            "Anambra",
            "Bauchi",
            "Bayelsa",
            "Benue",
            "Borno",
            "Cross River",
            "Delta",
            "Ebonyi",
            "Edo",
            "Ekiti",
            "Enugu",
            "FCT",
            "Gombe",
            "Imo",
            "Jigawa",
            "Kaduna",
            "Kano",
            "Katsina",
            "Kebbi",
            "Kogi",
            "Kwara",
            "Lagos",
            "Nasarawa",
            "Niger",
            "Ogun",
            "Ondo",
            "Osun",
            "Oyo",
            "Plateau",
            "Rivers",
            "Sokoto",
            "Taraba",
            "Yobe",
            "Zamfara",
          ],
          "South Africa": [
            "Eastern Cape",
            "Free State",
            "Gauteng",
            "KwaZulu-Natal",
            "Limpopo",
            "Mpumalanga",
            "Northern Cape",
            "North West",
            "Western Cape",
          ],
        },
      };
    },
    computed: {
      progress() {
        let completedFields = 0;
        const totalFields = 37; 

  
        for (const key in this.userData) {
          if (this.userData[key]) {
            completedFields++;
          }
        }

        return (completedFields / totalFields) * 100;
      },
      provinceOptions() {
        return this.provinces[this.userData.country] || [];
      },

      provinceOptions() {
        return this.provinces[this.userData.country] || [];
      },
    },
    created() {
      this.fetchAccountData();
      this.splitPhoneNumber();
      this.checkUploadedFiles();
    },
    watch: {
  'userData.idCardFrontUrl'(newVal) {
    if (newVal) {
      this.userData.idCardFrontUploaded = true;
    } else {
      this.userData.idCardFrontUploaded = false;
    }
  },
  'userData.idCardBackUrl'(newVal) {
    if (newVal) {
      this.userData.idCardBackUploaded = true;
    } else {
      this.userData.idCardBackUploaded = false;
    }
  },
  'userData.proofOfResidenceUrl'(newVal) {
    if (newVal) {
      this.userData.proofOfResidenceUploaded = true;
    } else {
      this.userData.proofOfResidenceUploaded = false;
    }
  },
  'userData.bankAccountProofUrl'(newVal) {
    if (newVal) {
      this.userData.bankAccountProofUploaded = true;
    } else {
      this.userData.bankAccountProofUploaded = false;
    }
  },
  'userData.selfieUrl'(newVal) {
    if (newVal) {
      this.userData.selfieUploaded = true;
    } else {
      this.userData.selfieUploaded = false;
    }
  }
},
    methods: {
      handleConfirmation() {
        this.saveUserData();
        this.showDialog = false;
      },
      onFileSelected(docType, event) {
        this[docType] = event.target.files[0];
      },
      openFileDialog(refName) {
        this.$refs[refName].click();
      },
      async sendUserDataEmail(email, lastName, name, idNumber, OvexEmail) {
        try {
          const templateParams = {
            to_email: "hodlwebsite@gmail.com",
            subject: "Registration Added",
            message: `New User has been added with these credentials:
                \nEmail: ${email}
                \nName: ${lastName}
                \nIDNumber: ${name}
                \nOvexEmail: ${idNumber}
                PLease Create there Ovex Acount`,
          };

          await emailjs.send(
            "service_9esjl09", // Replace with your Email.js service ID
            "template_ta9fu4j", // Replace with your Email.js template ID
            templateParams,
            "mr1E1BaGMKZMEzWVu" // Replace with your Email.js user ID
          );
        } catch (error) {
          console.error("Error sending email", error);
        }
      },
      async uploadFiles() {
        try {
          if (this.idCardFront) {
            await this.uploadFile("idCardFront", "documents/idCard");
          }
          if (this.idCardBack) {
            await this.uploadFile("idCardBack", "documents/idCard");
          }
          if (this.proofOfResidenceUrl) {
            await this.uploadFile("proofOfResidenceUrl", "documents/");
          }
          if (this.bankAccountProof) {
            await this.uploadFile("bankAccountProof", "documents/");
          }
          if (this.selfie) {
            await this.uploadFile("selfie", "documents/");
          }

          // Handle the updated userData here (e.g., save to Firestore)
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      },
      async uploadFile(fieldName, directory) {
        try {
          const fileRef = storageRef(
            getStorage(),
            `${directory}/${this[fieldName].name}`
          );
          await uploadBytes(fileRef, this[fieldName]);
          this.userData[`${fieldName}Url`] = await getDownloadURL(fileRef);

          // Set the success message
          this.uploadSuccessMessage = `Upload successful for ${fieldName}.`;

          // Update the corresponding upload status flag
          switch (fieldName) {
            case "idCardFrontUrl":
              this.userData.idCardFrontUploaded = true;
              break;
            case "idCardBackUrl":
              this.userData.idCardBackUploaded = true;
              break;
            case "proofOfResidenceUrl":
              this.userData.proofOfResidenceUploaded = true;
              break;
            case "bankAccountProofUrl":
              this.userData.bankAccountProofUploaded = true;
              break;
            case "selfieUrl":
              this.userData.selfieUploaded = true;
              break;
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          this.uploadSuccessMessage = `Error uploading ${fieldName}.`;
        }
      },
      async handleFileChange(event) {
        const file = event.target.files[0];
        if (!file) return;

        const docType = event.target.getAttribute("data-type");
        if (!docType) {
          console.error("Document type not found");
          return;
        }

        try {
          const fileUrl = await this.uploadFileToStorage(docType, file);
          if (fileUrl) {
            this.$set(this.userData, docType, fileUrl); // Ensure reactivity is preserved
            
            // Update the corresponding upload status flag
            switch (docType) {
              case 'idCardFrontUrl':
                this.userData.idCardFrontUploaded = true;
                break;
              case 'idCardBackUrl':
                this.userData.idCardBackUploaded = true;
                break;
              case 'proofOfResidenceUrl':
                this.userData.proofOfResidenceUploaded = true;
                break;
              case 'bankAccountProofUrl':
                this.userData.bankAccountProofUploaded = true;
                break;
              case 'selfieUrl':
                this.userData.selfieUploaded = true;
                break;
            }
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      },
      async uploadFileToStorage(docType, file) {
        const storage = getStorage();
        const userEmail = auth.currentUser.email; // Adjust as needed
        const fileRef = storageRef(
          storage,
          `documents/${userEmail}/${docType}`
        );
        await uploadBytes(fileRef, file);
        return await getDownloadURL(fileRef);
      },
      checkUploadStatus(docType, newVal) {
        if (newVal) {
          console.log(`${docType} uploaded successfully.`);
        } else {
          console.log(`${docType} not uploaded.`);
        }
      },
      // If you still need to update Firestore
      async updateDocumentUrlInFirestore(docType, fileUrl) {
        const docRef = await this.getDocumentRef();
        if (docRef) {
          await updateDoc(docRef, { [docType]: fileUrl });
        }
      },
      async getDocumentRef() {
        const db = getFirestore();
        const userEmail = auth.currentUser.email;
        const accountCollections = ["individuals", "companies", "trusts"];

        for (const collectionName of accountCollections) {
          const accountCollectionRef = collection(db, collectionName);
          const q = query(
            accountCollectionRef,
            where("email", "==", userEmail)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].ref;
          }
        }
        return null;
      },
      async fetchAccountData() {
        const db = getFirestore();
        const userEmail = auth.currentUser.email;
        let accountCollections = ["individuals", "companies", "trusts"];
        let accountDataFound = false;

        for (let collectionName of accountCollections) {
          if (accountDataFound) break;
          let accountCollectionRef = collection(db, collectionName);
          let q = query(accountCollectionRef, where("email", "==", userEmail));
          let querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            this.userData = querySnapshot.docs[0].data();
            this.currentCollection = collectionName;
            accountDataFound = true;
          }
        }
      },
      async fetchUserDataFromAPI() {
        const myHeaders = new Headers();
        myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");

        try {
          const userEmail = auth.currentUser.email;

          const response = await fetch(
            `https://corsproxy.io/?https://hodl-vm.northeurope.cloudapp.azure.com/api/registrationSelect?email=${userEmail}`,
            {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            }
          );
          const result = await response.json();
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      },
      async sendToApi(userData) {
        const myHeaders = new Headers();
        myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");
        myHeaders.append("Content-Type", "application/json");

        let payload = {
          companyName: userData.companyName || "",
          companyVat: userData.companyVAT || "",
          name: userData.name || "",
          lastName: userData.lastName || "",
          middleName: userData.middleName || "",
          email: userData.email || "",
          password: userData.password || "",
          city: userData.city || "",
          country: userData.country || "",
          postalCode: userData.postalCode || "",
          suburb: userData.suburb || "",
          streetNumber: userData.streetNumber || "",
          streetName: userData.streetName || "",
          phoneNumber: userData.PhoneNumber || "",
          emailVerified: userData.emailVerified || "",
          idVerified: userData.idVerified || "",
          twoFactorAuthLink: userData.twoFactorAuthLink || "",
          twoFactorAuthStatus: userData.twoFactorAuthStatus || "",
          identityNumber: userData.idNumber || "",
          trustRegNum: userData.trustRegNumber || "",
          trustName: userData.TrustName || "",
          idBookFront: userData.idBookFront || "",
          driversFront: userData.driversFront || "",
          driversBack: userData.driversBack || "",
          idCardFront: userData.idCardFront || "",
          idCardBack: userData.idCardBack || "",
          passport: userData.PassportFront || "",
          passportFront: userData.passportFront || "", // Added based on your initial structure
          honeyCombResult: "",
          errorMessage: "",
        };

        const raw = JSON.stringify(payload);

        try {
          const response = await fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/RegistrationUpdate",
            {
              method: "PUT",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            }
          );
          const result = await response.text();
        } catch (error) {
          console.error("Error:", error);
        }
      },
      async saveUserData() {
        const db = getFirestore();
        const userEmail = auth.currentUser.email;
        const accountCollections = ["individuals", "companies", "trusts"];

        try {
          let userDoc = null;

          // Fetch the user document
          for (let collectionName of accountCollections) {
            const accountCollectionRef = collection(db, collectionName);
            const q = query(
              accountCollectionRef,
              where("email", "==", userEmail)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
              userDoc = querySnapshot.docs[0];
              break;
            }
          }

          if (!userDoc) {
            throw new Error("User document not found");
          }

          const userData = userDoc.data();

          // If OvexEmail does not exist, create a new one
          if (!userData.OvexEmail) {
            if (this.userData.name && this.userData.idNumber) {
              const name = this.userData.name.replace(/\s+/g, "");
              const lastName = this.userData.lastName.replace(/\s+/g, "");
              const idNumber = this.userData.idNumber.replace(/\s+/g, "");
              const OvexEmail = `${name}.${lastName}.${idNumber}@hodlotc.com`;
              this.userData.OvexEmail = OvexEmail;
            }
          } else {
            // Preserve the existing OvexEmail
            this.userData.OvexEmail = userData.OvexEmail;
          }

          this.userData.PhoneNumber = `${this.userData.cellCode}${this.userData.cellNumber}`;

          // Update the document with the new user data
          await updateDoc(userDoc.ref, this.userData);

          // Send data to the API
          await this.sendToApi(this.userData);

          // Send user data email
          await this.sendUserDataEmail(
            this.userData.email,
            this.userData.name,
            this.userData.idNumber,
            this.userData.OvexEmail
          );
        } catch (error) {
          console.error("Error updating document: ", error);
        }
      },
      splitPhoneNumber() {
        const PhoneNumber = this.userData.PhoneNumber;
        const cellCode = this.userData.cellCode;

        if (PhoneNumber.startsWith(cellCode)) {
          this.userData.cellNumber = PhoneNumber.slice(cellCode.length);
        } else {
          this.userData.cellNumber = PhoneNumber; // or any default action
        }
      },
      async checkUploadedFiles() {
        const storage = getStorage();
        const userEmail = auth.currentUser.email;
        const filesToCheck = [
          "idCardFrontUrl",
          "idCardBackUrl",
          "proofOfResidenceUrl",
          "bankAccountProofUrl",
          "selfieUrl",
        ];

        for (let fileType of filesToCheck) {
          const fileRef = storageRef(
            storage,
            `documents/${userEmail}/${fileType}`
          );
          try {
            await getMetadata(fileRef);
            this.userData[fileType] = await getDownloadURL(fileRef);
            // Set the corresponding uploaded status flag to true
            this.userData[`${fileType.replace('Url', 'Uploaded')}`] = true;
          } catch (error) {
            // File does not exist
            this.userData[fileType] = null;
            this.userData[`${fileType.replace('Url', 'Uploaded')}`] = false;
          }
        }
      },
    },
    components: {
      FeatureCard,
    },
  };
</script>

<style scoped>
  .fs-80 {
    font-size: 80%;
  }
  .height-230 {
    height: 230px;
  }
  @media (max-width: 600px) {
    .dark-blue-btn,
    .pink-btn-border {
      width: 100%;
    }
    .height-230 {
      height: auto;
    }
  }
</style>

<style>
  .identity .v-file-input .v-label {
    color: #be1b74;
    font-weight: bold;
  }

  .qr .v-label {
    max-width: unset !important;
    left: auto !important;
  }
  .flag-icon {
    width: 20px;
    height: 15px;
    object-fit: contain;
  }
  .green-text {
    color: #4caf50 !important;
  }

  .red-text {
    color: #f44336 !important;
  }

  .pink-btn-border {
    border: 1px solid #e91e63;
    color: #e91e63;
  }

  .pink-btn-border:hover {
    background-color: #e91e63;
    color: white;
  }

  .flag-icon {
    width: 20px;
    height: 15px;
    object-fit: contain;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
</style>
