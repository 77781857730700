<template>
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="transparent-card pa-4">
        <v-card-title class="headline white--text text-center">Verify Your Email</v-card-title>
        <v-card-text class="white--text">
          <p>To access your account, please verify your email address. Follow these simple steps:</p>
          <ol class="verification-steps">
            <li>
              <p>Check your email inbox for a verification email from <strong>hodlwebsite@gmail.com</strong>.</p>
              <v-img src="URL_TO_IMAGE_STEP1" class="step-image"></v-img>
            </li>
            <li>
              <p>If you don't see it, check your spam or junk mail folder. Emails from <strong>hodlwebsite@gmail.com</strong> sometimes end up there.</p>
              <v-img src="URL_TO_IMAGE_STEP2" class="step-image"></v-img>
            </li>
            <li>
              <p>Make sure your email address is spelled correctly: {{ formattedEmail }}</p>
              <v-img src="URL_TO_IMAGE_STEP3" class="step-image"></v-img>
            </li>
            <li>
              <p>Click on the verification link in the email from <strong>hodlwebsite@gmail.com</strong> to verify your account.</p>
              <v-img src="URL_TO_IMAGE_STEP4" class="step-image"></v-img>
            </li>
          </ol>
          <p>If you still can't find the email, click the "Resend Email" button below to receive a new verification email from <strong>hodlwebsite@gmail.com</strong>.</p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn  rounded class="pink-btn" @click="resendEmail">Resend Email</v-btn>
          <v-btn color="primary"  rounded class="transparent-bg white--text" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "VerificationDialog",
    props: {
      showDialog: Boolean,
      formattedEmail: String,
    },
    data() {
      return {
        dialog: this.showDialog,
      };
    },
    watch: {
      showDialog(newVal) {
        this.dialog = newVal;
      },
    },
    methods: {
      resendEmail() {
        this.$emit('resend-email');
        this.dialog = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .transparent-card {
    background-color: transparent;
    border: 2px solid #f8f8f82b;
  }
  .verification-steps {
    padding-left: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .step-image {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .white--text {
    color: #ffffff;
  }
  .pink-btn {
    background-color: #ff4081;
    color: white;
  }
  .transparent-bg {
    background-color: transparent;
  }
  </style>
  