<template>
  <v-container class="markets pa-8 full-width">
    <transition name="slide">
      <v-row v-if="showForm" justify="center">
        <div class="white-card full-width pa-6">
          <v-row justify="center" justify-md="start">
            <div
              style="font-family: 'Montserrat', sans-serif; font-size: 18px;"
            >
              Market Overview
            </div>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="4" class="pa-1">
              <v-card class="overview-card full-width no-height">
                <div
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                  "
                >
                  Market Cap
                </div>
                <v-row justify="center" class="mt-4">
                  <v-data-table
                    :headers="marketcap"
                    :items="marketCapItems"
                    hide-default-header
                    items-per-page="5"
                    :loading="loading"
                    hide-default-footer
                    class="elevation-1 full-width mt-6"
                  >
                    <template v-slot:item.png64="{ item }">
                      <div style="display: flex; align-items: center;">
                        <img
                          :src="item.png64"
                          alt="Crypto Image"
                          height="30"
                          style="margin-right: 8px;"
                        />
                      </div>
                    </template>
                    <template v-slot:item.rate="{ item }">
                      {{ formatPrice(item.rate) }}
                    </template>
                  </v-data-table>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-card class="overview-card full-width no-height">
                <div
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                  "
                >
                  Top Movers
                </div>
                <v-row justify="center" class="mt-4">
                  <v-data-table
                    :headers="topmovers"
                    :items="topMoversItems"
                    hide-default-header
                    items-per-page="5"
                    :loading="loading"
                    hide-default-footer
                    class="elevation-1 full-width mt-6"
                  >
                    <template v-slot:item.png64="{ item }">
                      <div style="display: flex; align-items: center;">
                        <img
                          :src="item.png64"
                          alt="Crypto Image"
                          height="30"
                          style="margin-right: 8px;"
                        />
                      </div>
                    </template>
                    <template v-slot:item.rate="{ item }">
                      {{ formatPrice(item.rate) }}
                    </template>
                  </v-data-table>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-card class="overview-card full-width no-height">
                <div
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                  "
                >
                  Volume
                </div>
                <v-row justify="center" class="mt-4">
                  <v-data-table
                    :headers="volume"
                    :items="volumeItems"
                    hide-default-header
                    :loading="loading"
                    items-per-page="5"
                    hide-default-footer
                    class="elevation-1 full-width mt-6"
                  >
                    <template v-slot:item.png64="{ item }">
                      <div style="display: flex; align-items: center;">
                        <img
                          :src="item.png64"
                          alt="Crypto Image"
                          height="30"
                          style="margin-right: 8px;"
                        />
                      </div>
                    </template>
                    <template v-slot:item.rate="{ item }">
                      {{ formatPrice(item.rate) }}
                    </template>
                  </v-data-table>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-row></transition
    >
    <transition name="slide">
      <v-row v-if="showForm" justify="center">
        <div class="white-card full-width pa-6 mt-6">
          <v-row justify="center" class="pa-0 my-4">
            <!-- Markets label on the left -->
            <v-col cols="auto" class="pa-0">
              <div
                style="font-family: 'Montserrat', sans-serif; font-size: 20px;"
              >
                Markets
              </div>
            </v-col>

            <v-col></v-col>

            <v-col cols="auto" class="pa-0">
              <v-text-field
                v-model="searchQuery"
                :hide-details="true"
                class="px-2"
                dense
                outlined
                rounded
                light
                placeholder="Search"
                prepend-inner-icon="mdi-search-web"
                :style="{ 'max-width': '320px' }"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-data-table
              :headers="headers"
              :items="filteredItems"
              :loading="loading"
              class="elevation-1 full-width mt-6"
            >
              <!-- Custom slot for the combined Name and Image column -->
              <template v-slot:item.name="{ item }">
                <div style="display: flex; align-items: center;">
                  <img
                    :src="item.png64"
                    alt="Crypto Image"
                    height="30"
                    style="margin-right: 8px;"
                  />
                  <span>{{ item.name }}</span>
                </div>
              </template>

              <!-- Custom slot for the Price column -->
              <template v-slot:item.rate="{ item }">
                {{ formatPrice(item.rate) }}
              </template>

              <template v-slot:item.Trade="{ item }">
                <v-row no-gutters align="center" justify="end">
                  <v-col class="pa-1" cols="auto">
                    <v-btn rounded @click="storeAndTrade(item)" color="primary">
                      Trade
                    </v-btn>
                  </v-col>
                  <v-col class="pa-1" cols="auto">
                    <v-btn
                      rounded
                      dense
                      @click="redirectToWallet()"
                      class="pink-btn-border-wallet"
                    >
                      <v-icon>mdi-wallet</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-row>
        </div></v-row
      >
    </transition>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        showForm: false,
        loading: false,
        searchQuery: "",
        items: [], // Initialize your items array to be filled with API data
        marketCapItems: [],
        topMoversItems: [],
        volumeItems: [],
        marketcap: [
          { text: "", align: "start", value: "png64" },
          { text: "", align: "start", value: "code" },
          { text: "", align: "start", value: "name" }, // Assuming you still want to display the name
          // And the market cap for sorting
          { text: "", align: "start", value: "rate" },
        ],
        topmovers: [
          { text: "", align: "start", value: "png64" },
          { text: "", align: "start", value: "code" },
          { text: "", align: "start", value: "name" }, // Assuming you still want to display the name
          { text: "", align: "start", value: "rate" },
        ],
        volume: [
          { text: "", align: "start", value: "png64" },
          { text: "", align: "start", value: "code" },
          { text: "", align: "start", value: "name" }, // Assuming you still want to display the name
          { text: "", align: "start", value: "rate" },
        ],
        headers: [
          {
            text: "Name",
            align: "start",
            sortable: false,
            value: "name", // Ensure these values match the structure of your API response
          },
          {
            text: "Price",
            align: "start",
            sortable: true,
            value: "rate",
          },
          {
            text: "24h Volume",
            align: "start",
            sortable: false,
            value: "volume",
          },
          {
            text: "Market Cap",
            align: "start",
            sortable: false,
            value: "cap",
          },
          {
            align: "end",
            sortable: false,
            value: "Trade",
          },
        ],
        assetType: "all",
      };
    },
    mounted() {
      this.showForm = true;
      this.fetchMarketData();
    },
    computed: {
      filteredItems() {
        if (!this.searchQuery) {
          return this.items; // Return all items if the search query is empty
        }
        const searchLowerCase = this.searchQuery.toLowerCase();
        return this.items.filter((item) => {
          return item.name.toLowerCase().includes(searchLowerCase);
        });
      },
    },
    methods: {
      formatPrice(value) {
        const formatter = new Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
          minimumFractionDigits: 0,
        });
        return formatter.format(value);
      },
      navigateToTrade() {
        this.$router.push("/trade"); // Programmatic navigation to /trade
      },

      async fetchMarketData() {
        this.loading = true;
        try {
          const response = await fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/LiveCoin"
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          console.log(data);
          this.items = data; // Assuming the API response structure matches your data's needs
          this.marketCapItems = [...data]
            .sort((a, b) => b.cap - a.cap)
            .slice(0, 5);
          this.topMoversItems = [...data]
            .sort((a, b) => b.rate - a.rate)
            .slice(0, 5);
          this.volumeItems = [...data]
            .sort((a, b) => b.volume - a.volume)
            .slice(0, 5);
        } catch (error) {
          console.error("Fetching market data failed:", error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped>
  /* Animations: */
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.6s;
  }
  .slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
    transform: translateX(100%);
  }

  .active-btn {
    background-color: #00145845 !important;
    color: white !important;
  }

  .overview-card {
    width: 500px;
    min-height: 500px;
    border: 1px solid #7070708c !important;
    border-radius: 12px !important;
    padding: 20px;
  }

  .markets ::placeholder {
    color: black !important;
  }
</style>
