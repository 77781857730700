<template>
  <v-container class="account pa-8 full-width">
    <!-- Loading section -->
    <v-row justify="center" v-if="loading" class="loader-container">
      <v-col cols="12" class="text-center">
        <v-icon class="mdi mdi-bitcoin rotating-loader" color="primary" size="64"></v-icon>
        <div style="font-family: 'Montserrat', sans-serif; font-size: 24px;" class="text-center">
          LOADING...
        </div>
      </v-col>
    </v-row>

    <!-- Beta banner section -->
    <v-row justify="center" v-if="!loading" class="my-4">
      <v-col cols="12">
        <v-card class="light-purple-banner pa-4">
          <div class="text-center">
            <div style="font-family: 'Montserrat', sans-serif; font-size: 24px;" class="text-center">
              HODL WEB APP IS IN BETA VERSION
            </div>
            <div style="font-family: 'Montserrat', sans-serif; font-size: 18px;" class="text-center">
              Let us know if you encounter any issues
            </div>
            <v-btn rounded class="dark-blue-btn mx-2 no-width" @click="redirectToReportIssue">
              Report Issue
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Balance and Loyalty section -->
    <v-row v-if="!loading" justify="center">
      <v-col cols="12" sm="6" md="6" class="pa-2">
        <v-row class="full-height" justify="center">
          <div class="white-card full-width">
            <div style="font-family: 'Montserrat', sans-serif; font-size: 24px;" class="text-center">
              TOTAL PROFILE BALANCE
            </div>
            <div style="font-family: 'Montserrat', sans-serif; font-size: 20px;" :style="{ color: '#2DBA11' }"
              class="text-center">
              {{ profileBalance.totalProfileBalance }}
            </div>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="pa-2">
        <v-row class="full-height" justify="center">
          <div class="white-card full-width">
            <div style="font-family: 'Montserrat', sans-serif; font-size: 24px;" class="text-center">
              LOYALTY LEVEL
            </div>
            <v-row justify="center" align="center">
              <img height="40px" src="../assets/images/Basic-Crown.svg" />
              <div style="font-family: 'Montserrat', sans-serif; font-size: 20px;" class="text-center mx-2">
                BASE
              </div>
            </v-row>
          </div>
        </v-row>
      </v-col>

      <!-- Left Column (Trading Balance and Nice Hash Balance) -->
      <v-col cols="12" md="6">
        <v-row>
          <!-- Trading Balance -->
          <v-col cols="12" class="pa-2">
            <div class="white-card full-width">
              <v-row justify="center">
                <div class="text-center" style="font-family: 'Montserrat', sans-serif; font-size: 24px;">
                  ZAR BALANCE
                </div>
              </v-row>
              <v-row justify="center">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 20px;" :style="{ color: '#2DBA11' }"
                  class="text-center">
                  {{ profileBalance.zarBalance }}
                </div>
              </v-row>
              <v-row justify="center">
                <div class="text-center" style="font-family: 'Montserrat', sans-serif; font-size: 24px;">
                  CRYPTO BALANCE
                </div>
              </v-row>
              <v-row justify="center">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 20px; color: #2dba11;"
                  :class="$vuetify.breakpoint.width < 600 ? 'text-center' : ''">
                  {{ profileBalance.cryptoBalance }}
                </div>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" md="7" class="pa-1 mt-4" justify="end">
                  <apexchart height="115%" type="donut" :options="donutOptions" :series="donutSeries"></apexchart>
                </v-col>
                <v-col cols="12" md="5" class="pa-0 mt-4">
                  <v-row v-for="(currency, index) in formattedCombinedCurrencyRateData" :key="index" justify="end"
                    align="center" class="my-1">
                    <v-col cols="auto" class="pa-0">
                      <v-img v-if="currency.png64 !== 'N/A'" :src="currency.png64" :alt="currency.currency" class="mr-2"
                        height="20" width="20" contain></v-img>
                    </v-col>
                    <v-col class="pa-0">
                      <div style="font-family: 'Montserrat', sans-serif; font-size: 14px;" class="my-1">
                        {{ currency.currency }}
                        {{ formatCurrency(currency.balance) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="mt-4">
                <v-btn rounded class="dark-blue-btn mx-2 no-width" @click="$router.push('/trade')">Trade</v-btn>
                <h5 class="purple-text mx-2 pointer" @click="$router.push('/deposit')">Deposit</h5>
              </v-row>
            </div>
          </v-col>

          <!-- Nice Hash Balance -->
          <v-col cols="12" class="pa-2">
            <div class="white-card full-width">
              <div class="text-center" style="font-family: 'Montserrat', sans-serif; font-size: 24px;">
                NICE HASH BALANCE
              </div>
              <v-row justify="center">
                <v-col cols="12" sm="12" lg="4" class="pa-0 px-2 my-4">
                  <v-row justify="center">
                    <div class="full-width">
                      <v-img height="100px" width="100%" src="../assets/images/nicehash9123.jpg" alt="Image Alt Text"
                        contain></v-img>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" justify-sm="center">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 24px;" class="mx-1">
                  COMING SOON
                </div>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- Right Column (Request for Quote) -->
      <v-col cols="12" md="6" class="pa-3 my-2">
        <v-row justify="center" class="full-height">
          <div class="white-card full-width full-height">
            <ExchangeTrade />
          </div>
        </v-row>
      </v-col>
    </v-row>

    <!-- Verify Identity Section -->
    <v-row justify="center" v-if="!loading && !isIdentityVerified">
      <div class="identity-verification full-width mx-2 mt-6">
        <v-row class="full-height" justify="center">
          <v-col cols="12" sm="6">
            <v-row class="full-height" justify="center" justify-sm="start">
              <v-col cols="2">
                <v-row class="full-height" justify="center" align="center">
                  <v-icon large class="white-text">mdi-face-recognition</v-icon>
                </v-row>
              </v-col>
              <v-col cols="10">
                <v-row class="full-height" justify="start" align="center">
                  <h5 class="white-text">Verify Identity</h5>
                  <p class="white-text mx-2">
                    Complete identity verification to fully unlock all features
                  </p>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row justify="center" justify-sm="end" align="center" class="full-height">
              <v-btn @click="
                $router.push({
                  path: '/options',
                  query: { section: 'IdentityVerification' },
                })
                " rounded class="pink-btn">
                Identity Verification
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-row>

    <!-- Data Table Section -->
    <v-row justify="center" v-if="!loading">
      <div class="white-card full-width mx-2 mt-6 pa-0">
        <v-row class="px-6 my-7" justify="center" justify-sm="start">
          <v-col cols="12" lg="2" md="2" sm="3" class="pa-0 my-2">
            <v-row class="full-height" justify="center" justify-sm="start" align="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 24px;">
                TRADING ASSETS
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="3" class="pa-0 my-2">
            <v-row class="full-height" justify="center" align="center">
              <v-btn dark :style="{ 'background-color': '#737fa7' }">All Assets</v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" lg="1" md="2" sm="2" class="pa-0 my-2">
            <v-row class="full-height" justify="center" align="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 15px;">
                CRYPTO
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6" class="pa-0 my-2">
            <v-row class="full-height" justify="center" justify-sm="start" align="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 15px;">
                HIDE ZERO BALANCE ASSETS
              </div>
              <v-switch v-model="hideZeroBalance" :hide-details="true" light class="mt-0 pt-0 mx-2"
                color="#001763"></v-switch>
            </v-row>
          </v-col>
          <v-col cols="12" lg="2" md="8" sm="6" class="pa-0">
            <v-row class="full-height" justify="end" align="center">
              <v-text-field v-model="search" :hide-details="true" class="px-2" dense outlined rounded light
                placeholder="Search" prepend-inner-icon="mdi-search-web"
                :style="{ 'max-width': '280px' }"></v-text-field>
            </v-row>
          </v-col>
        </v-row>

        <v-data-table :headers="headers" :items="filteredTableData" class="elevation-1 full-width mt-6"
          hide-default-footer>
          <template v-slot:item.Name="{ item }">
            <v-row align="center">
              <v-col cols="auto" class="pa-0">
                <v-img v-if="item.png64 !== 'N/A'" :src="item.png64" :alt="item.currency" class="mr-2" height="20"
                  width="20" contain></v-img>
              </v-col>
              <v-col class="pa-0">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 15px;">
                  {{ item.currency }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.Balance="{ item }">
            <v-row align="center">
              <v-col class="pa-0">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 15px;">
                  {{ formatCurrency(item.balance) }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.Rate="{ item }">
            <v-row align="center">
              <v-col class="pa-0">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 15px;">
                  R {{ formatRate(item.rate) }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.Trade="{ item }">
            <v-row no-gutters align="center" justify="end">
              <v-col class="pa-1" cols="auto">
                <v-btn rounded @click="storeAndTrade(item)" color="primary">
                  Trade
                </v-btn>
              </v-col>
              <v-col class="pa-1" cols="auto">
                <v-btn rounded dense @click="redirectToWallet()" class="pink-btn-border-wallet">
                  <v-icon>mdi-wallet</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <v-row justify="center">
          <h5 class="ma-6">
            HODL OTC Assets are held by our acquirer in Fireblocks
          </h5>
        </v-row>
        <v-row justify="center">
          <div class="full-width pa-6" :style="{ 'background-color': '#D0E4FD' }">
            <v-row justify="center">
              <h3>
                Estimated Total Value Of Crypto
                {{ profileBalance.cryptoBalance }}
              </h3>
            </v-row>
          </div>
        </v-row>
      </div>
    </v-row>

    <!-- News Section -->
    <v-row justify="center" v-if="!loading" class="mt-6">
      <v-col cols="12">
        <v-card class="pa-4">
          <div class="text-center">
            <h3>Crypto News</h3>
          </div>
          <v-row v-for="(article, index) in newsArticles" :key="index" class="my-4">
            <v-col cols="12" sm="2" md="3" lg="2">
              <v-row justify="center">
                <v-img :src="article.image_url" class="white-card pointer" :style="{ width: '80px', height: '80px' }"
                  @click="redirectToNewsUrl(article.news_url)"></v-img>

              </v-row>
            </v-col>
            <v-col cols="12" sm="10" md="9" lg="10" :style="{ 'border-bottom': '1px solid lightgrey' }">
              <v-col class="pa-0">
                <v-row>
                  <h5 @click="redirectToNewsUrl(article.news_url)" style="cursor: pointer;">{{ article.title }}</h5>
                </v-row>
                <v-row>
                  <h5 @click="redirectToNewsUrl(article.news_url)" style="cursor: pointer;">{{ article.source_name }}
                  </h5>
                  <h5 class="grey-text mx-2">
                    {{ new Date(article.date).toLocaleString() }}
                  </h5>
                </v-row>
                <v-row>
                  <p>{{ article.text }}</p>

                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Invite Friends Section -->
    <v-row justify="center" v-if="!loading">
      <div class="white-card full-width mx-2 mt-6">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <h2>Invite friends</h2>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="8">
                <v-row justify="center">
                  <v-text-field v-model="friendEmail" :hide-details="true" class="full-width px-2" dense outlined
                    rounded light placeholder="Enter the email of your friend"></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row justify="center">
                  <v-btn rounded class="dark-blue-btn no-width mx-2" @click="handleInviteLink">
                    {{ hasReferralLink ? "Send Invite Link" : "Get Invite Link" }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-btn rounded dense outlined class="dark-blue-text full-width"
                  :href="'https://www.facebook.com/sharer/sharer.php?u=https://hodlotc.com'" target="_blank">
                  <v-icon class="dark-blue-text">mdi-facebook</v-icon>
                  Share
                </v-btn>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn rounded dense outlined class="dark-blue-text full-width"
                  :href="'https://twitter.com/intent/tweet?text=Check%20out%20this%20website!%20https://hodlotc.com'"
                  target="_blank">
                  <v-icon class="dark-blue-text">mdi-twitter</v-icon>
                  Tweet
                </v-btn>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn rounded dense outlined class="dark-blue-text full-width"
                  :href="'https://www.linkedin.com/sharing/share-offsite/?url=https://hodlotc.com'" target="_blank">
                  <v-icon class="dark-blue-text">mdi-linkedin</v-icon>
                  Post
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ExchangeTrade from "@/components/ExchangeTrade.vue";
import emailjs from "emailjs-com";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  components: {
    apexchart: VueApexCharts,
    ExchangeTrade,
  },
  data() {
    return {
      friendEmail: "",
      profileBalance: {
        totalProfileBalance: "R 0.00",
        zarBalance: "R 0.00",
        cryptoBalance: "R 0.00",
        cryptoCurrencies: [],
        detailedRates: [],
      },
      donutSeries: [],
      donutOptions: {
        labels: [],
        colors: [
          "#32CD32",
          "#9f12ca",
          "#1baede",
          "#de961b",
          "#FF5733",
          "#C70039",
          "#900C3F",
          "#581845",
          "#FFC300",
          "#DAF7A6",
          "#FF33FF",
          "#33FFFF",
          "#FFFF33",
          "#3333FF",
          "#33FF33",
          "#FF3333",
          "#663399",
          "#FF69B4",
        ],
        chart: { type: "donut", toolbar: { show: false } },
        legend: { show: false },
        dataLabels: { enabled: false },
        responsive: [{ breakpoint: 480, options: { chart: {} } }],
        stroke: { show: false },
      },
      loading: true,
      transferring: false,
      hideZeroBalance: false,
      search: "",
      newsArticles: [],
      headers: [
        {
          text: "Currency",
          align: "start",
          sortable: false,
          value: "Name",
        },
        {
          text: "Balance",
          align: "start",
          sortable: false,
          value: "Balance",
        },
        {
          text: "Rate",
          align: "start",
          sortable: false,
          value: "Rate",
        },
        {
          align: "end",
          sortable: false,
          value: "Trade",
        },
      ],
      hasReferralLink: false,
    };
  },
  computed: {
    isIdentityVerified() {
      return this.clientStatus === "Approved";
    },
    formattedCombinedCurrencyRateData() {
      return this.profileBalance.cryptoCurrencies.map((currency) => {
        const detailedRate = this.profileBalance.detailedRates.find(
          (rate) => rate.code === currency.currency
        );
        return {
          ...currency,
          rate: detailedRate ? detailedRate.rate : "N/A",
          png64: detailedRate ? detailedRate.png64 : "N/A",
        };
      });
    },
    filteredTableData() {
      const filteredData = this.formattedCombinedCurrencyRateData.filter(
        (currency) =>
          (!this.hideZeroBalance ||
            parseFloat(this.formatCurrency(currency.balance)) > 0) &&
          currency.currency.toLowerCase().includes(this.search.toLowerCase())
      );
      return filteredData;
    },
  },
  methods: {
    redirectToReportIssue() {
      window.open("https://forms.gle/otpuxB5SXQGYy6KM8", "_blank");
    },
    async fetchClientEmailFromFirebase() {
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const collections = ["individuals", "companies", "trusts"];
        for (let collection of collections) {
          const userRef = doc(db, collection, user.uid);
          try {
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
              const userData = docSnap.data();
              if (userData.clientSN) {
                this.clientSN = userData.clientSN;
                this.clientStatus = userData.state;
                await this.fetchProfileBalance();
                await this.checkReferralLink();
              } else {
                console.log("clientSN not found in the document.");
              }
            } else {
              console.log(`No document found in ${collection} collection.`);
            }
          } catch (error) {
            console.error("Error fetching user document:", error);
          }
        }
      } else {
        console.log("No user logged in");
      }
      this.loading = false;
    },
    async fetchProfileBalance() {
      if (!this.clientSN) {
        console.error("Client SN is not available.");
        return;
      }
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/ApiCalculation/GetProfileBalance/${this.clientSN}`,
          requestOptions
        );
        if (!response.ok) throw new Error("Response from API was not ok");
        const data = await response.json();
        this.profileBalance = data;
        this.donutSeries = this.profileBalance.cryptoCurrencies.map(
          (currency) => currency.balance
        );
        this.donutOptions.labels = this.profileBalance.cryptoCurrencies.map(
          (currency) => currency.currency
        );
      } catch (error) {
        console.error("Failed to fetch profile balance:", error);
        this.profileBalance = {
          totalProfileBalance: "R 0.00",
          zarBalance: "R 0.00",
          cryptoBalance: "R 0.00",
          cryptoCurrencies: [],
          detailedRates: [],
        };
      }
    },
    async fetchNewsArticles() {
      try {
        const response = await fetch(
          "https://cryptonews-api.com/api/v1/category?section=general&items=10&page=1&token=6jl2qhlyf17wddnhjijnll1isjh5o7hgoktpuzf6"
        );
        const data = await response.json();
        this.newsArticles = data.data;
      } catch (error) {
        console.error("Error fetching news articles:", error);
      }
    },
    redirectToWallet() {
      this.$router.push("/viewcurrency?type=Wallet");
    },
    storeAndTrade(item) {
      localStorage.setItem("selectedCoin", JSON.stringify(item));
      this.$router.push("/exchange");
    },
    formatCurrency(value) {
      const number = parseFloat(value);
      return isNaN(number) ? "N/A" : number.toFixed(7);
    },
    formatRate(value) {
      const number = parseFloat(value);
      return isNaN(number) ? "N/A" : number.toFixed(2);
    },
    async checkReferralLink() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const collections = ["individuals", "companies", "trusts"];
        this.hasReferralLink = false;
        for (let collection of collections) {
          const userRef = doc(db, collection, user.uid);
          try {
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
              this.hasReferralLink = true;
              break;
            }
          } catch (error) {
            console.error(
              "Error checking referral link in collection: ",
              collection,
              error
            );
          }
        }
      }
    },
    async handleInviteLink() {
      console.log("Friend Email:", this.friendEmail); // Debugging log
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const collections = ["individuals", "companies", "trusts"];
        let referralLink = null;
        for (let collection of collections) {
          const docRef = doc(db, collection, user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            referralLink = docSnap.data().referralLink;
            this.referralLink = referralLink; // Assign to `this.referralLink`
            console.log('Referral Link:', this.referralLink); // Debugging log
            break;
          }
        }

        if (this.referralLink) {
          emailjs
            .send(
              "service_9esjl09",
              "template_r2guo1c",
              {
                to_email: this.friendEmail,
                message: this.referralLink, // Use `this.referralLink`
              },
              "mr1E1BaGMKZMEzWVu"
            )
            .then(
              () => {
                alert("Invite link sent successfully!");
              },
              (error) => {
                console.log("FAILED...", error);
                alert("Failed to send invite link.");
              }
            );
        } else {
          alert(
            "No referral link found for the current user. Please create one first."
          );
          this.$router.push("/options?section=ReferaFriend");
        }
      }
    },
    redirectToNewsUrl(url) {
      window.open(url, "_blank");
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchClientEmailFromFirebase();
    await this.fetchNewsArticles();
    this.loading = false;
  },
};
</script>

<style>
/* Add your custom styles here */
.account .v-input__slot {
  min-height: unset !important;
}

.account .v-input__prepend-inner {
  margin: 0 !important;
}

.scrollable-col {
  max-height: 160px;
  overflow-y: auto;
}

.account .v-input__icon {
  margin-top: 6px;
  margin-left: 5px;
}

.account ::placeholder {
  color: black !important;
}

.account thead span {
  font-weight: bold;
  color: black;
  font-size: 14px;
}

.identity-verification {
  background-image: url("../assets/images/App-Bar-Background.png");
  min-height: 100px;
  background-size: cover;
}

.equal-height-columns>.v-col {
  display: flex;
  flex-direction: column;
}

.equal-height-columns .v-col .white-card {
  flex-grow: 1;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.iframe-container {
  max-width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotating-loader {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
