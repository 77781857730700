<template>
  <v-container class="pa-0 full-width full-height">
    <div class="background full-height full-width">
      <v-row justify="center">
        <v-card class="transparent-card card mt-16" outlined>
          <div class="text-h4 py-4 white-text">Two-Factor Authentication</div>
          <v-text-field
            label="Enter your 2FA code"
            v-model="code"
            autofocus
            dark
            rounded
            class="white--text"
            outlined
            dense
          ></v-text-field>
          <div class="text-center">
            <v-btn
              :disabled="isButtonDisabled"
              color="pink"
              dense
              rounded
              dark
              @click="sendVerificationCode"
            >
              {{ isButtonDisabled ? "Wait..." : "Send Verification Code" }}
            </v-btn>
            <v-btn color="primary" dense rounded dark @click="verifyCode">
              Verify
            </v-btn>
          </div>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import { db } from "../firebaseConfig";
  import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";
  import emailjs from "@emailjs/browser";

  export default {
    name: "TwoFAModal",
    props: {
      value: Boolean,
      userEmail: String, // Assuming you pass the user's email as a prop
    },
    data() {
      return {
        visible: this.value,
        code: "",
        isButtonDisabled: false, // Disable button initially
      };
    },
    watch: {
      value(val) {
        this.visible = val;
      },
    },
    mounted() {
      this.sendVerificationCode(); // Send verification code when component is mounted
    },
    methods: {
      async sendVerificationCode() {
        const otp = Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit OTP
        try {
          console.log(this.userEmail);
          // Store OTP in Firestore under the 'otp' collection with the user's email as the document ID
          await setDoc(doc(db, "otp", this.userEmail), {
            code: otp,
            timestamp: new Date(),
          });

          // Prepare the email parameters
          const emailParams = {
            to_email: this.userEmail,
            message: otp.toString(),
            // Your template variable for the OTP code
          };

          // Send the OTP to the user's email using EmailJS
          await emailjs.send(
            "service_9esjl09",
            "template_4ykp3e4",
            emailParams,
            "mr1E1BaGMKZMEzWVu"
          );
          alert("Verification code sent to your email.");

          // Disable the button for 30 seconds
          this.isButtonDisabled = true;
          setTimeout(() => {
            this.isButtonDisabled = false;
          }, 30000);
        } catch (error) {
          console.error("Error sending verification code:", error);
          alert("Failed to send verification code.");
        }
      },
      async verifyCode() {
        const otpRef = doc(db, "otp", this.userEmail); // Reference to the OTP document
        try {
          const docSnap = await getDoc(otpRef);
          if (
            docSnap.exists() &&
            docSnap.data().code.toString() === this.code
          ) {
            // OTP matches
            await deleteDoc(otpRef); // Delete OTP document
            this.$emit("verified"); // Optionally emit an event
            this.visible = false; // Close the modal
            this.$router.push("/account"); // Navigate to the account page
          } else {
            alert("Incorrect or expired code."); // Handle incorrect OTP
          }
        } catch (error) {
          console.error("Error verifying code:", error);
          alert("Verification failed.");
        }
      },
    },
  };
</script>

<style scoped>
  .card {
    margin-top: 50%;
    max-width: 600px; /* Adjusted to match the width of the login card */
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    color: white;
    border-radius: 10px; /* Rounded corners */
  }

  .background {
    background-image: url("../assets/images/Login.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .textfield,
  .v-text-field__slot {
    background-color: rgba(
      255,
      255,
      255,
      0.1
    ) !important; /* Lighter text field background for visibility */
    color: white !important;
  }

  .v-label {
    color: #ccc !important;
  }

  .custom-btn {
    margin-top: 10px !important;
    border: 1px solid #fff !important; /* Ensure visible border */
    color: #fff !important;
    background-color: transparent !important; /* Transparent background */
  }

  .custom-btn:hover {
    background-color: #fff !important;
    color: #000 !important;
  }

  .white-text {
    color: #fff !important; /* Ensure all text is white for visibility */
  }

  .text-center {
    text-align: center;
  }
</style>
