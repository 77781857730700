var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"options full-width",style:({ padding: '2%' })},[_c('transition',{attrs:{"name":"slide"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('h4',{staticClass:"pa-2 nav-item",class:{'nav-item-active': _vm.$route.query.section == 'AccountInfo'},on:{"click":function($event){return _vm.goToPage('AccountInfo')}}},[_vm._v(" Account Info ")]),_c('h4',{staticClass:"pa-2 nav-item",class:{
            'nav-item-active': _vm.$route.query.section == 'IdentityVerification',
          },on:{"click":function($event){return _vm.goToPage('IdentityVerification')}}},[_vm._v(" Identity Verification ")]),_c('h4',{staticClass:"pa-2 nav-item",class:{
            'nav-item-active': _vm.$route.query.section == 'LoyaltyLevels',
          },on:{"click":function($event){return _vm.goToPage('LoyaltyLevels')}}},[_vm._v(" Loyalty Levels ")]),_c('h4',{staticClass:"pa-2 nav-item",class:{
            'nav-item-active': _vm.$route.query.section == 'ReferaFriend',
          },on:{"click":function($event){return _vm.goToPage('ReferaFriend')}}},[_vm._v(" Refer a friend ")]),_c('h4',{staticClass:"pa-2 nav-item",class:{
            'nav-item-active': _vm.$route.query.section == 'Affiliate',
          },on:{"click":function($event){return _vm.goToPage('Affiliate')}}},[_vm._v(" Affiliate ")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10"}},[(_vm.$route.query.section == 'AccountInfo')?_c('AccountInfo'):_vm._e(),(_vm.$route.query.section == 'IdentityVerification')?_c('IdentityVerification'):_vm._e(),(_vm.$route.query.section == 'LoyaltyLevels')?_c('LoyaltyLevels'):_vm._e(),(_vm.$route.query.section == 'ReferaFriend')?_c('ReferAFriend'):_vm._e(),(_vm.$route.query.section == 'Affiliate')?_c('Affiliate'):_vm._e(),(_vm.$route.query.section == 'Settings')?_c('Settings'):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }