<template>
  <v-container class="account pa-8 full-width">
    <!-- Loading section -->
    <v-row justify="center" v-if="loading" class="loader-container">
      <v-col cols="12" class="text-center">
        <v-icon class="mdi mdi-bitcoin rotating-loader" color="primary" size="64"></v-icon>
        <div style="font-family: 'Montserrat', sans-serif; font-size: 24px" class="text-center">
          LOADING...
        </div>
      </v-col>
    </v-row>

    <!-- Existing Total Balance Section -->
    <v-row class="pa-6" justify="center" v-if="!loading">
      <v-card class="full-width ma-3 white-card" rounded outlined>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-row justify="center" justify-md="start">
              <v-col>
                <v-row justify="center" justify-md="start">
                  <div style="font-family: 'Montserrat', sans-serif; font-size: 20px" class="grey-text">TOTAL BALANCE</div>
                </v-row>
                <v-row align="center" justify="center" justify-md="start">
                  <div style="font-family: 'Montserrat', sans-serif; font-size: 20px">{{ combinedZarBalance }}</div>
                </v-row>
              </v-col>
              <v-col>
                <v-row justify="center" justify-md="start">
                  <div style="font-family: 'Montserrat', sans-serif; font-size: 20px" class="grey-text">ZAR BALANCE</div>
                </v-row>
                <v-row align="center" justify="center" justify-md="start">
                  <div style="font-family: 'Montserrat', sans-serif; font-size: 20px"> {{ totalZarBalance }}</div>
                </v-row>
              </v-col>
              <v-col>
                <v-row justify="center" justify-md="start">
                  <div style="font-family: 'Montserrat', sans-serif; font-size: 20px" class="grey-text">TOTAL CRYPTO</div>
                </v-row>
                <v-row align="center" justify="center" justify-md="start">
                  <div style="font-family: 'Montserrat', sans-serif; font-size: 20px">R {{ totalZarAmount.toFixed(2) }}</div>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="full-height" justify="center" justify-md="end" align="center">
              <!-- Additional content can be added here -->
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-dialog width="500px" v-if="transfering" v-model="transfering">
        <WalletTransfer @close="transfering = false" />
      </v-dialog>
    </v-row>

    <!-- Data Table Section -->
    <v-row justify="center" v-if="!loading">
      <div class="white-card full-width mx-2 mt-6 pa-0">
        <v-row class="px-6" justify="center" justify-sm="start">
          <v-col cols="12" lg="2" md="2" sm="3" class="pa-0 my-2">
            <v-row class="full-height" justify="center" justify-sm="start" align="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 24px">Trading Assets</div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="3" class="pa-0 my-2">
            <v-row class="full-height" justify="center" align="center">
              <v-btn :style="{ 'background-color': '#737fa7' }">All Assets</v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" lg="1" md="2" sm="2" class="pa-0 my-2">
            <v-row class="full-height" justify="center" align="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 16px">Crypto</div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6" class="pa-0 my-2">
            <v-row class="full-height" justify="center" justify-sm="start" align="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 16px">Hide Zero Balance Assets</div>
              <v-switch v-model="hideZeroBalance" :hide-details="true" light class="mt-0 pt-0 mx-2" color="#001763"></v-switch>
            </v-row>
          </v-col>
          <v-col cols="12" lg="2" md="8" sm="6" class="pa-0">
            <v-row class="full-height" justify="end" align="center">
              <v-text-field v-model="search" :hide-details="true" class="px-2" dense outlined rounded light placeholder="Search" prepend-inner-icon="mdi-search-web" :style="{ 'max-width': '280px' }"></v-text-field>
            </v-row>
          </v-col>
        </v-row>

        <v-data-table :headers="headers" :items="filteredTableData" class="elevation-1 full-width mt-6" hide-default-footer>
          <template v-slot:item.Name="{ item }">
            <v-row align="center">
              <v-col cols="auto" class="pa-0">
                <v-img v-if="item.png64 !== 'N/A'" :src="item.png64" :alt="item.currency" class="mr-2" height="20" width="20" contain></v-img>
              </v-col>
              <v-col class="pa-0">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 16px">{{ item.currency }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.Balance="{ item }">
            <v-row align="center">
              <v-col class="pa-0">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 16px">{{ item.formattedBalance }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.MarketPrice="{ item }">
            <v-row align="center">
              <v-col class="pa-0">
                <div style="font-family: 'Montserrat', sans-serif; font-size: 16px">{{ item.formattedRate }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.Additional="{ item }">
            <v-row align="center" justify="end">
              <v-btn @click="storeAndTrade(item)" rounded class="dark-blue-btn no-width mx-2">Trade</v-btn>
            </v-row>
          </template>
        </v-data-table>
        <v-row justify="center">
          <div style="font-family: 'Montserrat', sans-serif; font-size: 16px">HODL OTC Assets are held by our acquirer in Fireblocks</div>
        </v-row>
        <v-row justify="center">
          <div class="full-width pa-6" :style="{ 'background-color': '#D0E4FD' }">
            <v-row justify="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 16px">
                Estimated Total Value Of Crypto R {{ totalZarAmount.toFixed(2) }}
              </div>
            </v-row>
          </div>
        </v-row>
      </div>
    </v-row>

    <!-- Trading Balance Section -->
    <v-row justify="center" class="equal-height-columns" v-if="!loading">
      <v-col cols="12" class="pa-0 px-2 my-4 d-flex flex-column">
        <v-row justify="center">
          <div class="white-card full-width" @click="$router.push({ path: 'viewcurrency', query: { type: 'Wallet' } })">
            <v-row justify="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 24px" class="text-center">Trading Balance</div>
            </v-row>
            <v-row justify="center">
              <div style="font-family: 'Montserrat', sans-serif; font-size: 24px" :class="$vuetify.breakpoint.width < 600 ? 'text-center' : ''">R {{ totalZarAmount.toFixed(2) }}</div>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="7" class="pa-1 mt-4" justify="end">
                <apexchart height="115%" type="donut" :options="donutOptions" :series="donutSeries"></apexchart>
              </v-col>
              <v-col cols="12" md="5" class="pa-0 mt-4">
                <v-row v-for="(currency, index) in formattedCombinedCurrencyRateData" :key="index" justify="end" align="center" class="my-1">
                  <v-col cols="auto" class="pa-0">
                    <v-img v-if="currency.png64 !== 'N/A'" :src="currency.png64" :alt="currency.currency" class="mr-2" height="20" width="20" contain></v-img>
                  </v-col>
                  <v-col class="pa-0">
                    <div style="font-family: 'Montserrat', sans-serif; font-size: 12px" class="my-1">{{ currency.currency }} {{ currency.formattedBalance }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" justify-sm="end" align="center" class="mt-4">
              <v-btn rounded class="dark-blue-btn mx-2 no-width" @click="$router.push('/trade')">Trade</v-btn>
            </v-row>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Topup from "../components/Topup.vue";
import Withdraw from "../components/Withdraw.vue";
import WalletTransfer from "../components/WalletTransfer.vue";
import ExchangeTrade from "@/components/ExchangeTrade.vue";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  components: {
    apexchart: VueApexCharts,
    Topup,
    Withdraw,
    WalletTransfer,
    ExchangeTrade,
  },
  data() {
    return {
      totalZarBalance: 0,
      totalZarAmount: 0,
      cryptoCurrencies: [],
      clientSN: null,
      fiatCurrencies: ["zar"],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
          width: "150px",
        },
        {
          text: "Balance",
          align: "start",
          sortable: false,
          value: "Balance",
          width: "150px",
        },
        {
          text: "Market Price",
          align: "start",
          sortable: false,
          value: "MarketPrice",
          width: "150px",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "Additional",
          width: "300px",
        },
      ],
      lineOptions: {
        colors: ["#32CD32"],
        chart: { type: "line", toolbar: { show: false } },
        xaxis: { labels: { show: false }, axisBorder: { show: false } },
        yaxis: { show: false },
        grid: { show: false },
      },
      donutSeries: [],
      donutOptions: {
        labels: [],
        colors: ["#32CD32", "#9f12ca", "#1baede", "#de961b"],
        chart: { type: "donut", toolbar: { show: false } },
        legend: { show: false },
        dataLabels: { enabled: false },
        responsive: [{ breakpoint: 480, options: { chart: {} } }],
        stroke: { show: false },
      },
      loading: true,
      transferring: false,
      transfering: false,
      hideZeroBalance: false,
      combinedCurrencyRateData: [],
      detailedRates: [],
      search: '', // Add search data property
    };
  },
  computed: {
    formattedCombinedCurrencyRateData() {
      return this.combinedCurrencyRateData.map((currency) => ({
        ...currency,
        formattedBalance: parseFloat(currency.balance)
          .toFixed(7)
          .replace(/\.?0+$/, ""),
        formattedRate: currency.rate !== "N/A" 
          ? `R ${parseFloat(currency.rate).toFixed(2)}` 
          : currency.rate,
      }));
    },
    combinedZarBalance() {
      const totalBalanceValue = parseFloat(
        this.totalZarBalance.toString().replace(/[^\d.-]/g, "")
      );
      return `R ${(totalBalanceValue + this.totalZarAmount).toFixed(2)}`;
    },
    filteredTableData() {
      return this.hideZeroBalance
        ? this.formattedCombinedCurrencyRateData.filter(
            (currency) => parseFloat(currency.formattedBalance) > 0
          )
        : this.formattedCombinedCurrencyRateData.filter(currency => 
          currency.currency.toLowerCase().includes(this.search.toLowerCase())
        );
    },
  },
  methods: {
    async fetchClientEmailFromFirebase() {
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const collections = ["individuals", "companies", "trusts"];
        for (let collection of collections) {
          const userRef = doc(db, collection, user.uid);
          try {
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
              const userData = docSnap.data();
              if (userData.clientSN) {
                this.clientSN = userData.clientSN;
                await this.fetchProfileBalance();
              } else {
                console.log("clientSN not found in the document.");
              }
            } else {
              console.log(`No document found in ${collection} collection.`);
            }
          } catch (error) {
            console.error("Error fetching user document:", error);
          }
        }
      } else {
        console.log("No user logged in");
      }
      this.loading = false;
    },
    async fetchProfileBalance() {
      if (!this.clientSN) {
        console.error("Client SN is not available.");
        return;
      }
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/ApiCalculation/GetProfileBalance/${this.clientSN}`,
          requestOptions
        );
        if (!response.ok) throw new Error("Response from API was not ok");
        const data = await response.json();
        this.totalZarBalance = data.zarBalance;
        this.totalZarAmount = data.cryptoBalance;
        this.cryptoCurrencies = data.cryptoCurrencies;
        this.detailedRates = data.detailedRates;

        this.donutSeries = this.cryptoCurrencies.map(
          (currency) => currency.balance
        );
        this.donutOptions.labels = this.cryptoCurrencies.map(
          (currency) => currency.currency
        );

        await this.pairDetailedRatesWithCurrencies();
      } catch (error) {
        console.error("Failed to fetch profile balance:", error);
        this.totalZarBalance = "0.00";
        this.totalZarAmount = 0;
        this.cryptoCurrencies = [];
        this.detailedRates = [];
      }
    },
    storeAndTrade(item) {
      localStorage.setItem("selectedCoin", JSON.stringify(item));
      this.$router.push("/exchange");
    },
    async pairDetailedRatesWithCurrencies() {
      if (!this.detailedRates || this.detailedRates.length === 0) {
        console.error("Detailed rates data is not available.");
        return;
      }
      const combinedData = this.cryptoCurrencies.map((currency) => {
        const detailedRate = this.detailedRates.find(
          (rate) =>
            rate.code.toUpperCase() === currency.currency.toUpperCase()
        );
        return {
          currency: currency.currency,
          balance: currency.balance,
          rate: detailedRate ? detailedRate.rate : "N/A",
          png64: detailedRate ? detailedRate.png64 : "N/A",
        };
      });
      this.combinedCurrencyRateData = combinedData;
      await this.calculateAndLogZarAmounts();
    },
    async calculateAndLogZarAmounts() {
      if (
        !this.combinedCurrencyRateData ||
        this.combinedCurrencyRateData.length === 0
      ) {
        console.error("Combined currency rate data is not available.");
        return [];
      }
      const zarAmounts = this.combinedCurrencyRateData.map((currency) => {
        const rate = !isNaN(parseFloat(currency.rate))
          ? parseFloat(currency.rate)
          : 0;
        const balance = !isNaN(parseFloat(currency.balance))
          ? parseFloat(currency.balance)
          : 0;
        const zarAmount = rate * balance;
        return {
          currency: currency.currency,
          zarAmount: parseFloat(zarAmount.toFixed(2)),
        };
      });
      const totalZarAmount = zarAmounts.reduce(
        (total, currency) => total + currency.zarAmount,
        0
      );
      this.totalZarAmount = totalZarAmount;
      return zarAmounts;
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchClientEmailFromFirebase();
    this.loading = false;
  },
};
</script>

<style>
.account .v-input__slot {
  min-height: unset !important;
}

.account .v-input__prepend-inner {
  margin: 0 !important;
}

.scrollable-col {
  max-height: 160px;
  overflow-y: auto;
}

.account .v-input__icon {
  margin-top: 6px;
  margin-left: 5px;
}

.account ::placeholder {
  color: black !important;
}

.account thead span {
  font-weight: bold;
  color: black;
  font-size: 14px;
}

.identity-verification {
  background-image: url("../assets/images/App-Bar-Background.png");
  min-height: 100px;
  background-size: cover;
}

.equal-height-columns > .v-col {
  display: flex;
  flex-direction: column;
}

.equal-height-columns .v-col .white-card {
  flex-grow: 1;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.iframe-container {
  max-width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotating-loader {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
