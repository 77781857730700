<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="pa-4 white-card d-flex flex-column" height="100%">
            <v-skeleton-loader
              v-if="isLoadingBalances"
              type="card"
              class="white-card"
            ></v-skeleton-loader>
            <template v-else>
              <v-row justify="center" style="font-family: 'Montserrat', sans-serif; font-size: 22px">
                Balances:
              </v-row>
              <v-row>
                <v-select
                  v-model="selectedCurrency"
                  :items="currenciesWithBalance"
                  label="Select Currency"
                  dense
                  class="my-4"
                  outlined
                  @change="handleCurrencyChange"
                ></v-select>
              </v-row>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="font-family: 'Montserrat', sans-serif; font-size: 20px">
                      Total Deposited funds
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-family: 'Montserrat', sans-serif; font-size: 20px" class="green--text">
                      R {{ totalBalance }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="font-family: 'Montserrat', sans-serif; font-size: 20px">
                      Available Balance
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-family: 'Montserrat', sans-serif; font-size: 20px" class="green--text">
                      {{ availableBalance }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="font-family: 'Montserrat', sans-serif; font-size: 20px">
                      Locked Balance
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-family: 'Montserrat', sans-serif; font-size: 20px" class="green--text">
                      {{ lockedBalance }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="pa-4 white-card d-flex flex-column" height="100%">
            <v-skeleton-loader
              v-if="isLoadingDepositDetails"
              type="card"
              class="white-card"
            ></v-skeleton-loader>
            <template v-else>
              <v-row justify="center" style="font-family: 'Montserrat', sans-serif; font-size: 22px" class="my-3">
                Deposit Details
              </v-row>
              <v-row justify="center" class="mb-4">
                <v-btn width="120px" :color="isFiat ? 'primary' : 'grey'" @click="isFiat = true" class="mr-2">
                  Fiat
                </v-btn>
                <v-btn width="120px" :color="!isFiat ? 'primary' : 'grey'" @click="isFiat = false">
                  Crypto
                </v-btn>
              </v-row>
              <v-form v-if="isFiat">
                <v-row justify="end">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                    </template>
                    <span style="width:200px; display: block;">
                      Please ensure that you use the reference provided above when making a deposit. This reference is unique to your account and helps us identify that a given bank transfer belongs to your account. Cash Deposits will not be accepted. Third party deposits are prohibited and may lead to account suspension.
                    </span>
                  </v-tooltip>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Recipient Name"
                      value="HODL"
                      outlined
                      dense
                      readonly
                    >
                      <template v-slot:append>
                        <v-btn icon @click="copyText('HODL')">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Bank"
                      value="Standard Bank"
                      outlined
                      dense
                      readonly
                    >
                      <template v-slot:append>
                        <v-btn icon @click="copyText('Standard Bank')">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Account Number"
                      value="003273865"
                      outlined
                      dense
                      readonly
                    >
                      <template v-slot:append>
                        <v-btn icon @click="copyText('003273865')">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Branch Code"
                      value="051001"
                      outlined
                      dense
                      readonly
                    >
                      <template v-slot:append>
                        <v-btn icon @click="copyText('051001')">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Account Type"
                      value="Current/Cheque"
                      outlined
                      dense
                      readonly
                    >
                      <template v-slot:append>
                        <v-btn icon @click="copyText('Current/Cheque')">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Reference"
                      :value="clientSN"
                      outlined
                      dense
                      readonly
                    >
                      <template v-slot:append>
                        <v-btn icon @click="copyText(clientSN)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-form v-else>
                <v-select
                  label="Deposit Currency"
                  v-model="depositCurrency"
                  :items="currenciesWithBalance"
                  @change="fetchDepositAddress"
                  outlined
                  dense
                ></v-select>
                <v-text-field
                  v-if="isLoadingDepositAddress"
                  label="Deposit Address"
                  value="Loading..."
                  outlined
                  dense
                  readonly
                ></v-text-field>
                <v-text-field
                  v-else
                  label="Deposit Address"
                  :value="currentDepositAddress"
                  outlined
                  dense
                  readonly
                >
                  <template v-slot:append>
                    <v-btn icon @click="copyText(currentDepositAddress)">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-row>
                  <v-btn icon color="primary" @click="copyText(currentDepositAddress)" class="ma-2">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn icon color="primary" @click="generateQRCode" class="ma-2">
                    <v-icon>mdi-qrcode</v-icon>
                  </v-btn>
                </v-row>
              </v-form>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4 white-card">
            <v-skeleton-loader
              v-if="isLoadingDepositHistory"
              type="table"
              class="white-card"
            ></v-skeleton-loader>
            <template v-else>
              <v-row justify="center" class="my-3" style="font-family: 'Montserrat', sans-serif; font-size: 22px">
                Deposit History
              </v-row>
              <v-text-field
                v-model="search"
                label="Search"
                class="mb-4"
                outlined
                dense
              ></v-text-field>
              <v-data-table
                :headers="depositHeaders"
                :items="filteredDeposits"
                class="mt-4"
              ></v-data-table>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="showDialog" persistent max-width="300px">
        <v-card>
          <v-card-title class="text-h6">QR Code</v-card-title>
          <v-card-text>
            <VueQr :text="currentDepositAddress" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" rounded @click="closeDialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import "vue-toastification/dist/index.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      isLoadingBalances: true,
      isLoadingDepositDetails: true,
      isLoadingDepositHistory: true,
      isLoadingDepositAddress: true, // Added loading state for deposit address
      isFiat: true,
      selectedCurrency: "BTC",
      currenciesWithBalance: [],
      depositCurrency: "BTC",
      allDepositAddresses: [],
      clientSN: "",
      showDialog: false,
      totalBalance: "",
      availableBalance: "",
      currentDepositAddress: "",
      lockedBalance: "0.00",
      banks: ["Standard Bank", "Bank 1", "Bank 2", "Bank 3"],
      depositHeaders: [
        { text: "Date", value: "completed_at", sortable: false, align: "start" },
        { text: "Asset", value: "currency" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "state" },
      ],
      depositHistory: [],
      search: "",
      page: 1,
      pages: 10,
    };
  },
  created() {
    this.fetchUserData();
  },
  watch: {
    selectedCurrency(newCurrency) {
      this.fetchAvailableBalance(newCurrency);
    },
  },
  computed: {
    filteredDeposits() {
      if (!this.search) {
        return this.depositHistory;
      }
      const searchTerm = this.search.toLowerCase();
      return this.depositHistory.filter((deposit) => {
        return (
          deposit.completed_at.toLowerCase().includes(searchTerm) ||
          deposit.currency.toLowerCase().includes(searchTerm) ||
          deposit.amount.toString().toLowerCase().includes(searchTerm) ||
          deposit.state.toLowerCase().includes(searchTerm)
        );
      });
    },
  },
  methods: {
    async fetchUserData() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const db = getFirestore();
          const collections = ["individuals", "companies", "trusts"];
          for (let collection of collections) {
            try {
              const docRef = doc(db, collection, uid);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists() && docSnap.data().clientSN) {
                this.clientSN = docSnap.data().clientSN;
                this.fetchCurrenciesWithBalances();
                this.fetchDeposits();
                this.fetchDepositAddress();
                this.fetchAvailableBalance(this.selectedCurrency);
                break;
              }
            } catch (error) {
              console.error("Error fetching clientSN:", error);
            }
          }
        } else {
          console.log("User is signed out");
        }
      });
    },
    async fetchDeposits() {
      try {
        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetDeposits?sn=${this.clientSN}`
        );
        const deposits = await response.json();
        this.depositHistory = deposits
          .filter((deposit) => deposit.sn === this.clientSN)
          .map((deposit) => ({
            ...deposit,
            currency: deposit.currency.toUpperCase(),
            completed_at: this.formatDate(deposit.completed_at),
            state: this.capitalizeFirstLetter(deposit.state),
            amount: parseFloat(deposit.amount.replace(/,/g, "")),
          }));
        this.totalBalance = this.depositHistory
          .reduce((acc, deposit) => acc + deposit.amount, 0)
          .toFixed(2);
        this.isLoadingDepositHistory = false; // Stop loading after fetching data
      } catch (error) {
        console.error("Failed to fetch deposit data:", error);
        this.isLoadingDepositHistory = false; // Stop loading even if there is an error
      }
    },
    async fetchCurrenciesWithBalances() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetAccounts`,
          requestOptions
        );
        const allData = await response.json();
        this.currenciesWithBalance = allData
          .filter((account) => account.sn === this.clientSN)
          .map((account) => account.currency.toUpperCase())
          .filter((value, index, self) => self.indexOf(value) === index);
        this.isLoadingBalances = false; // Stop loading after fetching data
      } catch (error) {
        console.error("Error fetching currencies with balances:", error);
        this.isLoadingBalances = false; // Stop loading even if there is an error
      }
    },
    async fetchDepositAddress() {
      if (!this.depositCurrency || !this.clientSN) {
        return;
      }

      this.isLoadingDepositAddress = true; // Start loading before fetching data

      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetDepositAddress?coin=${this.depositCurrency}&sn=${this.clientSN}`,
          requestOptions
        );
        const data = await response.json();
        const addressData = data.find(
          (item) =>
            item.currency.toUpperCase() ===
              this.depositCurrency.toUpperCase() && item.sn === this.clientSN
        );

        if (addressData && addressData.address) {
          this.currentDepositAddress = addressData.address;
        } else {
          this.currentDepositAddress =
            "No address available for this currency";
        }
        this.isLoadingDepositAddress = false; // Stop loading after fetching data
        this.isLoadingDepositDetails = false; // Stop loading after fetching data
      } catch (error) {
        console.error("Error fetching deposit address:", error);
        this.currentDepositAddress = "Error fetching address";
        this.isLoadingDepositAddress = false; // Stop loading even if there is an error
        this.isLoadingDepositDetails = false; // Stop loading even if there is an error
      }
    },
    async fetchAvailableBalance(currency) {
      if (!this.clientSN) {
        console.error("Client SN is not available.");
        return;
      }
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetAccounts`,
          requestOptions
        );
        const allData = await response.json();
        const filteredData = allData.find(
          (account) =>
            account.sn === this.clientSN &&
            account.currency.toUpperCase() === currency
        );
        this.availableBalance = filteredData ? filteredData.balance : "0.00";
        this.isLoadingBalances = false; // Stop loading after fetching data
      } catch (error) {
        console.error("Failed to fetch available balance:", error);
        this.availableBalance = "Error fetching balance";
        this.isLoadingBalances = false; // Stop loading even if there is an error
      }
    },
    copyText(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$toast.success("Text copied to clipboard!");
        },
        (err) => {
          console.error("Failed to copy the text:", err);
          this.$toast.error("Failed to copy the text.");
        }
      );
    },
    generateQRCode() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    handleCurrencyChange() {
      this.fetchDepositAddress();
      this.fetchAvailableBalance(this.depositCurrency);
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")} ${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    },
    capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
  },
};
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
  color: var(--v-theme-primary);
}
.v-btn.icon {
  border-radius: 50%;
}
.elevation-10 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.white-card {
  background: #ffffff;
  border-radius: 15px;
  width: 100%;
}
body {
  background: #f5f5f5;
  color: #333;
  font-family: "Roboto", sans-serif;
}
.v-container {
  padding: 20px;
}
.v-alert {
  background: rgba(255, 0, 0, 0.1);
  color: #000000;
  border-radius: 10px;
}
</style>
