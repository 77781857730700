<template>
  <div class="background full-height full-width">
    <v-container class="pt-0"> <!-- Reduced padding-top for the container -->
      <v-row justify="center" > <!-- Negative margin-top to move the content up -->
        <v-col cols="12" sm="8" md="6">
          <v-card class="pa-5 elevation-6 mt-16" color="#3949AB" dark> <!-- Enhanced styling for the card -->
            <h1>Welcome to Account Verification</h1>
            <p v-if="statusMessage">{{ statusMessage }}</p>

            <!-- Display Email -->
            <p v-if="email">Your Email: {{ email }}</p>

            <!-- Lively Content -->
            <v-divider class="my-5"></v-divider> <!-- Adjusted margins for consistency -->
            <p>Thank you for joining us! You're just one step away from unlocking all the exciting features.</p>
            <p>Please remember to upload your documents and complete your profile to get full access.</p>
          
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { auth, db } from "@/firebaseConfig";
  import { signInWithEmailAndPassword } from "firebase/auth";
  import { doc, getDoc } from "firebase/firestore";

  export default {
    data() {
      return {
        statusMessage: "",
        email: "", // Added email data property
        password: "",
        showPassword: false,
        uid: null,
      };
    },
    mounted() {
      this.extractUID();
      this.initiateAutoVerification();
    },
    methods: {
      extractUID() {
        const url = window.location.href;
      

        const urlParams = new URLSearchParams(window.location.search);
        this.uid = urlParams.get("uid");
      
        if (!this.uid) {
          this.statusMessage = "Invalid verification link";
        }
      },
      async getUserEmailByUID(uid) {
        try {
          const userCollections = ["individuals", "companies", "trusts"];
          for (const collection of userCollections) {
            const userDocRef = doc(db, collection, uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists() && userDoc.data().email) {
              this.email = userDoc.data().email; // Set email
              return this.email;
            }
          }
          this.statusMessage = "User not found in any collection";
          return null;
        } catch (error) {
          console.error("Error fetching user email:", error);
          this.statusMessage = `Error fetching user email: ${error}`;
          return null;
        }
      },
      async initiateAutoVerification() {
        const email = await this.getUserEmailByUID(this.uid);
        if (email) {
          this.verifyEmail();
        }
      },

      async verifyEmail() {
        if (!this.uid) return;

        try {
          const verificationResponse = await fetch(
            "https://europe-west2-hodl-f3ae3.cloudfunctions.net/verifyUserEmail",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ uid: this.uid }),
            }
          );

          const responseBody = await verificationResponse.json();

          // Handling based on HTTP status codes
          switch (verificationResponse.status) {
            case 200: // Success
              this.statusMessage = responseBody.message;
              const userData = await this.fetchUserDataFromAPI(this.email);
              if (userData) {
                await this.sendVerifiedStatusToApi(this.email);
              };
              break;
            case 400: // Bad Request
            case 403: // Forbidden
            case 500: // Internal Server Error
              throw new Error(responseBody.error);
            default:
              throw new Error("Unexpected response from the server");
          }
        } catch (error) {
          console.error("Verification Error:", error);
          this.statusMessage = `Verification failed: ${error.message}`;
        }
      },
      async fetchUserDataFromAPI(email) {
        const myHeaders = new Headers();
        myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");

        try {
          const response = await fetch(
            `https://hodl-vm.northeurope.cloudapp.azure.com/api/registrationSelect?email=${email}`,
            {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            }
          );
          const result = await response.json();
        
          
          return result; // Process and return the user data
        } catch (error) {
          console.error("Error fetching data from API:", error);
          return null; // Handle the error appropriately
        }
      },
      async sendVerifiedStatusToApi(email) {
        const myHeaders = new Headers();
        myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");
        myHeaders.append("Content-Type", "application/json");

        const payload = {
          Email: email,
          EmailVerified: "1",
        };

        try {
          const response = await fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/registrationUpdate",
            {
              method: "POST",
              headers: myHeaders,
              body: JSON.stringify(payload),
              redirect: "follow",
            }
          );
          const result = await response.text();
      
          // Handle the response from the API
        } catch (error) {
          console.error("Error sending verification status to API:", error);
        }
      },
    },
  };
</script>

<style scoped>
  .background {
    background-image: url("../assets/images/Login.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .description {
    color: white;
  }
  .header {
    text-align: center;
  }

  .card {
    margin: 0 auto;
    margin-top: 350px;
    max-width: 600px;
    max-height: 600px;
  }
  .full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.card {
  border-radius: 12px;
}

.text-white {
  color: white;
  font-size: 1.5rem; /* Adjust the font size as needed */
}

.mb-4 {
  margin-bottom: 2rem; /* Adjust the margin bottom as needed */
}

.my-6 {
  margin-top: 3rem; /* Adjust the margin top as needed */
  margin-bottom: 3rem; /* Adjust the margin bottom as needed */
}
</style>