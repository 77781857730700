<template>
  <v-container class="view-currency pa-0 full-width">
    <v-row justify="center">
      <div class="white-card pb-0 px-12 no-radius full-width">
    
        <v-row>
          <v-col cols="12" sm="6" md="6" class="pa-0 mt-4">
            <v-row v-if="pageType == 'market'" justify="center" justify-sm="start">
              <img :src="selectedCurrency.icon_url" width="40px" />
              <h3 class="mx-2">{{ selectedCurrency.name }}</h3>
              <v-select
                dense
                :items="currencies"
                :style="{ 'max-width': '120px' }"
                label="Currency"
                class="no-elavation"
                solo
                hide-details
                item-text="name"
                item-value="id"
                v-model="selectedCurrencyId"
                @change="updateSelectedCurrency"
              ></v-select>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pa-0 mt-4">
            <v-row justify="center" justify-sm="end">
              <v-btn rounded class="dark-blue-btn mx-2 my-2" :style="{ width: '140px' }" @click="$router.push('/deposit')">Deposit</v-btn>
              <v-btn rounded class="pink-btn-border mx-2 my-2" :style="{ width: '140px' }" @click="$router.push('/withdrawal')">Withdrawal</v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-tabs v-model="pageType">
            <v-tab tab-value="market" @click="$router.push({ query: { type: 'Market' } })">Market</v-tab>
            <v-tab tab-value="wallet" @click="$router.push({ query: { type: 'Wallet' } })">Wallet</v-tab>
          </v-tabs>
        </v-row>
      </div>
    </v-row>
    <component :is="currentComponent" />
  </v-container>
</template>

<script>
import axios from "axios";
import MarketComponent from "@/components/MarketComponent.vue";
import WalletComponent from "@/components/WalletComponent.vue";

export default {
  data() {
    return {
      pageType: "market",
      currencies: [],
      selectedCurrencyId: null,
      selectedCurrency: {
        id: null,
        name: "Token",
        icon_url: "../assets/images/Ethereum-Logo.png",
      },
    };
  },
  computed: {
    currentComponent() {
      return this.pageType === "market" ? MarketComponent : WalletComponent;
    },
  },
  created() {
    if (this.$route.query.type == "Market") {
      this.pageType = "market";
    } else if (this.$route.query.type == "Wallet") {
      this.pageType = "wallet";
    }

    this.loadSelectedCurrencyFromLocalStorage();
    this.fetchCurrencies();
  },
  methods: {
    fetchCurrencies() {
      axios
        .get("https://hodl-vm.northeurope.cloudapp.azure.com/api/OvexCurrencies")
        .then((response) => {
          this.currencies = response.data;
          // Set initial selected currency to the one from localStorage or the first one
          if (this.selectedCurrencyId) {
            this.updateSelectedCurrency();
          } else if (this.currencies.length > 0) {
            this.selectedCurrencyId = this.currencies[0].id;
            this.updateSelectedCurrency();
          }
        })
        .catch((error) => {
          console.error("Error fetching currencies:", error);
        });
    },
    updateSelectedCurrency() {
      const currency = this.currencies.find((c) => c.id === this.selectedCurrencyId);
      if (currency) {
        this.selectedCurrency = currency;
        // Store selected currency ID in localStorage
        localStorage.setItem('selectedCurrencyId', this.selectedCurrencyId);
      }
    },
    loadSelectedCurrencyFromLocalStorage() {
      const storedCurrencyId = localStorage.getItem('selectedCurrencyId');

      if (storedCurrencyId) {
        this.selectedCurrencyId = storedCurrencyId;
      }
    }
  },
};
</script>
