<template>
  <v-row class="loyalty-levels" justify="center">
    <v-card
      rounded
      class="white-card full-width py-8"
      :style="{ padding: '10%' }"
    >
      <h4>HODL OTC Loyalty Levels</h4>
      <p class="grey-text mt-2">
        The Hodl Loyalty program is four-tier system, comprising Basic, Silver,
        Gold and Platinum levels, which extends benefits depending on the amount
        of HODL Tokens you hold across your Portfolio Balance
      </p>
      <v-row justify="start" class="mt-4">
        <v-col cols="12" lg="4" class="pa-4 my-2">
          <h3 class="blue-text">11.5%</h3>
          <p class="grey-text">Of your portfolio in HODL Tokens</p>
          <v-btn class="dark-blue-btn full-width mt-6">
            Buy HODL OTC Tokens to Upgrade
          </v-btn>
        </v-col>
        <v-divider vertical> </v-divider>
        <v-col cols="12" md="2" class="pa-4 my-2">
          <v-row justify="center">
            <img height="60px" src="../assets/images/Basic-Crown.svg" />
          </v-row>
          <v-row justify="center">
            <h4>Basic</h4>
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text mt-4">Up to 9.9% HODL in Portfolio</h5>
          </v-row>
        </v-col>
        <v-divider vertical> </v-divider>
        <v-col cols="12" md="2" class="pa-4 my-2">
          <v-row justify="center">
            <img height="60px" src="../assets/images/Silver-Crown.svg" />
          </v-row>
          <v-row justify="center">
            <h4>Silver</h4>
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text mt-4">10% - 13% HODL in Portfolio</h5>
          </v-row>
        </v-col>
        <v-divider vertical> </v-divider>
        <v-col cols="12" md="2" class="pa-4 my-2">
          <v-row justify="center">
            <img height="60px" src="../assets/images/Gold-Crown.svg" />
          </v-row>
          <v-row justify="center">
            <h4>Gold</h4>
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text mt-4">13.01% - 14.99% HODL in Portfolio</h5>
          </v-row>
        </v-col>
        <v-divider vertical> </v-divider>
        <v-col cols="12" md="2" class="pa-4 my-2">
          <v-row justify="center">
            <img height="60px" src="../assets/images/Platinum-Crown.svg" />
          </v-row>
          <v-row justify="center">
            <h4>Platinum</h4>
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text mt-4">Above 15% HODL in Portfolio</h5>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-4">
        <v-col cols="4">
          <v-row justify="center">
            <h4>Borrow Benefits</h4>
          </v-row>
          <v-row justify="center">
            <p class="grey-text">Interest Rates</p>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row class="full-height" justify="center" align="center">
            <p class="grey-text">COMING SOON</p>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-4">
        <v-card
          outlined
          class="full-width"
          :style="{ border: '1px solid black' }"
          height="50px"
        >
        </v-card>
      </v-row>
      <v-row justify="start" class="mt-4">
        <v-card
          class="full-width pa-4"
          :style="{ 'background-color': '#F7F8FC' }"
        >
          <v-row justify="start">
            <h4>Earn Benefits</h4>
          </v-row>
          <v-row justify="start">
            <p>Interest Rates</p>
          </v-row>
        </v-card>
      </v-row>
      <v-row justify="start">
        <div
          class="grid-block pa-1"
          :class="{ 'w-40': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">Crypto Currencies</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
      </v-row>
      <v-row justify="start">
        <div
          class="grid-block pa-1"
          :class="{ 'w-40': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">Fiat Currencies</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey- text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
      </v-row>
      <v-row justify="start">
        <div
          class="grid-block pa-1"
          :class="{ 'w-40': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">Stablecoins</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
      </v-row>
      <v-row justify="start">
        <div
          class="grid-block pa-1"
          :class="{ 'w-40': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">HODL OTC coins</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
        <div
          class="grid-block pa-1"
          :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
        >
          <v-row class="full-height" justify="center" align="center">
            <h5 class="grey-text text-center">1%</h5>
          </v-row>
        </div>
      </v-row>
      <v-row justify="start">
        <v-card
          class="full-width mt-4"
          :style="{ 'background-color': '#F7F8FC' }"
        >
          <v-row justify="start">
            <div
              class="pa-2 w-20"
              :class="{ 'w-40': $vuetify.breakpoint.width < 900 }"
            >
              <h5 class="dark-blue-text">12%</h5>
              <h5>of your portfolio in HODL Token</h5>
            </div>
            <div
              class="pa-2 w-20"
              :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
            >
              <v-row justify="center"
                ><h5 :style="{ 'word-break': 'break-all' }">Unlocked</h5></v-row
              >
            </div>
            <div
              class="pa-2 w-20"
              :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
            >
              <v-row justify="center">
                <h5 :style="{ 'word-break': 'break-all' }">Unlocked</h5>
              </v-row>
            </div>
            <div
              class="pa-2 w-20"
              :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
            >
              <v-row justify="center">
                <h5 :style="{ 'word-break': 'break-all' }">Unlocked</h5>
              </v-row>
            </div>
            <div
              class="pa-2 w-20"
              :class="{ 'w-15': $vuetify.breakpoint.width < 900 }"
            >
              <v-row justify="center">
                <h5 :style="{ 'word-break': 'break-all' }">Current</h5>
              </v-row>
            </div>
          </v-row>
        </v-card>
      </v-row>
      <v-row justify="start">
        <v-card
          class="full-width pa-4 mt-4"
          :style="{ 'background-color': '#441567' }"
        >
          <v-row>
            <v-col cols="9">
              <h4 class="white-text">Your'e Platinum Now</h4>
              <p class="white-text">
                Enjoy our exclusive interest rates, Higher crypto rewards, and
                many more!
              </p>
              <v-btn class="purple-text mt-4">Buy HODL Tokens</v-btn>
            </v-col>
            <v-col cols="3">
              <v-row justify="center" align="end">
                <img
                  width="100px"
                  height="100px"
                  src="../assets/images/Basic-Crown.svg"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.grid-block {
  height: 50px;
  border: 1px solid grey;
  width: 20%;
}

.w-40 {
  width: 40% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20%;
}
</style>