<template>
  <v-row class="withdraw" justify="center">
    <div
      v-if="!confirmingWithdrawel"
      class="white-card"
      :style="{ 'overflow-y': 'hidden', width: '500px', padding: '5%' }"
    >
      <v-row class="mt-4" justify="center">
        <v-col cols="10" class="pa-0">
          <h4>Withdraw</h4>
        </v-col>
        <v-col cols="2" class="pa-0">
          <v-row justify="end">
            <v-icon class="black-text" @click="$emit('close')">
              mdi-close
            </v-icon>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-4 px-6" justify="center">
        <v-col cols="12" md="6" class="pa-0">
          <h5 class="text-center">You can withdraw up to</h5>
        </v-col>
        <v-col cols="12" md="6" class="pa-0">
          <v-row justify="center" justify-sm="end">
            <h3 class="blue-text text-center">BTC 0,0000</h3>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-4" justify="center" justify-md="start">
        <h5>Asset</h5>
      </v-row>
      <v-row justify="center">
        <v-select :hide-details="true" rounded outlined dense> </v-select>
      </v-row>
      <v-row class="mt-4" justify="center" justify-md="start">
        <h5>Network</h5>
      </v-row>
      <v-row justify="center">
        <v-select :hide-details="true" rounded outlined dense> </v-select>
      </v-row>
      <v-row class="mt-4" justify="center" justify-md="start">
        <v-col cols="6" class="pa-0">
          <v-row justify="start">
            <h5>Withdrawal To</h5>
          </v-row>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-row justify="end">
            <h6 @click="$router.push('/addressbook')" class="blue-text pointer">
              Address Book
            </h6>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-radio-group class="full-width mt-0" v-model="withdrawTo">
          <v-row justify="center">
            <v-col cols="6" class="pa-0">
              <v-row justify="center">
                <v-card
                  @click="withdrawTo = 'NewWalletAddress'"
                  outlined
                  rounded
                  class="pa-1 full-width mr-2"
                >
                  <v-row justify="center" align="center">
                    <v-col cols="4" sm="2">
                      <v-radio
                        on-icon="mdi-radiobox-marked"
                        off-icon="mdi-radiobox-blank"
                        rounded
                        :hide-details="true"
                        class="mt-0 pt-0"
                        value="NewWalletAddress"
                      >
                      </v-radio>
                    </v-col>
                    <v-col cols="8" sm="10">
                      <h5 class="text-center">New Wallet Address</h5>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-row justify="center">
                <v-card
                  @click="withdrawTo = 'AddressBook'"
                  outlined
                  rounded
                  class="pa-1 full-width ml-2"
                >
                  <v-row justify="center" align="center">
                    <v-col cols="4" sm="2">
                      <v-radio
                        on-icon="mdi-radiobox-marked"
                        off-icon="mdi-radiobox-blank"
                        rounded
                        :hide-details="true"
                        class="mt-0 pt-0"
                        value="AddressBook"
                      >
                      </v-radio>
                    </v-col>
                    <v-col cols="8" sm="10">
                      <h5 class="text-center">Address Book</h5>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-row>
      <v-col v-if="withdrawTo == 'NewWalletAddress'" class="pa-0">
        <v-row class="mt-4" justify="center" justify-md="start">
          <h5>Withdrawal Address</h5>
        </v-row>
        <v-row justify="center" justify-md="start">
          <v-text-field outlined dense></v-text-field>
        </v-row>
        <v-row justify="center" justify-md="start">
          <v-card outlined class="full-width pa-4">
            <v-row>
              <v-switch class="mt-0 pt-0"></v-switch>
              <h4>Save to Address Book</h4>
            </v-row>
            <v-row justify="center" justify-md="start">
              <v-card outlined class="full-width pa-2">
                <h4>Bitmart HODL OTC</h4>
              </v-card>
            </v-row>
          </v-card>
        </v-row>
        <v-row class="mt-4" justify="center" justify-md="start">
          <h5>Withdrawal Amount</h5>
        </v-row>
        <v-row justify="center" justify-md="start">
          <v-text-field class="mt-2" rounded outlined dense></v-text-field>
        </v-row>
        <v-row justify="center">
          <v-card class="pa-4" :style="{ 'background-color': '#F8F9FD' }">
            <v-row justify="center">
              <v-col cols="12" sm="6">
                <v-row justify="center" justify-sm="start">
                  <h5>HODL OTC Fee</h5>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row justify="center" justify-sm="end">
                  <h5>No Fee</h5>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row justify="center" justify-sm="start">
                  <h5>Network Fee</h5>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row justify="center" justify-sm="end">
                  <h5>USDC 4.406024</h5>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row justify="center" justify-sm="start">
                  <h5>We will transfer</h5>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row justify="center" justify-sm="end">
                  <h5>USDC 3,545.406024</h5>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              :style="{ 'border-top': '1px solid lightgrey' }"
            >
              <v-col cols="12" sm="2">
                <v-card class="pa-2" :style="{ 'background-color': '#F8F9FD' }">
                  <v-row justify="center">
                    <h5>0/5</h5>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-row class="full-height" justify="center" align="center">
                  <h6>Free withdrawals left</h6>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row class="full-height" justify="center" align="center">
                  <h6>Limit refresh Date: Nov 1 2022</h6>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row class="mt-6" justify="center">
          <v-col class="pa-0">
            <v-row justify="center" :style="{ 'background-color': '#F8F9FD' }">
              <v-col cols="4" class="pa-0">
                <v-row justify="center" align="center" class="h40">
                  <h5 class="text-center">Estimated</h5>
                </v-row>
              </v-col>
              <v-col cols="4" class="pa-0">
                <v-row justify="center" align="center" class="h40">
                  <h5 class="text-center">Market Value</h5>
                </v-row>
              </v-col>
              <v-col cols="4" class="pa-0">
                <v-row justify="center" align="center" class="h40">
                  <h5 class="text-center">Available credit</h5>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                :style="{ 'background-color': '#F8F9FD' }"
                cols="4"
                class="pa-0 grid-border"
              >
                <v-row justify="center" align="center" class="h40">
                  <h5 class="text-center">Before</h5>
                </v-row>
              </v-col>
              <v-col cols="4" class="pa-0 grid-border">
                <v-row justify="center">
                  <h5></h5>
                </v-row>
              </v-col>
              <v-col cols="4" class="pa-0 grid-border">
                <v-row justify="center">
                  <h5></h5>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                :style="{ 'background-color': '#F8F9FD' }"
                cols="4"
                class="pa-0 grid-border"
              >
                <v-row justify="center" align="center" class="h40">
                  <h5 class="text-center">After withdrawal</h5>
                </v-row>
              </v-col>
              <v-col cols="4" class="pa-0 grid-border">
                <v-row justify="center">
                  <h5 class="text-center"></h5>
                </v-row>
              </v-col>
              <v-col cols="4" class="pa-0 grid-border">
                <v-row justify="center">
                  <h5 class="text-center"></h5>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-card
            :style="{ 'background-color': '#D0E4FD' }"
            class="full-width mt-6"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="blue-text"> mdi-alert </v-icon>
              </v-col>
              <v-col cols="11">
                <h5>
                  BTC Withdrawals below 0.005 BTC may experience delays in
                  processing by miners
                </h5>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row justify="center">
          <v-expansion-panels
            accordion
            flat
            :style="{ 'background-color': '#FDFAE5 !important' }"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                >Important Notes</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <v-row justify="center" justify-sm="end">
          <v-btn
            @click="$emit('close')"
            elevation="0"
            rounded
            class="dark-blue-text ma-2"
          >
            Cancel
          </v-btn>
          <v-btn
            rounded
            class="dark-blue-btn ma-2"
            @click="confirmingWithdrawel = true"
          >
            Withdraw
          </v-btn>
        </v-row>
      </v-col>
      <v-col v-if="withdrawTo == 'AddressBook'" class="pa-0">
        <v-col class="pa-0" v-if="!addresses.length">
          <v-row justify="center">
            <v-col cols="12" md="9" class="pa-0">
              <v-row justify="center" justify-sm="start">
                <h5 class="text-center">No BTC Address in the Address Book</h5>
              </v-row>
              <v-row justify="center" justify-sm="start">
                <h6 class="grey-text mt-2 text-center">
                  You have to add Bitcoin adresses in the Address Book
                </h6>
              </v-row>
              <v-row justify="center" justify-sm="start">
                <v-btn rounded class="dark-blue-btn mt-2">
                  Got to Address Book
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" class="pa-0">
              <v-row justify="center">
                <img src="../assets/images/AddressBook.png" class="my-2" />
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-card
              :style="{ 'background-color': '#D0E4FD' }"
              class="full-width mt-6"
            >
              <v-row>
                <v-col cols="1">
                  <v-icon class="blue-text"> mdi-alert </v-icon>
                </v-col>
                <v-col cols="11">
                  <h5>
                    BTC Withdrawals below 0.005 BTC may experience delays in
                    processing by miners
                  </h5>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-expansion-panels
              accordion
              flat
              :style="{ 'background-color': '#FDFAE5 !important' }"
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Important Notes</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-col>
        <v-col class="pa-0" v-if="addresses.length">
          <v-row justify="center">
            <h5>Select Address</h5>
          </v-row>
          <v-row justify="center">
            <v-select rounded outlined dense :hide-details="true"> </v-select>
          </v-row>
          <v-row class="mt-4" justify="center" justify-md="start">
            <h5>Withdrawal Amount</h5>
          </v-row>
          <v-row justify="center" justify-md="start">
            <v-text-field class="mt-2" rounded outlined dense></v-text-field>
          </v-row>
          <v-row justify="center">
            <v-card class="pa-4" :style="{ 'background-color': '#F8F9FD' }">
              <v-row justify="center">
                <v-col cols="12" sm="6">
                  <v-row justify="center" justify-sm="start">
                    <h5>HODL OTC Fee</h5>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row justify="center" justify-sm="end">
                    <h5>No Fee</h5>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row justify="center" justify-sm="start">
                    <h5>Network Fee</h5>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row justify="center" justify-sm="end">
                    <h5>USDC 4.406024</h5>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row justify="center" justify-sm="start">
                    <h5>We will transfer</h5>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row justify="center" justify-sm="end">
                    <h5>USDC 3,545.406024</h5>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                justify="center"
                :style="{ 'border-top': '1px solid lightgrey' }"
              >
                <v-col cols="12" sm="2">
                  <v-card
                    class="pa-2"
                    :style="{ 'background-color': '#F8F9FD' }"
                  >
                    <v-row justify="center">
                      <h5>0/5</h5>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-row class="full-height" justify="center" align="center">
                    <h6>Free withdrawals left</h6>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row class="full-height" justify="center" align="center">
                    <h6>Limit refresh Date: Nov 1 2022</h6>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="mt-6" justify="center">
            <v-col class="pa-0">
              <v-row
                justify="center"
                :style="{ 'background-color': '#F8F9FD' }"
              >
                <v-col cols="4" class="pa-0">
                  <v-row justify="center" align="center" class="h40">
                    <h5 class="text-center">Estimated</h5>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pa-0">
                  <v-row justify="center" align="center" class="h40">
                    <h5 class="text-center">Market Value</h5>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pa-0">
                  <v-row justify="center" align="center" class="h40">
                    <h5 class="text-center">Available credit</h5>
                  </v-row>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  :style="{ 'background-color': '#F8F9FD' }"
                  cols="4"
                  class="pa-0 grid-border"
                >
                  <v-row justify="center" align="center" class="h40">
                    <h5 class="text-center">Before</h5>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pa-0 grid-border">
                  <v-row justify="center">
                    <h5></h5>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pa-0 grid-border">
                  <v-row justify="center">
                    <h5></h5>
                  </v-row>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  :style="{ 'background-color': '#F8F9FD' }"
                  cols="4"
                  class="pa-0 grid-border"
                >
                  <v-row justify="center" align="center" class="h40">
                    <h5 class="text-center">After withdrawal</h5>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pa-0 grid-border">
                  <v-row justify="center">
                    <h5 class="text-center"></h5>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pa-0 grid-border">
                  <v-row justify="center">
                    <h5 class="text-center"></h5>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-card
              :style="{ 'background-color': '#D0E4FD' }"
              class="full-width mt-6"
            >
              <v-row>
                <v-col cols="1">
                  <v-icon class="blue-text"> mdi-alert </v-icon>
                </v-col>
                <v-col cols="11">
                  <h5>
                    BTC Withdrawals below 0.005 BTC may experience delays in
                    processing by miners
                  </h5>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-expansion-panels
              accordion
              flat
              :style="{ 'background-color': '#FDFAE5 !important' }"
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Important Notes</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row justify="center" justify-sm="end" class="mt-4">
            <v-btn
              @click="$emit('close')"
              elevation="0"
              rounded
              class="dark-blue-text ma-2"
            >
              Cancel
            </v-btn>
            <v-btn
              rounded
              class="dark-blue-btn ma-2"
              @click="confirmingWithdrawel = true"
            >
              Withdraw
            </v-btn>
          </v-row>
        </v-col>
      </v-col>
    </div>
    <div
      v-if="confirmingWithdrawel"
      class="white-card"
      :style="{ 'overflow-y': 'hidden', width: '500px', padding: '5%' }"
    >
      <v-row justify="center">
        <img src="../assets/images/WithdrawelInbox.png" />
      </v-row>
      <v-row justify="center">
        <h4 class="text-center mt-6">Confirm Withdrawal</h4>
      </v-row>
      <v-row justify="center">
        <h5 class="text-center mt-6">
          For security reason's, we have sent a confirmation request to your
          registered email address. Please click the link in the email to
          confirm withdrawal. If you have not received it, please check your
          SPAM/ Junk and other folders.
        </h5>
      </v-row>
      <v-row justify="center">
        <v-btn rounded class="dark-blue-btn mt-8" @click="$emit('close')">
          Close
        </v-btn>
      </v-row>
    </div>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      withdrawTo: "NewWalletAddress",
      addresses: [""],
      confirmingWithdrawel: false,
    };
  },
  methods: {},
};
</script>

<style>
/* Center radio buttons */
.withdraw .v-input--selection-controls .v-radio {
  margin-bottom: 0 !important;
}
</style>

<style scoped>
.grid-border {
  border: 1px solid #bebebe;
}

.h40 {
  height: 40px;
}
</style>