<template>
  <div
    class="navbar"
    @mousemove="handleMouseMovement"
    @mouseleave="handleMouseLeave"
  >
    <v-app-bar app dark class="nav app-bar pa-2" auto-grow extended>
      <v-row justify="center" justify-sm="start">
        <v-col cols="2" class="hidden-sm-and-up"> </v-col>
        <v-col cols="8" sm="4" md="3" lg="2" class="pa-0">
          <v-row
            :style="{ 'min-width': '160px' }"
            justify="center"
            align="center"
            class="full-height"
          >
            <img class="app-bar-logo" src="@/assets/images/Logo.png" />
          </v-row>
        </v-col>
        <v-col sm="5" md="5" lg="7" class="pa-0 hidden-xs-only">
          <div
            class="nav-links flex full-height"
            :style="{ 'justify-content': 'start' }"
          >
            <router-link
              class="silver-text mx-4"
              to="/account"
              @click="handleNavClick"
              >DASHBOARD</router-link
            >
            <router-link
              class="silver-text mx-4"
              to="/transactionhistory"
              @click="handleNavClick"
              >TRANSACTIONS</router-link
            >
            <router-link
              class="silver-text mx-4"
              to="/exchange"
              @click="handleNavClick"
              >EXCHANGE</router-link
            >
            <router-link
              class="silver-text mx-4"
              to="/markets"
              @click="handleNavClick"
              >MARKETS</router-link
            >
            <router-link
              class="silver-text mx-4"
              to="/viewcurrency?type=Wallet"
              @click="handleNavClick"
              >WALLET</router-link
            >
            <v-btn
              v-if="accountData && accountData.state !== 'Approved'"
              class="user-state-display pointer mt-1"
              rounded
              :style="{ margin: '0px 70px', 'font-size': '16px' }"
              @click="
                    $router.push({
                      path: '/options?section=IdentityVerification',
                    })
                  "
            >
              {{ accountData.state }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="2" class="hidden-sm-and-up">
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </v-col>
        <v-col class="pa-0" cols="12" sm="3" md="4" lg="3">
          <v-row
            class="full-height"
            align="center"
            justify="center"
            justify-sm="end"
          >
            <v-icon small class="white-text mx-2" @click="openPDF">
              mdi-help-circle-outline
            </v-icon>
        
            <v-menu offset-y class="options-menu no-shadow">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="account-btn"
                  outlined
                  rounded
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small> mdi-account </v-icon>
                  <v-icon x-small> mdi-arrow-down </v-icon>
                </v-btn>
              </template>
              <v-card rounded class="options-card top pt-4">
                <h4 class="text-center">
                  WELCOME
                  {{
                    accountData
                      ? accountData.name + " " + accountData.lastName
                      : "Loading..."
                  }}
                </h4>
                <v-row
                  @click="
                    $router.push({
                      path: '/options',
                      query: { section: 'AccountInfo' },
                    })
                  "
                  justify="center"
                  class="mt- pt-8 py-2 underline pointer"
                >
                  <v-col cols="3" class="py-0">
                    <v-icon> mdi-account </v-icon>
                  </v-col>
                  <v-col cols="9" class="py-0">
                    <h4 class="pointer">My Profile</h4>
                  </v-col>
                </v-row>
                <v-row
                  @click="
                    $router.push({
                      path: '/security',
                    })
                  "
                  justify="center"
                  class="mt-2 py-2 underline pointer"
                >
                  <v-col cols="3" class="py-0">
                    <v-icon> mdi-lock </v-icon>
                  </v-col>
                  <v-col cols="9" class="py-0">
                    <h4>Security</h4>
                  </v-col>
                </v-row>
            
                <v-row
                  justify="center"
                  class="mt-2 pb-8 py-2 underline pointer"
                  @click="$router.push('/')"
                >
                  <v-col cols="3" class="py-0">
                    <v-icon> mdi-logout </v-icon>
                  </v-col>
                  <v-col cols="9" class="py-0">
                    <h4 @click="logout()">LOGOUT</h4>
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.width < 600"
      class="nav nav-bar pa-4"
      v-model="drawer"
      app
    >
      <v-col class="full-height" cols="12">
        <v-row justify="center">
          <img class="app-bar-logo" src="@/assets/images/Logo.png" />
        </v-row>
        <v-row class="mt-12 my-2">
          <v-icon small class="white-text"> mdi-account </v-icon>
          <router-link
            class="silver-text mx-4"
            to="/account"
            @click="handleNavClick"
            >DASHBOARD</router-link
          >
        </v-row>
        <v-row class="my-2">
          <v-icon small class="white-text"> mdi-cash-multiple </v-icon>
          <router-link
            class="silver-text mx-4"
            to="/transactionhistory"
            @click="handleNavClick"
            >TRANSACTIONS</router-link
          >
        </v-row>
        <v-row class="my-2">
          <v-icon small class="white-text"> mdi-swap-horizontal </v-icon>
          <router-link
            class="silver-text mx-4"
            to="/exchange"
            @click="handleNavClick"
            >EXCHANGE</router-link
          >
        </v-row>
        <v-row class="my-2">
          <v-icon small class="white-text"> mdi-shopping </v-icon>
          <router-link
            class="silver-text mx-4"
            to="/markets"
            @click="handleNavClick"
            >MARKETS</router-link
          >
        </v-row>
        <v-row class="my-2">
          <v-icon small class="white-text"> mdi-wallet </v-icon>
          <router-link
            class="silver-text mx-4"
            to="/viewcurrency?type=Wallet"
            @click="handleNavClick"
            >WALLET</router-link
          >
        </v-row>
        <v-btn
          v-if="accountData && accountData.state !== 'Approved'"
          class="user-state-display pointer mt-1"
          rounded
          @click="verifyAccount"
          :style="{ 'font-size': '16px', color: 'black' }"
          disabled
        >
          {{ accountData.state }}
        </v-btn>
      </v-col>
    </v-navigation-drawer>
    <v-row justify="center">
      <v-overlay :value="loading" style="z-index: 999;">
        <v-progress-circular
          :size="100"
          color="##008080"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-row>
  </div>
</template>

<script>
  import { auth } from "../firebaseConfig.js";
  import { onAuthStateChanged } from "firebase/auth";
  import {
    getFirestore,
    query,
    where,
    collection,
    getDocs,
  } from "firebase/firestore";

  const db = getFirestore();
  export default {
    name: "NavBar",
    data() {
      return {
        drawer: null,
        loading: false,
        accountData: null,
        navClickTimer: null,
        isNavClicked: false,
        lastInteractionTime: null,
        mouseMoved: false,
      };
    },
    methods: {
      async logout() {
        try {
          this.loading = true;
          await auth.signOut();
          this.loading = false;
          localStorage.clear();
          this.$notify({
            group: "alert",
            title: "Success",
            text: "Logged out successfully!",
            position: "bottom center",
            type: "success",
          });
          console.log("User logged out successfully.");
          this.$router.push("/");
        } catch (error) {
          this.$notify({
            group: "alert",
            title: "Error",
            text: "Failed to logout, please try again!",
            position: "bottom center",
            type: "error",
          });
          console.error("Failed to log out:", error);
        }
      },
      async fetchAccountData() {
        const userEmail = auth.currentUser.email;
        let accountCollections = ["individuals", "companies", "trusts"];
        let accountDataFound = false;

        for (let collectionName of accountCollections) {
          if (accountDataFound) break;
          let accountCollectionRef = collection(db, collectionName);
          let q = query(accountCollectionRef, where("email", "==", userEmail));
          let querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            this.accountData = querySnapshot.docs[0].data();
            accountDataFound = true;
          }
        }
      },
      verifyAccount() {
        this.$router
          .push("/options?section=IdentityVerification")
          .catch((err) => {});
        console.log("Routing to the account verification page...");
      },
      handleNavClick() {
        console.log("Nav item clicked.");
        this.isNavClicked = true;
        this.lastInteractionTime = new Date();
        this.startTimer();
      },
      handleMouseMovement() {
        if (!this.mouseMoved) {
          this.resetTimer();
          this.mouseMoved = true;
        }
      },
      handleMouseLeave() {
        this.mouseMoved = false;
        this.startTimer();
      },
      resetTimer() {
        if (this.navClickTimer) {
          clearTimeout(this.navClickTimer);
        }
        this.lastInteractionTime = new Date();
      },
      startTimer() {
        this.resetTimer();
        this.navClickTimer = setTimeout(() => {
          this.breakFunctionality();
        }, 4800000); // 80 minutes
      },
      breakFunctionality() {
        const currentTime = new Date();
        const timeSinceLastInteraction = Math.floor(
          (currentTime - this.lastInteractionTime) / 1000
        );
        console.log(
          `No interaction with the nav bar for ${timeSinceLastInteraction} seconds.`
        );
        this.isNavClicked = false;
        this.logout();
      },
      openPDF() {
        window.open("/HODLOTCFEES.pdf", "_blank");
      },
    },
    computed: {
      userStateDisplay() {
        if (this.accountData && this.accountData.state) {
          return this.accountData.state !== "Approved"
            ? this.accountData.state
            : "";
        }
        return "Loading...";
      },
    },
    created() {
      this.fetchAccountData(); // Corrected method call
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // Logic when user is logged in
          this.fetchAccountData(); // Fetch account data for logged-in user
        } else {
          // Reset data if no user is logged in
          this.accountData = null; // Corrected reference to data property
        }
      });
      this.startTimer(); // Start the timer on component creation
    },
    beforeDestroy() {
      this.resetTimer();
    },
  };
</script>

<style>
  .v-menu__content--fixed {
    border-radius: 25px !important;
  }
</style>

<style scoped>
  .options-card {
    width: 250px;
    border-radius: 25px !important;
    box-shadow: none !important;
  }

  .user-state-display {
    width: 240px;
    max-width: 80%;
    border: 2px solid #be1b74 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    color: #be1b74 !important;
    font-size: 11px !important;
  }
</style>
