<template>
  <v-app style="background-color:#f8f8f8;">
    <Navbar v-if="!routesToHide.includes($router.currentRoute.path)" />

    <v-main>
      <v-row v-if="!routesToHide2.includes($router.currentRoute.path)">
        <iframe srcdoc="<!DOCTYPE html>
          <html lang='en'>
          <head>
            <meta charset='utf-8'>
            <meta name='viewport' content='width=device-width,initial-scale=1'>
            <script defer src='https://www.livecoinwatch.com/static/lcw-widget.js'></script>
          </head>
          <body>
            <div class='livecoinwatch-widget-5' lcw-base='ZAR' lcw-color-tx='#0693e3' lcw-marquee-1='coins' lcw-marquee-2='movers' lcw-marquee-items='100'></div>
          </body>
          </html>" style="width: 100vw; height: 65px; border: 0; margin-left: calc(-50vw + 55%);margin-right: calc(-50vw + 55%);" frameborder="0"
          scrolling="no">
        </iframe>
      </v-row>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",

  data: () => ({
    routesToHide: [
      "/",
      "/register",
      "/forgotpassword",
      "/verifyaccount",
      "/twofamodal",
      "/maintenance"
    ],
    routesToHide2: [
      "/",
      "/register",
      "/forgotpassword",
      "/verifyaccount",
      "/twofamodal",
      "/viewcurrency",
      "/Usermanagement",
      "/maintenance"
    ],
  }),

  created() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    window.addEventListener('load', this.loadHandler);
    window.addEventListener('storage', this.storageHandler);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    window.removeEventListener('load', this.loadHandler);
    window.removeEventListener('storage', this.storageHandler);
  },

  methods: {
    beforeUnloadHandler() {
      // Set a flag in sessionStorage to indicate page unloading
      sessionStorage.setItem('isPageRefreshed', 'true');
    },

    loadHandler() {
      const isPageRefreshed = sessionStorage.getItem('isPageRefreshed');
      if (isPageRefreshed) {
        // Page was refreshed, remove the flag
        sessionStorage.removeItem('isPageRefreshed');
      } else {
        // Page was closed and reopened, clear site data
        this.clearSiteData();
      }
    },

    storageHandler(event) {
      if (event.key === 'isPageRefreshed' && !event.newValue) {
        // If the session storage key is removed, indicating session end
        this.clearSiteData();
      }
    },

    clearSiteData() {
      // Clear cookies
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }

      // Clear localStorage
      localStorage.clear();

      // Clear sessionStorage
      sessionStorage.clear();

      // Clear IndexedDB
      indexedDB.databases().then(databases => {
        databases.forEach(db => {
          indexedDB.deleteDatabase(db.name);
        });
      });

      // Clear cache storage
      caches.keys().then(names => {
        for (let name of names) {
          caches.delete(name);
        }
      });
    }
  },

  components: {
    Navbar,
  },
};
</script>


<style>
@import "@/assets/css/Global.css";

.row {
  margin: 0 !important;
}

.v-toolbar__content {
  height: 100% !important;
}

.nav a {
  text-decoration: none !important;
  font-size: 80%;
  font-weight: 300px !important;
}

.nav-links {
  overflow-x: scroll !important;
}

.app-bar {
  background-image: url("./assets/images/App-Bar-Background.png");
  min-height: "100px";
  background-size: cover;
}

.nav-bar {
  background-image: url("./assets/images/Login.png");
  background-size: cover;
}

.app-bar-logo {
  width: 40vw;
  max-width: 80%;
  max-height: 80%;
}

.account-btn {
  height: 20px !important;
  width: 15px !important;
  min-width: 15px !important;
  padding: 0px 20px !important;
}

.v-toolbar__content {
  padding: 0 !important;
}

.text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}



th:nth-child(1) {
  border-radius: 4px 0px 0px 0px;
}

th:last-child {
  border-radius: 0px 4px 0px 0px;
}

.v-data-table-header-mobile th {
  border-radius: 13px !important;
}

.v-data-table tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

.v-data-table {
  color: white;
  box-shadow: 0px 0px 0px 1px lightslategray;
}

.data-table {
  border-radius: 12px;
  box-shadow: 4px 4px 16px lightslategray;
  width: 100%;
}

.stack-vertical td {
  word-break: break-all;
  white-space: normal;
}
</style>
