<template>
  <div :style="{ 'min-height': '100px' }" class="full-height">
    <v-card rounded outlined class="full-width full-height">
      <v-row class="full-height" justify="center" align="center">
        <v-col cols="12" sm="2" class="pa-0">
          <v-row justify="center">
            <v-icon class="dark-blue-text my-4"> mdi-{{ icon }} </v-icon>
          </v-row>
        </v-col>
        <v-col cols="12" sm="7">
          <h4>{{ heading }}</h4>
          <h5>{{ description }}</h5>
        </v-col>
        <v-col cols="12" sm="3" class="pa-0 full-height">
          <div
            v-if="comingSoon"
            class="full-height pa-4"
            :style="{ 'background-color': '#00155F' }"
            :class="{ 'h-50': $vuetify.breakpoint.width < 600 }"
          >
            <v-row justify="center" align="center" class="full-height">
              <h6 class="white-text text-center">Coming Soon</h6>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  props: {
    comingSoon: false,
    icon: "",
    heading: "",
    description: "",
  },
};
</script>

<style scoped>
.h-50 {
  height: 50px !important;
}
</style>