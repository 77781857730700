<template>
  <v-container class="full-width" :style="{ padding: '2% 10%' }">
    <v-row class="Auth" justify="center">
      <v-col>
        <v-row class="pointer" @click="$router.back()">
          <v-icon class="blue-text"> mdi-arrow-left </v-icon>
          <h4>Go Back</h4>
        </v-row>
        <v-row justify="center">
          <v-card
            rounded
            class="white-card full-width mt-4 pb-8"
            :style="{ padding: '2% 10%' }">
            <v-row justify="center" justify-sm="start">
              <v-col cols="12" sm="2">
                <v-row justify="center" justify-sm="start">
                  <v-icon
                    class="dark-blue-text"
                    :style="{ 'font-size': '50px' }">
                    mdi-cellphone-link
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row justify="start" justify-sm="start">
                  <h4>Two-Factor Authentication</h4>
                </v-row>
                <v-row justify="start" justify-sm="start">
                  <h5 class="grey-text">
                    User for withdrawals and changes to security settings.
                  </h5>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-card outlined rounded :style="{ padding: '10%' }">
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-row justify="center">
                      <v-icon
                        class="blue-text"
                        :style="{ 'font-size': '50px' }">
                        mdi-at
                      </v-icon>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-row justify="center" justify-sm="start">
                      <h4>Two-Factor Authentication</h4>
                    </v-row>
                    <v-row justify="center" justify-sm="start">
                      <h5>
                        User for withdrawals and changes to security settings.
                      </h5>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    color="pink-btn-enable"
                    rounded
                    dark
                    @click="enable2FA"
                    class="ma-2">
                    Enable 2FA
                  </v-btn>

                  <v-btn color="primary" @click="disable2FA" rounded
                    dark class="ma-2">
                    Disable 2FA
                  </v-btn>
                </v-row>
              </v-card>
            </v-row>
          </v-card>
        </v-row>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
  import { deleteField } from "firebase/firestore";
  import { auth } from "../firebaseConfig.js";
  import { onAuthStateChanged } from "firebase/auth";
  import {
    getFirestore,
    query,
    where,
    collection,
    getDocs,
    updateDoc,
    doc,
  } from "firebase/firestore";

  const db = getFirestore();

  export default {
    data() {
      return {
        accountData: null,
        is2FAEnabled: false,
      };
    },
    methods: {
      async fetchAccountData() {
        const userEmail = auth.currentUser.email;
        let accountCollections = ["individuals", "companies", "trusts"];
        let accountDataFound = false;

        for (let collectionName of accountCollections) {
          if (accountDataFound) break;
          const accountCollectionRef = collection(db, collectionName);
          const q = query(
            accountCollectionRef,
            where("email", "==", userEmail)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            this.accountData = querySnapshot.docs[0].data();
            this.accountDocId = querySnapshot.docs[0].id; // Save the document ID for later updates
            accountDataFound = true;
          }
        }
      },

      async enable2FA() {
        const userEmail = auth.currentUser?.email;
      
        await this.update2FAState(true, userEmail);
      },

      async disable2FA() {
        const userEmail = auth.currentUser?.email;
  
        await this.update2FAState(false, userEmail);
      },

      async update2FAState(enable, userEmail) {
        const accountCollections = ["individuals", "companies", "trusts"];
        let updated = false;

        for (const collectionName of accountCollections) {
          if (updated) break;
          const accountCollectionRef = collection(db, collectionName);
          const q = query(
            accountCollectionRef,
            where("email", "==", userEmail)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userDocRef = userDoc.ref;
            const currentData = userDoc.data();

            // Proceed only if enabling or if disabling and is2FAEnabled is currently true
            if (enable || (!enable && currentData.is2FAEnabled === true)) {
              const update = enable
                ? { is2FAEnabled: true }
                : { is2FAEnabled: deleteField() };
              await updateDoc(userDocRef, update);

              this.accountData.is2FAEnabled = enable;
              updated = true;
              alert(`2FA is now ${enable ? "enabled" : "disabled"}.`);
            }
          }
        }

        if (!updated) {
          console.error("Failed to update 2FA status.");
        }
      },
    },
    created() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.fetchAccountData();
        } else {
          this.accountData = null; // Reset accountData if not logged in
        }
      });
    },
  };
</script>

<style>
  .Auth .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  .pink-btn-enable {
    background-color: #BE1B74 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
</style>
