<template>
  <v-container class="options full-width" :style="{ padding: '2%' }">
    <transition name="slide">
      <v-row justify="center">
        <v-col cols="12" sm="12" md="2">
          <h4
            @click="goToPage('AccountInfo')"
            :class="{'nav-item-active': $route.query.section == 'AccountInfo'}"
            class="pa-2 nav-item"
          >
            Account Info
          </h4>
          <h4
            @click="goToPage('IdentityVerification')"
            :class="{
              'nav-item-active': $route.query.section == 'IdentityVerification',
            }"
            class="pa-2 nav-item"
          >
            Identity Verification
          </h4>
          <h4
            :class="{
              'nav-item-active': $route.query.section == 'LoyaltyLevels',
            }"
            @click="goToPage('LoyaltyLevels')"
            class="pa-2 nav-item"
          >
            Loyalty Levels
          </h4>
          <h4
            @click="goToPage('ReferaFriend')"
            :class="{
              'nav-item-active': $route.query.section == 'ReferaFriend',
            }"
            class="pa-2 nav-item"
          >
            Refer a friend
          </h4>
          <h4
            :class="{
              'nav-item-active': $route.query.section == 'Affiliate',
            }"
            @click="goToPage('Affiliate')"
            class="pa-2 nav-item"
          >
            Affiliate
          </h4>
        
        </v-col>
        <v-col cols="12" sm="12" md="10">
          <AccountInfo v-if="$route.query.section == 'AccountInfo'" />
          <IdentityVerification
            v-if="$route.query.section == 'IdentityVerification'"
          />
          <LoyaltyLevels v-if="$route.query.section == 'LoyaltyLevels'" />
          <ReferAFriend v-if="$route.query.section == 'ReferaFriend'" />
          <Affiliate v-if="$route.query.section == 'Affiliate'" />
          <Settings v-if="$route.query.section == 'Settings'" />
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import AccountInfo from "../views/AccountInfo";
import IdentityVerification from "../views/IdentityVerification";
import LoyaltyLevels from "../views/LoyaltyLevels";
import ReferAFriend from "../views/ReferaFriend";
import Affiliate from "../views/Affiliate";
import Settings from "../views/Settings";

export default {
  data() {
    return {};
  },
  methods: {
    goToPage(page) {
      if (this.$route.query.section != page) {
        this.$router.push({ query: { section: page } });
      }
    },
  },
  components: {
    AccountInfo,
    IdentityVerification,
    LoyaltyLevels,
    ReferAFriend,
    Affiliate,
    Settings,
  },
};
</script>

<style scoped>
.nav-item {
  border-left: 3px solid #e3e3e3;
  cursor: pointer;
}

.nav-item-active {
  border-left: 1px solid #0099e4;
  box-shadow: 0px 3px 6px #00000029;
}
</style>