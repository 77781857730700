<template>
  <v-container>
    <transition name="slide">
      <v-row v-if="showForm" justify="center">
        <v-card class="full-width white-card py-6 mt-6" :style="{ padding: '8%' }">
          <v-row>
            <v-icon :style="{ 'font-size': '80px' }" class="blue-text">
              mdi-shield-lock
            </v-icon>
            <v-col>
              <h4>Security</h4>
              <h5 class="mt-2">
                Manage your Account Activity, Two-Factor Authentication (2FA)
                and Withdrawal Whitelisting.
              </h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-card outlined class="pa-4 security-card">
                <v-row justify="center">
                  <v-col cols="9">
                    <h4>Login Information</h4>
                  </v-col>
                  <v-col cols="3">
                    <v-icon large class="dark-blue-text">
                      mdi-shield-lock
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <h5 class="mx-3 mb-12">
                    Change your password, see your account e-mail details, and
                    monitor the logins to your account.
                  </h5>
                </v-row>
                <v-btn
                  :style="{ bottom: '20px' }"
                  rounded
                  class="dark-blue-btn pos-abs mt-4"
                  @click="$router.push('/logininformation')"
                >
                  Login Information
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card outlined class="pa-4 security-card">
                <v-row justify="center">
                  <v-col cols="9">
                    <h4>Two-Factor Authentication</h4>
                  </v-col>
                  <v-col cols="3">
                    <v-icon large class="dark-blue-text"> mdi-devices </v-icon>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <h5 class="mx-3 mb-12">
                    Used for withdrawals and changes to security settings.
                  </h5>
                </v-row>
                <v-btn
                  :style="{ bottom: '20px' }"
                  rounded
                  class="dark-blue-btn pos-abs mt-4"
                  @click="$router.push('/2factorauth')"
                >
                  2FA Settings
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card outlined class="pa-4 security-card">
                <v-row justify="center">
                  <v-col cols="9">
                    <h4>Address Book</h4>
                  </v-col>
                  <v-col cols="3">
                    <v-icon large class="dark-blue-text">
                      mdi-shield-lock
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <h5 class="mx-3 mb-12">
                    Manage crypto addresses for easier and more secure
                    withdrawals.
                  </h5>
                </v-row>
                <v-btn
                  :style="{ bottom: '20px' }"
                  rounded
                  class="dark-blue-btn pos-abs mt-4"
                  @click="$router.push('/addressbook')"
                >
                  Address Book
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card outlined class="pa-4 security-card">
                <v-row justify="center">
                  <v-col cols="9">
                    <h4>Close Account</h4>
                  </v-col>
                  <v-col cols="3">
                    <v-icon large class="dark-blue-text">
                      mdi-account-remove
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <h5 class="mx-3 mb-12">
                    Please make sure your account balance is $0.00 before you
                    begin.
                  </h5>
                </v-row>
                <v-row class="fill-height" align="end">
                  <v-btn
                    :style="{ bottom: '20px' }"
                    rounded
                    class="dark-blue-btn pos-abs mt-4"
                    @click="openDialog"
                  >
                    Close Account
                  </v-btn>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </transition>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to close your account?
        </v-card-title>
        <v-card-text>
          <p>This action cannot be undone.</p>
          <p>Do you really want to close your account?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" dense rounded dark @click="closeDialog">NO</v-btn>
          <v-btn color="primary" dense rounded dark @click="reAuthenticate">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reAuthDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Please re-authenticate
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="email" outlined rounded dense label="Email" required></v-text-field>
          <v-text-field v-model="password" outlined rounded dense  label="Password" type="password" required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" dense rounded dark @click="closeReAuthDialog">CANCEL</v-btn>
          <v-btn color="primary" dense rounded dark @click="confirmReAuth">SUBMIT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, auth } from "../firebaseConfig"; // Ensure the correct path to your firebaseConfig.js
import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      showForm: false,
      dialog: false, // Controls the visibility of the dialog
      reAuthDialog: false,
      email: '',
      password: '',
    };
  },
  created() {
    this.showForm = true;
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    openReAuthDialog() {
      this.reAuthDialog = true;
    },
    closeReAuthDialog() {
      this.reAuthDialog = false;
    },
    reAuthenticate() {
      this.closeDialog();
      this.openReAuthDialog();
    },
    async confirmReAuth() {
      try {
        const user = auth.currentUser;
        if (!user) {
          alert("No user is currently logged in.");
          return;
        }

        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.closeReAuthDialog();
        this.confirmCloseAccount();

      } catch (error) {
        console.error("Re-authentication failed:", error);
        alert("Re-authentication failed. Please try again.");
      }
    },
    async confirmCloseAccount() {
      try {
        const user = auth.currentUser;
        if (!user) {
          alert("No user is currently logged in.");
          return;
        }
        const uid = user.uid;

        // Make a copy of the user's data
        const companiesRef = doc(db, "companies", uid);
        const individualsRef = doc(db, "individuals", uid);
        const trustsRef = doc(db, "trusts", uid);

        const companiesSnap = await getDoc(companiesRef);
        const individualsSnap = await getDoc(individualsRef);
        const trustsSnap = await getDoc(trustsRef);

        const backupData = {
          companies: companiesSnap.exists() ? companiesSnap.data() : null,
          individuals: individualsSnap.exists() ? individualsSnap.data() : null,
          trusts: trustsSnap.exists() ? trustsSnap.data() : null,
        };

        await setDoc(doc(db, "deletedAccounts", uid), backupData);

        // Delete the user's data
        if (companiesSnap.exists()) await deleteDoc(companiesRef);
        if (individualsSnap.exists()) await deleteDoc(individualsRef);
        if (trustsSnap.exists()) await deleteDoc(trustsRef);

        // Delete the user's authentication data
        await user.delete();

        alert("Your account has been closed.");
        this.dialog = false;
        // Add your redirection logic here, if necessary
      } catch (error) {
        console.error("Error closing account:", error);
        alert("Failed to close account. Please try again.");
      }
    },
  },
};
</script>

<style scoped>
.security-card {
  min-height: 250px;
}

/* Animations: */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s;
}
.slide-enter,
.slide-leave-to /* .slide-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
</style>
