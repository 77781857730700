<template>
  <v-container class="transactionhistory pa-8 full-width">
    <v-row justify="center" v-if="loading" class="loader-container">
      <v-col cols="12" class="text-center">
        <v-icon class="mdi mdi-bitcoin rotating-loader" color="primary" size="64"></v-icon>
        <h3>LOADING...</h3>
      </v-col>
    </v-row>
    <transition name="slide">
      <v-row v-if="showForm && !loading" justify="center">
        <v-col cols="12" md="3">
          <div class="sticky-container">
            <div class="white-card pa-6 mb-4">
              <h3>Export CSV</h3>
              <v-btn rounded class="dark-blue-btn full-width mt-4" @click="exportToCSV">
                Export
              </v-btn>
            </div>
            <div class="white-card pa-6">
              <h3>Filter</h3>
              <h5 class="grey-text mt-4">Date Range</h5>
              <v-row justify="center" class="mt-2">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                  transition="scale-transition" outline>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dates" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" outlined
                      class="datePicker" rounded dense label="Start Date - End Date"></v-text-field>
                  </template>
                  <v-date-picker class="controls" color="blue" range v-model="dates" no-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="resetDates">Reset</v-btn>
                    <v-btn text @click="menu = false">Cancel</v-btn>
                    <v-btn text @click="$refs.menu.save(dates)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-row>
              <h5 class="grey-text">Transaction Type</h5>
              <v-row justify="center" class="mt-2">
                <v-select class="controls" label="Select Type"
                  :items="['Deposit', 'Withdrawal', 'Zar to Crypto', 'Crypto to Zar']" v-model="selectedType" dense
                  outlined rounded></v-select>
              </v-row>
              <h5 class="grey-text">Asset</h5>
              <v-row justify="center" class="mt-2">
                <v-select class="controls" label="Select Asset" :items="assets" v-model="selectedAsset" dense outlined
                  rounded></v-select>
              </v-row>
              <v-btn rounded color="primary" @click="filterTransactions" class="full-width mt-4">
                Apply Filters
              </v-btn>
              <v-btn rounded color="pink-btn" @click="clearFilters" class="full-width mt-2">
                Clear Filters
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <div class="white-card pa-6 full-width">
            <v-row justify="center">
              <v-col cols="12" md="6" class="pa-0">
                <v-row class="full-height" justify="center" justify-md="start" align="center">
                  <h3>Transaction History</h3>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" class="pa-0">
                <v-row justify="center" justify-md="end" align="center">
                </v-row>
              </v-col>
              <v-card outlined class="full-width transaction-card mt-4" hover
                v-for="transaction in displayedTransactions"
                :key="transaction.id + '-' + transaction.type + '-' + transaction.date">
                <v-row class="full-height" justify="start" align="center">
                  <v-col cols="12" md="3">
                    <v-row>
                      <v-row>
                        <img src="../assets/images/Transaction-Logo.svg" />
                      </v-row>
                      <v-row>
                        <p>{{ transaction.type }} Transaction</p>
                      </v-row>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    <p>Date: {{ transaction.date }}</p>
                    <p>Currency: {{ transaction.currency }}</p>
                  </v-col>
                  <v-col cols="12" md="3">
                    <p>Amount: {{ transaction.amount }}</p>
                    <p>Fee: {{ transaction.fee }}</p>
                  </v-col>
                  <v-col cols="12" md="3">
                    <p>Status: {{ transaction.state }}</p>
                    <p>Note: {{ transaction.note || "N/A" }}</p>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </transition>
    <v-btn fab dark color="primary" class="scroll-to-top" @click="scrollToTop">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  data() {
    return {
      dates: [],
      menu: null,
      transactions: [],
      showForm: false,
      clientSN: null,
      WithdrawalHistory: [],
      displayedTransactions: [],
      depositHistory: [],
      tradeHistory: [],
      assets: [],
      totalBalance: 0,
      sortKey: "Date",
      selectedType: null,
      selectedAsset: null,
      loading: true,
      abortController: null, // Add AbortController instance
    };
  },
  mounted() {
    this.showForm = true;
    this.abortController = new AbortController(); // Initialize AbortController
    this.fetchUserData();
    this.refreshData();
  },
  beforeDestroy() {
    if (this.abortController) {
      this.abortController.abort(); // Abort any ongoing requests
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.loading) {
      alert("Data is still loading, please wait until it's complete.");
      next(false); // Prevent navigation
    } else {
      next(); // Allow navigation
    }
  },
  watch: {
    selectedType() {
      this.filterTransactions();
    },
    selectedAsset() {
      this.filterTransactions();
    },
    dates() {
      this.filterTransactions();
    },
    sortKey() {
      this.sortTransactions();
    },
  },
  methods: {
    async fetchUserData() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const db = getFirestore();
          const collections = ["individuals", "companies", "trusts"];
          for (let collection of collections) {
            try {
              const docRef = doc(db, collection, uid);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists() && docSnap.data().clientSN) {
                this.clientSN = docSnap.data().clientSN;
                await this.refreshData();
              }
            } catch (error) {
              console.error("Error fetching clientSN:", error);
            }
          }
        }
      });
    },
    async fetchWithdrawals() {
      try {
        const withdrawalResponse = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetWithdrawalClients?sn=${this.clientSN}`,
          { signal: this.abortController.signal }
        );

        if (!withdrawalResponse.ok) {
          console.error(`Failed to fetch withdrawals: ${withdrawalResponse.status} ${withdrawalResponse.statusText}`);
          return;
        }

        const withdrawals = await withdrawalResponse.json();
        const filteredWithdrawals = withdrawals.filter(
          (withdrawal) => withdrawal.sn === this.clientSN
        );

        this.processData(filteredWithdrawals, "Withdrawal");
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error("Failed to fetch withdrawals:", error);
        }
      }
    },
    async fetchDeposits() {
      try {
        const depositResponse = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetDeposits?sn=${this.clientSN}`,
          { signal: this.abortController.signal } // Pass signal to fetch
        );
        const deposits = await depositResponse.json();
        this.processData(deposits, "Deposit");
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error("Failed to fetch deposits:", error);
        }
      }
    },
    async fetchTradeHistory() {
      try {
        const tradeHistoryResponse = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/TradeHistorybysn?sn=${this.clientSN}`,
          { signal: this.abortController.signal } // Pass signal to fetch
        );
        const responseJson = await tradeHistoryResponse.json();
        this.processData(responseJson.otc_trades, "Trade");
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error("Failed to fetch trade history:", error);
        }
      }
    },
    processData(data, transactionType) {
      if (!Array.isArray(data)) {
        console.error("Data is not an array:", data);
        return;
      }
      const currencies = new Set(this.assets);
      const processedData = data.map((item) => {
        currencies.add(transactionType === "Trade" ? `${item.from_currency.toUpperCase()}-${item.to_currency.toUpperCase()}` : item.currency.toUpperCase());

        return {
          id: item.id,
          type: transactionType,
          date: this.formatDate(item.created_at),
          currency: transactionType === "Trade" ? `${item.from_currency.toUpperCase()}-${item.to_currency.toUpperCase()}` : item.currency.toUpperCase(),
          amount: parseFloat(transactionType === "Trade" ? item.from_amount : item.amount).toFixed(2),
          fee: parseFloat(item.broker_fee_amount || item.fee).toFixed(2),
          state: this.capitalizeFirstLetter(item.status || item.state),
          note: item.note || "N/A",
        };
      });

      if (transactionType === "Withdrawal") {
        this.WithdrawalHistory = processedData;
      } else if (transactionType === "Deposit") {
        this.depositHistory = processedData;
      } else if (transactionType === "Trade") {
        this.tradeHistory = processedData;
      }

      this.assets = Array.from(currencies);
      this.displayedTransactions = [...this.WithdrawalHistory, ...this.depositHistory, ...this.tradeHistory];
      this.sortTransactions();
    },
    async refreshData() {
      this.loading = true;
      await Promise.all([this.fetchWithdrawals(), this.fetchDeposits(), this.fetchTradeHistory()]);
      this.filterTransactions();
      this.loading = false;
    },
    filterTransactions() {
      let filtered = [...this.WithdrawalHistory, ...this.depositHistory, ...this.tradeHistory];

      if (this.selectedType) {
        if (this.selectedType === "Zar to Crypto") {
          filtered = filtered.filter(transaction => transaction.currency === 'ZAR');
        } else if (this.selectedType === "Crypto to Zar") {
          filtered = filtered.filter(transaction => transaction.currency !== 'ZAR');
        } else {
          filtered = filtered.filter(
            (transaction) => transaction.type === this.selectedType
          );
        }
      }

      if (this.selectedAsset) {
        filtered = filtered.filter(
          (transaction) => transaction.currency === this.selectedAsset
        );
      }

      if (this.dates && this.dates.length === 2) {
        const startDate = new Date(this.dates[0]);
        const endDate = new Date(this.dates[1]);
        filtered = filtered.filter((transaction) => {
          const transactionDate = new Date(transaction.date);
          return transactionDate >= startDate && transactionDate <= endDate;
        });
      }

      this.displayedTransactions = filtered;
      this.sortTransactions();
    },
    sortTransactions() {
      if (!this.sortKey) return;

      this.displayedTransactions.sort((a, b) => {
        let valueA = a[this.sortKey.toLowerCase()];
        let valueB = b[this.sortKey.toLowerCase()];

        if (this.sortKey === "Date") {
          valueA = new Date(valueA).getTime();
          valueB = new Date(valueB).getTime();
          return valueB - valueA;
        }

        if (this.sortKey === "Amount") {
          return parseFloat(valueB) - parseFloat(valueA);
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueB.localeCompare(valueA);
        }

        return valueA > valueB ? -1 : 1;
      });
    },
    exportToCSV() {
      const rows = [
        ["Type", "Date", "Currency", "Amount", "Fee", "Status", "Note"],
        ...this.displayedTransactions.map((t) => [
          t.type,
          t.date,
          t.currency,
          t.amount,
          t.fee,
          t.state,
          t.note,
        ]),
      ];

      let csvContent = "data:text/csv;charset=utf-8,";
      rows.forEach((rowArray) => {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "transaction_history.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    resetDates() {
      this.dates = [];
      this.filterTransactions();
    },
    clearFilters() {
      this.dates = [];
      this.selectedType = null;
      this.selectedAsset = null;
      this.filterTransactions();
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>



<style scoped>
.datePicker {
  font-size: 14px !important;
}

.sort-select {
  max-width: 150px;
  margin-left: 8px;
}

.transaction-card {
  border-left: 5px solid #00155f;
  min-height: 70px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.sticky-container {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.scroll-to-top {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotating-loader {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
