<template>
  <div class="background full-height full-width">
    <v-dialog v-model="dialogSuccess" width="500px" height="500px">
      <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto"
      >
        <v-icon class="animated-icon" color="green" size="112">
          mdi-check-circle
        </v-icon>
        <h2 class="text-h5 mb-6 my-3">Please check your inbox.</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          The password reset email has been sent
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-center">
          <v-btn
            class="text-none my-2"
            color="black"
            rounded
            outlined
            dark
            variant="flat"
            width="200px"
            @click="goToPage('/')"
          >
            Close
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    
    <v-dialog v-model="dialogError" width="500px" height="500px">
      <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto"
      >
        <v-icon class="animated-icon" color="red" size="112">
          mdi-alert-circle
        </v-icon>
        <h2 class="text-h5 mb-6 my-3">Error</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          {{ errorMessage }}
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-center">
          <v-btn
            class="text-none my-2"
            color="black"
            rounded
            outlined
            dark
            variant="flat"
            width="200px"
            @click="closeErrorDialog"
          >
            Close
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    
    <div class="gradient">
    
        <v-row justify="center" >
            <a href="https://dashboard.hodlotc.com/"  target="_blank">
              <v-img
                src="../assets/images/Logo.png"
                contain
                height="80"
                class="mt-16"
              ></v-img>
            </a>
          </v-row>
        <v-card
          class="mx-auto pa-12 pb-1 text-center card"
          elevation="8"
          max-width="448"
          style="
            margin-top: 100px;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              8deg,
              rgba(0, 0, 0, 1) 0%,
              rgba(7, 24, 92, 1) 100%
            );
          "
          rounded="lg"
          color="#373737"
          dark
        >
          <v-row justify="center">
            <div><h2 style="color: white;">Forgot Password?</h2></div>
          </v-row>
          <v-row>
            <v-text-field
              solo
              outlined
              light
              dense
              rounded
              label="Email"
              ref="email"
              v-model="formattedEmail"
              @input="sanitizeEmailInput"
              density="compact"
              placeholder="Email address"
              prepend-inner-icon="mdi-email-outline"
              variant="outlined"
              style="margin-top: 20px;"
              class="field"
            ></v-text-field>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="7">
              <v-btn
                solo
                rounded
                block
                class="mb-8"
                light
                color="white"
                width="50%"
                variant="tonal"
                @click="validateAndResetPassword"
              >
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
</template>

<script>
  import { auth, db } from "../firebaseConfig.js";
  import { sendPasswordResetEmail } from "firebase/auth";
  import { collection, query, where, getDocs } from "firebase/firestore";
  import emailjs from "emailjs-com";

  export default {
    data() {
      return {
        dialogSuccess: false,
        dialogError: false,
        email: "",
        rawEmail: "",
        emailRules: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+\..+/.test(v) || "Email must be valid",
        ],
        errorMessage: "Something went wrong, please try again",
        successMessage: "Success",
      };
    },
    methods: {
      goToPage(path) {
        this.$router.push({ path });
      },
      sanitizeEmailInput() {
        this.rawEmail = this.rawEmail.replace(/[\s\t]/g, "").toLowerCase();
      },
      validateAndResetPassword() {
        let emailValid = this.$refs.email.validate();

        if (!emailValid) {
          this.errorMessage = "Please enter a valid email!";
          this.displayErrorMessage();
          return;
        }

        this.checkEmailExistsInCollections(this.rawEmail)
          .then((exists) => {
            if (!exists) {
              this.errorMessage = "Email does not exist!";
              this.displayErrorMessage();
            } else {
              this.resetPassword();
            }
          })
          .catch((error) => {
            console.error(error);
            this.errorMessage = "Error checking email, please try again!";
            this.displayErrorMessage();
          });
      },
      async checkEmailExistsInCollections(email) {
        const collections = ['individuals', 'companies', 'trusts'];
        const emailExistsPromises = collections.map(async (collectionName) => {
          const q = query(collection(db, collectionName), where('email', '==', email));
          const querySnapshot = await getDocs(q);
          return !querySnapshot.empty;
        });

        const results = await Promise.all(emailExistsPromises);
        return results.some((exists) => exists);
      },
      resetPassword() {
        sendPasswordResetEmail(auth, this.rawEmail)
          .then(() => {
            this.successMessage =
              "Password reset email sent! Please check your inbox.";
            this.displaySuccessMessage();
            this.dialogSuccess = true;
            this.sendAdminNotification();
          })
          .catch((error) => {
            console.error(error);
            this.errorMessage =
              "Failed to send password reset email, please try again!";
            this.displayErrorMessage();
          });
      },
      sendAdminNotification() {
        const clientEmail = this.rawEmail;

        const templateParams = {
          client_email: clientEmail,
          message: `
            A client has reset their password. Please verify their account.
            Client Email: ${clientEmail}
          `,
        };

        emailjs
          .send(
            "service_9esjl09", // Replace with your EmailJS service ID
            "template_u7lqqom", // Replace with your EmailJS template ID
            templateParams,
            "mr1E1BaGMKZMEzWVu" // Replace with your EmailJS user ID
          )
          .then(
            (response) => {
              console.log(
                "Admin notification email sent successfully!",
                response.status,
                response.text
              );
            },
            (error) => {
              console.error("Failed to send admin notification email.", error);
            }
          );
      },
      displayErrorMessage() {
        this.dialogError = true;
      },
      displaySuccessMessage() {
        this.dialogSuccess = true;
      },
      closeErrorDialog() {
        this.dialogError = false;
      },
    },
    computed: {
      formattedEmail: {
        get() {
          return this.rawEmail;
        },
        set(value) {
          this.rawEmail = value.toLowerCase(); // Convert to lowercase
        },
      },
    },
  };
</script>

<style scoped>
  .background {
    background-image: url("../assets/images/Login.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .description {
    color: white;
  }
  .header {
    text-align: center;
  }

  .card {
    margin: 0 auto;
    margin-top: 200px;
    max-width: 448px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      8deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(7, 24, 92, 1) 100%
    );
    width: 100%;
  }
</style>
