<template>
  <v-col class="pa-0">
    <v-row justify="center">
      <v-card rounded class="white-card full-width py-8">
        <h4>Savings Interest Preferences</h4>
        <v-card
          :style="{ 'background-color': '#F7F7F7' }"
          rounded
          class="full-width pa-8 mt-6"
        >
          <v-radio-group v-model="sameCurrency">
            <v-row>
              <v-radio :value="true"></v-radio>
              <v-col>
                <v-row justify="start">
                  <h4>Interest payout in the same currency</h4>
                </v-row>
                <v-row justify="start">
                  <p class="grey-text">Interest payout in the same currency</p>
                </v-row>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-divider></v-divider>
          <v-radio-group v-model="insuranceOpt">
            <v-row>
              <v-radio value="In"></v-radio>
              <v-col>
                <v-row justify="start">
                  <h4>Interest Opt in</h4>
                </v-row>
                <v-row justify="start">
                  <p class="grey-text">
                    Receive up to 2% bonus interest on all your assets and a
                    special bonus interest of 4% for AXS
                  </p>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-radio value="Out"></v-radio>
              <v-col>
                <v-row justify="start">
                  <h4>Interest Opt out</h4>
                </v-row>
                <v-row justify="start">
                  <p class="grey-text">
                    0% interest but will have discount on trades
                  </p>
                </v-row>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card>
        <p class="grey-text text-center mt-6">
          If changed this will take 48hrs to implement
        </p>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      sameCurrency: true,
      insuranceOpt: "In",
    };
  },
  methods() {},
};
</script>

<style>
</style>