<template>
  <v-container class="address-book pa-8 full-width">
    <v-row justify="center">
      <v-card
        :style="{ border: '1px solid #0682D4' }"
        rounded
        hover
        class="full-width pa-8"
      >
      <v-row
          class="pointer mb-2"
          @click="
            $router.push({
              path: '/security',
            })
          ">
          <v-icon class="blue-text"> mdi-arrow-left </v-icon>
          <h4>Go Back</h4>
        </v-row>
        <v-alert
          type="info"
          dense
          text
          prominent
          border="left"
          colored-border
          color="blue lighten-2"
        >
          Note: Your wallet will only be eligible for withdrawals once it has
          been verified by HODL. The verification process may take some time.
        </v-alert>
        <v-row justify="center" justify-md="start">
          <h3 class="text-center">Address Book</h3>
        </v-row>
        <v-row justify="center" justify-md="start">
          <h6 class="grey-text text-center">
            Manage your crypto addresses and improve the security of your
            account even further by enabling Address White listing
          </h6>
        </v-row>
        <v-row justify="center" justify-md="start">
          <v-col cols="12" sm="4" md="3">
            <v-row justify="center">
              <v-text-field
                v-model="searchQuery"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                rounded
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-row class="full-height" justify="center" align="center"> </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <v-row justify="center" justify-sm="end">
              <v-btn
                @click="dialog = true"
                elevation="0"
                rounded
                prepend-icon="mdi-plus"
                class="blue-text"
              >
                New Address <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-data-table
            class="full-width"
            :items="filteredAddresses"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
          >
          </v-data-table>
        </v-row>
        <v-row justify="center">
          <v-card
            @click="dialog = true"
            class="full-width pa-2 pointer"
            :style="{ 'background-color': '#F3F6FD' }"
          >
            <v-row justify="center" align="center">
              <h5 class="blue-text">New Address</h5>
              <v-icon class="blue-text">mdi-plus</v-icon>
            </v-row>
          </v-card>
        </v-row>
      </v-card>
    </v-row>
    <v-dialog width="500px" v-if="dialog" v-model="dialog">
      <v-row justify="center">
        <v-card elevation="10" class="full-width" :style="{ padding: '5%' }">
          <v-row justify="center" justify-sm="start">
            <h3>Add Withdrawal Address</h3>
          </v-row>

          <v-row justify="center" justify-sm="start">
            <!-- New Alert Component -->
            <v-alert
              type="warning"
              dense
              text
              prominent
              border="left"
              colored-border
              color="orange darken-2"
            >
              Important: Once a withdrawal address is added, it cannot be
              removed. Please double-check your details.
            </v-alert>
          </v-row>
          <v-row justify="center" justify-sm="start">
            <h5 class="mt-4">Coin</h5>
          </v-row>
          <v-row justify="center">
            <v-select
              v-model="selectedCurrency"
              :items="currenciesWithBalance"
              rounded
              outlined
              :hide-details="true"
              dense
              label=""
            ></v-select>
          </v-row>
          <v-row justify="center" justify-sm="start">
            <h5 class="mt-4">Label</h5>
          </v-row>
          <v-row justify="center">
            <v-text-field
              rounded
              outlined
              v-model="name"
              :hide-details="true"
              dense
            >
            </v-text-field>
          </v-row>
          <v-row justify="center" justify-sm="start">
            <h5 class="mt-4">Wallet Address</h5>
          </v-row>
          <v-row justify="center">
            <v-text-field
              rounded
              outlined
              :hide-details="true"
              dense
              v-model="withdrawalAddress"
            >
            </v-text-field>
          </v-row>

          <v-row justify="center">
            <v-btn @click="dialog = false" rounded class="white-blue-btn mt-4"
              >Cancel</v-btn
            >
          </v-row>
          <v-row justify="center">
            <v-btn @click="submitData()" rounded class="dark-blue-btn mt-4"
              >Save Address</v-btn
            >
          </v-row>
        </v-card>
      </v-row>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

export default {
  data() {
    return {
      dialog: false,
      clientSN: "",
      beneficiaries: [],
      addresses: [],
      selectedCurrency: "BTC",
      currenciesWithBalance: [],
      name: "",
      loading: true,
      searchQuery: "",
      withdrawalAddress: "",
      headers: [
        {
          text: "Asset",
          align: "center",
          sortable: false,
          value: "currency_id",
        },
        {
          text: "Label",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Address",
          align: "center",
          sortable: false,
          value: "rid",
        },
        {
          text: "State",
          align: "center",
          sortable: false,
          value: "confirmed",
        },
        {
          text: "Beneficiary ID",
          align: "center",
          sortable: false,
          value: "beneficiary_id",
        }
      ],
    };
  },
  computed: {
    filteredAddresses() {
      return this.addresses.filter((item) => {
        return Object.keys(item).some((key) => {
          return item[key]
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      });
    },
  },
  methods: {
    async fetchUserData() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const db = getFirestore();
          const collections = ["individuals", "companies", "trusts"];
          for (let collection of collections) {
            try {
              const docRef = doc(db, collection, uid);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists() && docSnap.data().clientSN) {
                this.clientSN = docSnap.data().clientSN;
                this.uid = docSnap.data().uid;
                console.log("Client SN:", this.clientSN); // Log the clientSN
                this.fetchAccounts();
                this.fetchBeneficiaries(); // Call fetchBeneficiaries after setting clientSN
                break;
              }
            } catch (error) {
              console.error("Error fetching clientSN:", error);
            }
          }
        } else {
          console.log("User is signed out");
        }
      });
    },
    async fetchAccounts() {
      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/GetAccounts"
        );
        const allData = await response.json();
        this.currenciesWithBalance = allData
          .filter(
            (account) =>
              account.sn === this.clientSN && !account.currency.includes("_")
          )
          .map((account) => account.currency.toUpperCase()); // Transform and store currency names in uppercase
        console.log("Filtered Accounts:", this.currenciesWithBalance);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    },
    async submitData() {
      const data = {
        name: this.name,
        currency_id: this.selectedCurrency.toLowerCase(),
        withdrawal_address: this.withdrawalAddress,
      };

      try {
        await this.saveWalletData(data);
        console.log("Submission successful", data);
        // Clear the fields after successful submission
        this.name = "";
        this.withdrawalAddress = "";
      } catch (error) {
        console.error("Submission failed:", error.message);
      }
    },

    async saveWalletData(data) {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const walletDocRef = doc(db, "wallets", user.uid);
        try {
          // First, get the current document to check for existing wallet data
          const docSnapshot = await getDoc(walletDocRef);
          let walletData = [];
          if (docSnapshot.exists()) {
            // If the document exists and wallet_data is an array, use it
            walletData = docSnapshot.data().wallet_data || [];
          }
          // Add the new wallet data to the array
          walletData.push(data);

          // Update the document with the new array of wallet data
          await setDoc(
            walletDocRef,
            { wallet_data: walletData },
            { merge: true }
          );
          console.log("Wallet data saved successfully.");
          this.dialog = false;
        } catch (error) {
          console.error("Error saving wallet data:", error);
          this.dialog = false;
        }
      } else {
        console.error("User is not logged in.");
        this.dialog = false;
      }
    },

    async fetchBeneficiaries() {
      this.loading = true;
      try {
        if (!this.clientSN) {
          this.error = "Client SN is not available.";
          console.error(this.error);
          this.loading = false;
          return;
        }

        console.log("Client SN:", this.clientSN); // Log the clientSN

        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetBeneficiarybysn?sn=${this.clientSN}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("API Response:", data); // Log the API response

        const allData = data.map((beneficiary) => ({
          beneficiary_id: beneficiary.beneficiary_id,
          currency_id: beneficiary.currency_id.toUpperCase(),
          rid: beneficiary.rid,
          name: beneficiary.name,
          confirmed: beneficiary.confirmed ? "Confirmed" : "Pending",
        }));

        this.addresses = [...allData];
        console.log(
          "Withdrawal addresses fetched successfully:",
          this.addresses
        );
        this.loading = false;
      } catch (error) {
        console.error("Error fetching withdrawal addresses:", error);
        this.error =
          error.message ||
          "An error occurred while fetching withdrawal addresses.";
        this.loading = false;
      }
    },
    // Other existing methods
  },
  created() {
    this.fetchUserData(); // Call fetchUserData when the component is created
  },
};
</script>

<style scoped></style>

<style>
  /* Center radio buttons */
  .address-book .v-input--selection-controls .v-radio {
    margin-bottom: 0 !important;
  }

  .address-book .v-data-table .v-data-table__wrapper table {
    border: 1px solid grey !important;
  }

  .address-book thead {
    outline: 1px solid grey !important;
  }

  .address-book .v-data-table .v-data-footer {
    border: 1px solid grey !important;
  }

  .address-book td,
  .address-book thead span {
    color: black !important;
  }
</style>
