<template>
  <v-container class="login-info">
    <transition name="slide">
      <v-col v-if="showForm" justify="center">
        <v-row
          class="pointer"
          @click="
            $router.push({
              path: '/security',
            })
          ">
          <v-icon class="blue-text"> mdi-arrow-left </v-icon>
          <h4>Go Back</h4>
        </v-row>
        <v-row justify="center">
          <v-card
            class="full-width white-card py-6 mt-6"
            :style="{ padding: '8%' }">
            <v-row>
              <v-icon
                :style="{
                  'font-size': '80px',
                }"
                class="dark-blue-text">
                mdi-shield-lock
              </v-icon>
              <v-col>
                <h4>Login Information</h4>
                <h5 class="mt-2 grey-text">
                  Change your password, see your account e-mail details, and
                  monitor the logins to your account.
                </h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-card outlined class="pa-4">
                  <v-row align="start">
                    <v-icon class="blue-text" large> mdi-account </v-icon>
                    <v-col class="py-0">
                      <v-row justify="start">
                        <h4>Contact Information</h4>
                      </v-row>
                      <v-row justify="start">
                        <h5>
                          For security reasons, please contact Hodl Support if
                          you want to change your email address.
                        </h5>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your Email</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="userEmail">
                    </v-text-field>
                  </v-row>
                </v-card>
                <v-card outlined class="pa-4 mt-6">
                  <v-row align="start">
                    <v-icon class="blue-text" large> mdi-lock </v-icon>
                    <v-col class="py-0">
                      <v-row justify="start">
                        <h4>Change Password</h4>
                      </v-row>
                      <v-row justify="start">
                        <h5>
                          Never share your password with anyone, including the
                          Hodl team.
                        </h5>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Current Password</h5>
                  </v-row>
                  <v-alert
                    :type="showSuccessAlert ? 'success' : 'error'"
                    v-if="showSuccessAlert || showErrorAlert"
                    dismissible
                    @input="resetAlerts">
                    {{ alertMessage }}
                  </v-alert>
                  <v-row justify="start">
                    <v-text-field
                      outlined
                      dense
                      rounded
                      :hide-details="true"
                      v-model="currentPassword"
                      placeholder="Current Password"
                      type="password">
                    </v-text-field>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">New Password</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      outlined
                      dense
                      rounded
                      :hide-details="true"
                      v-model="newPassword"
                      placeholder="New Password"
                      type="password">
                    </v-text-field>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Repeat Password</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      outlined
                      dense
                      rounded
                      :hide-details="true"
                      v-model="repeatNewPassword"
                      placeholder="Repeat New Password"
                      type="password">
                    </v-text-field>
                  </v-row>
                  <v-row justify="start">
                    <v-btn
                      rounded
                      class="dark-blue-btn mt-6"
                      @click="changePassword">
                      Change
                    </v-btn>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="7">
                <v-card outlined class="pa-4">
                  <v-row justify="start" align="start">
                    <v-icon class="blue-text" large> mdi-login </v-icon>
                    <v-col class="py-0">
                      <h4>Last Login</h4>
                      <h5>Monitor the logins to your HODL OTC account.</h5>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-data-table
                      class="mt-4"
                      :items="items"
                      :loading="loading"
                      :headers="headers">
                    </v-data-table>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </transition>
  </v-container>
</template>

<script>
  import { auth } from "../firebaseConfig";
  import { firebaseApp } from "../firebaseConfig";
  import { format } from "date-fns";
  import {
    onAuthStateChanged,
    reauthenticateWithCredential,
    EmailAuthProvider,
    updatePassword,
  } from "firebase/auth";
  import { doc, getDoc, getFirestore } from "firebase/firestore";
  const db = getFirestore(firebaseApp);

  export default {
    data() {
      return {
        showForm: true,
        loading: false,
        userEmail: "",
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: "",
        showSuccessAlert: false,
        showErrorAlert: false,
        headers: [
          {
            text: "Time",
            value: "loginTime",
          },
          { text: "Geolocation", value: "geolocation" },
          {
            text: "IP Address",
            value: "ip",
          },
        ],
        items: [],
      };
    },
    methods: {
      resetAlerts() {
        this.showSuccessAlert = false;
        this.showErrorAlert = false;
        this.alertMessage = "";
      },

      changePassword() {
        const user = auth.currentUser;
        if (!user) {
          alert("No user logged in");
          return;
        }

        if (this.newPassword !== this.repeatNewPassword) {
          alert("New passwords do not match");
          return;
        }

        // Re-authenticate the user
        const credential = EmailAuthProvider.credential(
          user.email,
          this.currentPassword
        );

        reauthenticateWithCredential(user, credential)
          .then(() => {
            // User re-authenticated.
            updatePassword(user, this.newPassword)
              .then(() => {
                this.alertMessage = "Password updated successfully";
                this.showSuccessAlert = true;
                this.showErrorAlert = false;

                // Clear password fields
                this.currentPassword = "";
                this.newPassword = "";
                this.repeatNewPassword = "";
              })
              .catch((error) => {
                console.error("Error updating password:", error);
                this.alertMessage = "Error updating password";
                this.showErrorAlert = true;
                this.showSuccessAlert = false;
              });
          })
          .catch((error) => {
            console.error("Error re-authenticating:", error);
            this.alertMessage = "Error re-authenticating";
            this.showErrorAlert = true;
            this.showSuccessAlert = false;
          });
      },
      async getGeolocationFromIP(ip) {
        try {
          const response = await fetch(`http://ip-api.com/json/${ip}`);
          if (!response.ok) {
            throw new Error("Failed to fetch geolocation data");
          }
          const data = await response.json();
          if (data.status !== "success") {
            throw new Error("Geolocation fetch was unsuccessful");
          }
          return `${data.city}, ${data.country}`; // Customize this based on the data you need
        } catch (error) {
          console.error("Error fetching geolocation:", error);
          return "Geolocation unavailable"; // Fallback message or handling
        }
      },
      async fetchLoginData(uid) {
        this.loading = true;
        const userLoginsDocRef = doc(db, "logins", uid);
        const docSnapshot = await getDoc(userLoginsDocRef);
        if (docSnapshot.exists()) {
          const loginData = docSnapshot.data().logins;
          const loginPromises = loginData.map(async (login) => {
            const geolocation = await this.getGeolocationFromIP(login.ip);
            return {
              ...login,
              loginTime: format(new Date(login.loginTime), "p d MMMM yyyy"), // Formatting date
              geolocation, // Adding geolocation data
            };
          });
          this.items = await Promise.all(loginPromises);
          this.loading = false;
        } else {
          console.log("No login data found");
          this.loading = false;
        }
      },
    },
    created() {
      this.showForm = true;

      // Fetch the current user's email from Firebase
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.userEmail = user.email; // Update userEmail with the current user's email
          this.fetchLoginData(user.uid); // Fetch and display login data
        } else {
          console.log("No user logged in");
        }
      });
    },
  };
</script>

<style>
  .login-info tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .login-info th span {
    color: black;
    font-weight: bold;
  }

  .login-info .v-data-table {
    border: 1px solid lightgrey;
  }
</style>

<style scoped>
  /* Animations: */
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.6s;
  }
  .slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
    transform: translateX(100%);
  }
</style>
