
<template>
  <v-container class="view-currency pa-0 full-width">
    <v-row justify="center">
      <div
        class="white-card pb-8 px-12 no-radius full-width"
        :style="{ 'overflow-y': 'hidden' }"
      >
        <v-row justify="center">
          <v-col cols="10">
            <h2>Transfer Between Wallets</h2>
          </v-col>
          <v-col cols="2">
            <v-row justify="end">
              <v-icon @click="$emit('close')"> mdi-close </v-icon>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-select rounded outlined dense label="Select Currency"> </v-select>
        </v-row>
        <v-col cols="12" class="pa-0" style="position: relative">
          <v-row justify="center">
            <v-card class="full-width pa-2 no-radius" outlined>
              <h5>From {{ wallet1 }} Wallet</h5>
            </v-card>
          </v-row>
          <v-row justify="center">
            <v-card class="full-width pa-2 no-radius" outlined>
              <h5>To {{ wallet2 }} Wallet</h5>
            </v-card>
          </v-row>
          <v-card
            @click="switchWallets()"
            class="top absolute"
            outlined
            :style="{
              right: '10%',
              top: '20%',
              width: '10%',
              height: '50%',
            }"
          >
            <v-row class="full-height" justify="center" align="center">
              <v-icon> mdi-swap-vertical </v-icon>
            </v-row>
          </v-card>
        </v-col>
        <v-row class="relative" justify="center">
          <v-text-field
            :hide-details="true"
            outlined
            dense
            label="Amount"
            class="full-width mt-10"
          >
          </v-text-field>
          <v-btn
            class="blue-card absolute"
            :style="{
              top: '45px',
              right: '10px',
              height: '25px',
            }"
            rounded
            dense
          >
            Max
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-col class="pa-0"><h5>Available For Transfer</h5></v-col>
          <v-col class="pa-0">
            <v-row justify="end"
              ><h5 class="dark-blue-text">BTC 0.34759018</h5></v-row
            >
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn class="dark-blue-btn full-width mt-6" rounded dense>
            Transfer
          </v-btn>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      wallet1: "Savings",
      wallet2: "Credit Line",
    };
  },
  methods: {
    switchWallets() {
      let tempWallet = this.wallet1;
      this.wallet1 = this.wallet2;
      this.wallet2 = tempWallet;
    },
  },
};
</script>

<style scoped>
.blue-card {
  background-color: #bfc4d5 !important;
  color: #001458;
  font-size: 12px;
}
</style>