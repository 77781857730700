<template>
    <v-container class="pa-0 full-width full-height">
      <v-responsive aspect-ratio="16/9">
        <video autoplay muted loop class="video">
          <source
            src="https://firebasestorage.googleapis.com/v0/b/web-hodl.appspot.com/o/Globe.mp4?alt=media&token=ea7a6d48-ab84-40b8-b5ba-9f9187614376"
            type="video/mp4"
          />
        </video>
      </v-responsive>
  
      <v-row justify="center" class="mt-12">
        <a href="https://hodlotc.com/" target="_blank">
          <v-img src="../assets/images/Logo.png" contain height="80"></v-img>
        </a>
      </v-row>
  
      <!-- Combined Maintenance Notice -->
      <v-row justify="center">
        <v-card class="card transparent-card pb-0" width="600px">
          <h1 class="white-text text-center">Maintenance Mode</h1>
          <h5 class="white-text text-center">
            Our website is currently undergoing scheduled maintenance. We are working on some exciting new features to enhance your experience. We apologize for any inconvenience and appreciate your patience.
          </h5>
          <h3 class="white-text text-center mt-4">We're working on some exciting new features!</h3>
          <p class="white-text text-center">
            To place a trade during this time, please contact your account manager through our VIP OTC Desk WhatsApp service.
          </p>
          <p class="white-text text-center">
            WhatsApp Number: 
            <a href="https://wa.me/+27665601607" target="_blank" class="whatsapp-link"><strong>066 560 1607</strong></a> 
          </p>
          <v-row justify="center">
            <v-btn dark rounded class="pink-btn my-5" @click="goHome">
              Go to Homepage
            </v-btn>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "MaintenanceMode",
    methods: {
      goHome() {
        window.location.href = "https://hodlotc.com/";
      },
    },
  };
  </script>
  
  <style scoped>
  .video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .card {
    margin-top: 2rem;
    max-width: 80%;
    padding: 1rem;
  }
  
  .whatsapp-link {
    color: #00e676; /* Green color to indicate WhatsApp */
  }
  
  h1 {
    font-size: 26px;
  }
  
  .mt-4 {
    margin-top: 1.5rem;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  </style>
  