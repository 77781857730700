<template>
  <v-container class="createpaymentlink pa-8 full-width">
    <transition name="slide">
      <v-row v-if="showForm && !$route.query.type" justify="center">
        <v-col>
          <v-row justify="center">
            <h5>Select a payment type to continue</h5>
          </v-row>
          <v-row justify="center">
            <v-radio-group v-model="paymentType">
              <v-row justify="center">
                <v-col cols="12" sm="6">
                  <v-card
                    @click="paymentType = 'SingleCharge'"
                    rounded
                    :style="{ width: '200px', height: '250px' }"
                  >
                    <v-col>
                      <v-radio value="SingleCharge" class="radio"></v-radio>
                      <v-row justify="center">
                        <img src="../assets/images/PaymentLinks1.png" />
                      </v-row>
                      <v-row justify="center">
                        <h5 class="mt-4">Single Charge</h5>
                      </v-row>
                      <v-row justify="center">
                        <h6 class="grey-text text-center mt-4">
                          Create a payment link to receive a one-time payment
                          from your customer, modify the currency as needed
                        </h6>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card
                    @click="paymentType = 'Donations'"
                    rounded
                    :style="{ width: '200px', height: '250px' }"
                  >
                    <v-col>
                      <v-radio value="Donations" class="radio"></v-radio>
                      <v-row justify="center">
                        <img src="../assets/images/PaymentLinks2.png" />
                      </v-row>
                      <v-row justify="center">
                        <h5 class="mt-4">Donations</h5>
                      </v-row>
                      <v-row justify="center">
                        <h6 class="grey-text text-center mt-4">
                          Receive one-time donations for your charity cause
                          towards group goals through donation links
                        </h6>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-row>
          <v-row justify="center">
            <v-btn
              class="pink-btn mt-8"
              @click="$router.push({ query: { type: paymentType } })"
            >
              Create payment link
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </transition>
    <transition name="slide">
      <v-row
        v-if="showForm && $route.query.type && !$route.query.linkCreated"
        justify="center"
      >
        <v-card class="full-width pa-12">
          <v-row>
            <v-col cols="12" md="5">
              <h4>Create Payment Link</h4>
              <h5 class="grey-text mt-4">Page Name</h5>
              <v-text-field outlined dense :hide-details="true"></v-text-field>
              <h5 class="grey-text mt-4">Page Name</h5>
              <v-textarea outlined dense :hide-details="true"></v-textarea>
              <h5 class="grey-text mt-4">Amount</h5>
              <v-row>
                <v-col cols="3" class="pa-0">
                  <v-select outlined dense :hide-details="true"></v-select>
                </v-col>
                <v-col cols="9" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    :hide-details="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <h5 class="grey-text">
                Leave empty to allow customers enter desired amount
              </h5>
              <h5 class="grey-text mt-4">
                Only accept these crypto currencies
              </h5>
              <v-select outlined dense :hide-details="true"></v-select>
              <v-expansion-panels
                accordion
                flat
                :style="{ 'background-color': '#FDFAE5 !important' }"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="blue-text pa-0 underline"
                    >Show additional details</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="pa-0">
                    <h5 class="grey-text">Use Custom URL</h5>
                    <v-row>
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          dense
                          :hide-details="true"
                          value="HODLOTC.com/pay/"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          dense
                          :hide-details="true"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <h5 class="grey-text mt-4">Redirect Payment URL</h5>
                    <v-text-field
                      outlined
                      dense
                      :hide-details="true"
                    ></v-text-field>

                    <v-row class="mt-4">
                      <v-col cols="2" class="pa-0">
                        <v-switch
                          class="pt-0 mt-0"
                          :hide-details="true"
                        ></v-switch>
                      </v-col>
                      <v-col cols="10" class="pa-0">
                        <h5 class="grey-text">
                          Automatically convert to ZAR (Client default currency)
                        </h5>
                      </v-col>
                    </v-row>
                    <h5 class="grey-text mt-4">Contact Number</h5>
                    <v-text-field
                      outlined
                      dense
                      :hide-details="true"
                    ></v-text-field>
                    <v-row justify="start">
                      <h5 class="grey-text mt-4">Collect extra information</h5>
                    </v-row>
                    <v-col class="pa-0">
                      <v-row
                        class="my-4"
                        v-for="(field, index) in extraFields"
                        :key="field"
                      >
                        <v-col cols="5" class="pa-0">
                          <v-text-field
                            outlined
                            dense
                            label="Field"
                            :hide-details="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pa-0">
                          <v-text-field
                            outlined
                            dense
                            :hide-details="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1" class="pa-0">
                          <v-row
                            class="full-height"
                            justify="center"
                            align="center"
                          >
                            <v-icon @click="extraFields.splice(index, 1)">
                              mdi-close
                            </v-icon>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-row>
                      <h6
                        @click="extraFields.push({})"
                        class="blue-text mt-2 pointer"
                      >
                        Add another one
                      </h6>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" md="7">
              <v-row justify="center" class="px-12">
                <div class="dropzone">
                  <v-card
                    class="pa-12"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                  >
                    <input
                      type="file"
                      style="visibility: hidden"
                      @change="onChange"
                      ref="file"
                      accept=".pdf,.jpg,.jpeg,.png"
                    />
                    <v-row v-if="!files.length" justify="center">
                      <v-icon x-large :style="{ 'font-size': '80px' }">
                        mdi-image
                      </v-icon>
                    </v-row>
                    <v-row v-if="files.length" justify="center">
                      <v-col cols="12">
                        <v-row justify="center">
                          <img height="100px" :src="imageUrl" />
                        </v-row>
                        <v-row justify="center">
                          <h5>{{ files[0].name }}</h5>
                        </v-row>
                        <v-row justify="center">
                          <h5
                            @click="remove(files.indexOf(files[0]))"
                            :style="{ color: 'red' }"
                            class="pointer"
                          >
                            Remove
                          </h5>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <!-- <h5 class="blue-text">
                        Drag image here or click to upload
                      </h5> -->
                      <v-file-input
                        :clearable="false"
                        :prepend-icon="false"
                        v-model="files[0]"
                        ref="fileInput"
                        elavation="0"
                        @change="addFile"
                        class="blue-text mt-4"
                        label="Drag image here or click to upload"
                      ></v-file-input>
                    </v-row>
                    <v-row justify="center">
                      <h6 class="grey-text text-center mt-4">
                        This image will be displayed on the social platforms
                        where the link is shared
                      </h6>
                    </v-row>
                    <v-row justify="center">
                      <h6 class="grey-text text-center mt-4">
                        Leaving this field blank adds the default image to the
                        link
                      </h6>
                    </v-row>
                  </v-card>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-btn dark class="my-4" @click="$router.push('/createpaymentlink')">
            Cancel
          </v-btn>
          <v-btn
            class="purple-btn my-4 mx-2"
            @click="
              $router.push({ query: { type: paymentType, linkCreated: true } })
            "
          >
            Create Link
          </v-btn>
        </v-row>
      </v-row>
    </transition>
    <transition name="slide">
      <v-row
        v-if="showForm && $route.query.type && $route.query.linkCreated"
        justify="center"
      >
        <v-card rounded class="pa-6">
          <v-row justify="center">
            <img height="60px" src="../assets/images/Logo.png" />
          </v-row>
          <v-row justify="center">
            <v-col cols="6" class="px-2">
              <h5 class="grey-text">First Name</h5>
              <v-text-field outlined dense :hide-details="true"></v-text-field>
            </v-col>
            <v-col cols="6" class="px-2">
              <h5 class="grey-text">Last Name</h5>
              <v-text-field outlined dense :hide-details="true"></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="6" class="px-2">
              <h5 class="grey-text">Email Address</h5>
              <v-text-field outlined dense :hide-details="true"></v-text-field>
            </v-col>
            <v-col cols="6" class="px-2">
              <h5 class="grey-text">Contact Number</h5>
              <v-text-field outlined dense :hide-details="true"></v-text-field>
            </v-col>
          </v-row>
          <h5 class="grey-text mt-4 mx-2">
            Select crypto currency you would like to pay with
          </h5>
          <v-select class="mx-2" outlined dense :hide-details="true"></v-select>
          <h5 class="grey-text mx-2 mt-4">Select Network</h5>
          <v-select class="mx-2" outlined dense :hide-details="true"></v-select>
          <h5 class="grey-text mt-4 mx-2">How much would you like to pay</h5>
          <v-row class="px-2">
            <v-col cols="3" class="pa-0">
              <v-select outlined dense :hide-details="true"></v-select>
            </v-col>
            <v-col cols="9" class="pa-0">
              <v-text-field outlined dense :hide-details="true"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-2 mt-4" justify-sm="end" justify="center">
            <v-btn
              @click="$router.push({ query: { type: $route.query.type } })"
              class="mx-2"
              :style="{ 'background-color': '#B6B6B6' }"
              >Cancel</v-btn
            >
            <v-btn @click="paying = true" class="pink-btn mx-2">Pay</v-btn>
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text mt-8">
              If you have any questions, please contact
            </h5>
          </v-row>
          <v-row justify="center">
            <h5 class="blue-text">
              Jaque@HODL.co.za (merchant email plus contact number must appear
              here)
            </h5>
          </v-row>
        </v-card>
      </v-row>
    </transition>
    <v-dialog width="500px" v-if="paying" v-model="paying">
      <v-row
        v-if="showForm && $route.query.type && $route.query.linkCreated"
        justify="center"
      >
        <v-card class="full-width pa-6" rounded>
          <v-row justify="center">
            <img height="80px" src="../assets/images/Crypto-Logo.png" />
          </v-row>
          <v-row justify="center">
            <v-col cols="6">
              <h5 class="grey-text">Crypto Amount</h5>
              <v-card rounded class="pa-2">
                <h5 class="grey-text">BTC: 0.000014</h5>
              </v-card>
            </v-col>
            <v-col cols="6">
              <h5 class="grey-text">Fiat Amount</h5>
              <v-card rounded class="pa-2">
                <h5 class="grey-text">ZAR R140.00</h5>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text">Qr Code</h5>
          </v-row>
          <v-row justify="center">
            <v-icon :style="{ 'font-size': '100px' }"> mdi-qrcode </v-icon>
          </v-row>
          <h5 class="grey-text">Address</h5>
          <v-card rounded class="pa-2">
            <h5 class="grey-text">Test Code</h5>
          </v-card>
          <h5 class="grey-text mt-4">Network</h5>
          <v-card rounded class="pa-2">
            <h5 class="grey-text">BTC Native Network</h5>
          </v-card>
          <v-row justify="center" class="mt-4">
            <v-btn dark @click="paying = false"> Cancel </v-btn>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-btn
              @click="
                paying = false;
                confirming = true;
              "
              class="purple-btn"
            >
              I have made the payment
            </v-btn>
          </v-row>
        </v-card>
      </v-row>
    </v-dialog>
    <v-dialog width="500px" v-if="confirming" v-model="confirming">
      <v-row
        v-if="showForm && $route.query.type && $route.query.linkCreated"
        justify="center"
      >
        <v-card class="full-width pa-6" rounded>
          <v-row justify="center">
            <img height="80px" src="../assets/images/Logo.png" />
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text text-center mt-6">Thank you!</h5>
          </v-row>
          <v-row justify="center">
            <h5 class="grey-text text-center mt-6">
              We will monitor your transaction We will notify the merchant
              automatically once payment has been recieved
            </h5>
          </v-row>
          <v-row justify="center" class="mt-6">
            <h5 class="grey-text text-center">
              To start receiving crypto payments
            </h5>
            <h5 class="blue-text pointer ml-2">Click Here</h5>
          </v-row>
          <v-row justify="center">
            <v-btn class="purple-btn mt-6" @click="confirming = false">
              OK
            </v-btn>
          </v-row>
        </v-card>
      </v-row>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showForm: false,
      paymentType: "SingleCharge",
      extraFields: [{}],
      files: [],
      imageUrl: "",
      paying: false,
      confirming: false,
    };
  },
  methods: {
    onChange() {
      this.files = [];
      this.files = [...this.$refs.file.files];
      this.imageUrl = URL.createObjectURL(this.files[0]);
    },
    addFile(file) {
      this.files = [];
      this.files.push(file);
      console.log("Files: ", this.files);
      this.imageUrl = URL.createObjectURL(this.files[0]);
      console.log("Image: ", this.imageUrl);
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
  mounted() {
    this.showForm = true;
  },
};
</script>

<style>
.createpaymentlink .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.dropzone {
  border: none;
}

.createpaymentlink .dropzone .v-input__slot:before {
  display: none;
}

.createpaymentlink .dropzone .v-label {
  left: auto !important;
  color: #0682d4;
  cursor: pointer !important;
}
</style>

<style scoped>
.radio {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>