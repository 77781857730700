<template>
  <v-row justify="center">
    <div
      class="white-card"
      :style="{ 'overflow-y': 'hidden', width: '500px', padding: '10px 10%' }"
    >
      <v-row class="mt-4" justify="center">
        <v-col cols="10" class="pa-0">
          <h4>Top Up</h4>
        </v-col>
        <v-col cols="2" class="pa-0">
          <v-row justify="end">
            <v-icon class="black-text" @click="$emit('close')">
              mdi-close
            </v-icon>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-4 px-6" justify="center">
        <v-col cols="12" sm="6" class="pa-0">
          <h5 class="text-center">Your Balance</h5>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-row justify="center" justify-sm="end">
            <h3 class="blue-text text-center">BTC 0,0000</h3>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-4" justify="center" justify-md="start">
        <h5>Asset</h5>
      </v-row>
      <v-row justify="center">
        <v-select
          :hide-details="true"
          rounded
          outlined
          dense
          label="Select Asset"
        >
        </v-select>
      </v-row>
      <v-row class="mt-4" justify="center" justify-md="start">
        <h5>Network</h5>
      </v-row>
      <v-row justify="center">
        <v-select
          :hide-details="true"
          rounded
          outlined
          dense
          label="Select Network"
        >
        </v-select>
      </v-row>
      <v-row class="mt-4" justify="center" justify-md="start">
        <h5>Deposit Address</h5>
      </v-row>
      <v-row justify="center">
        <div
          class="white-card no-radius full-width mt-2 pa-2 px-4"
          :style="{ 'background-color': '#F7F8FC !important' }"
        >
          <v-row justify="center">
            <v-col cols="12" sm="10" class="pa-0">
              <v-row class="full-height" justify="center" align="center">
                <h5 class="text-center">83hfjouwhciowfoijwefuhddwd</h5>
              </v-row>
            </v-col>
            <v-col cols="2" sm="1" class="pa-0">
              <v-icon class="blue-text"> mdi-dock-window </v-icon>
            </v-col>
            <v-col cols="2" sm="1" class="pa-0">
              <v-icon class="blue-text"> mdi-table </v-icon>
            </v-col>
          </v-row>
        </div>
      </v-row>
      <v-row justify="center">
        <div
          class="white-card no-radius full-width mt-2 pa-4 px-2"
          :style="{ 'background-color': '#FDFAE5 !important' }"
        >
          <v-row justify="center">
            <h6 class="text-center">
              Send only Bitcoin (BTC) to this address using the Bitcoin (native)
              Network
            </h6>
          </v-row>
        </div>
      </v-row>
      <v-row justify="center">
        <v-col cols="5">
          <v-row class="full-height" justify="center" align="center">
            <v-divider></v-divider>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-row justify="center">
            <h5>OR</h5>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row class="full-height" justify="center" align="center">
            <v-divider></v-divider>
          </v-row>
        </v-col>
        <v-row justify="center">
          <div
            class="white-card no-radius full-width mt-2 py-4 px-4"
            :style="{ 'background-color': '#F7F8FC !important' }"
          >
            <v-row justify="center">
              <v-col cols="11" class="pa-0">
                <v-row class="full-height" align="center">
                  <h5>Swap Btc</h5>
                </v-row>
              </v-col>
              <v-col cols="1" class="pa-0">
                <v-row class="full-height" justify="center" align="center">
                  <img src="../assets/images/Ethereum-Logo.png" />
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-row>
      <v-row justify="center" class="mt-6">
        <v-expansion-panels
          accordion
          flat
          :style="{ 'background-color': '#FDFAE5 !important' }"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>Important Notes</v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row justify="center" class="mt-6">
        <v-expansion-panels
          accordion
          flat
          :style="{ 'background-color': '#FDFAE5 !important' }"
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              >Hodl's assets are audited in real time</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>