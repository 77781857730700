import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#001458', // your desired primary color
                secondary: '#df842e', // your desired secondary color
                accent: '#df842e', // your desired accent color
                error: '#f44336', // your desired error color
            },
        },
    },
});
