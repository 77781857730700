<template>
  <v-row class="identity" justify="center">
    <v-card rounded class="white-card full-width pb-8 px-4">
      <v-row justify="center">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="9">
            <h4>HODL Loyalty Levels</h4>
            <h5 class="mt-4">
              The Hodl Loyalty program is a four-tier system, comprising Basic, Silver, Gold, and Platinum levels, which extends benefits depending on the amount of HODL Tokens you hold across your Portfolio Balance.
            </h5>
            <v-row justify="start">
              <v-btn @click="showHowItWorksDialog = true" rounded class="dark-blue-btn my-5 mx-1">How it works</v-btn>
              <v-btn 
                @click="handleBrokerRequest"
                :disabled="brokerRequestSent" 
                rounded 
                class="pink-btn my-5 mx-1"
              >
                {{ brokerRequestSent ? "Broker request sent" : "Become a broker with HODL" }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <img width="150px" src="../assets/images/ReferAFriend.png" />
          </v-col>
        </v-row>
      </v-row>

      <v-row justify="center" class="mt-4">
        <v-col cols="12" sm="8" md="10">
            <v-text-field
      v-model="generatedReferralLink"
      label="Click button to generate your copy link"
      class="dark-blue-text centered-copy-icon"
      rounded
      outlined
      dense
      hide-details
      readonly
      append-outer-icon="mdi-content-copy"
      @click:append-outer="copyReferralLink"
    ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-row class="full-height" justify="center" align="center">
            <v-btn @click="generateReferralLink();getCurrentUserAndSendData();" rounded class="dark-blue-btn" :disabled="generatedReferralLink.length > 0">
              Generate link
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" sm="8" md="10">
          <v-text-field
            v-model="friendEmail"
            label="Enter the email of your friend"
            class="dark-blue-text"
            rounded
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-row class="full-height" justify="center" align="center">
            <v-btn @click="sendEmail" rounded class="dark-blue-btn">Send</v-btn>
          </v-row>
        </v-col>
      </v-row>
      
      <v-row justify="center">
        <v-divider class="mt-3"></v-divider>
        <h4 class="dark-blue-text mx-4">Or</h4>
        <v-divider class="mt-3"></v-divider>
      </v-row>
      
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-row justify="center">
            <v-btn class="dark-blue-text w-140" target="_blank" :href="'https://www.facebook.com/sharer/sharer.php'" outlined rounded>
              <v-icon class="dark-blue-text">mdi-facebook</v-icon> Share
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row justify="center">
            <v-btn class="dark-blue-text w-140" target="_blank" :href="'https://twitter.com/intent/tweet'" outlined rounded>
              <v-icon class="dark-blue-text">mdi-twitter</v-icon> Tweet
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row justify="center">
            <v-btn class="dark-blue-text w-140" target="_blank" :href="'https://www.linkedin.com/sharing/share-offsite/'" outlined rounded><v-icon class="dark-blue-text">mdi-linkedin</v-icon> Post</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- How It Works Dialog -->
    <v-dialog v-model="showHowItWorksDialog" max-width="500">
      <v-card class="dialog-card">
        <v-card-title class="headline">Learn More</v-card-title>
        <v-card-text>
          <p>Refer a friend and earn rewards. Enter your friend's email and if they successfully sign up and start trading you can earn rewards.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="showHowItWorksDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Become a Broker Dialog -->
    <v-dialog v-model="showBecomeBrokerDialog" max-width="500">
      <v-card class="dialog-card">
        <v-card-title class="headline">Broker Request Sent</v-card-title>
        <v-card-text>
          <p>Broker request has been sent to HODL ADMIN and we will notify you soon if you meet the criteria to become a broker.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="showBecomeBrokerDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Email Sent Dialog -->
    <v-dialog v-model="showEmailSentDialog" max-width="500">
      <v-card class="dialog-card">
        <v-card-title class="headline">Email Sent</v-card-title>
        <v-card-text>
          <p>The email has been successfully sent to your friend.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="showEmailSentDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Broker Request Confirmation Dialog -->
    <v-dialog v-model="showBrokerRequestConfirmationDialog" max-width="500">
      <v-card class="dialog-card">
        <v-card-title class="headline">Broker Request Sent</v-card-title>
        <v-card-text>
          <p>Your broker request has been sent successfully! We will notify you once your request has been reviewed.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="showBrokerRequestConfirmationDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import emailjs from "@emailjs/browser";
import { auth } from "@/firebaseConfig";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  where,
  doc,
} from "firebase/firestore";

const EMAILJS_USER_ID = "mr1E1BaGMKZMEzWVu";
const EMAILJS_SERVICE_ID = "service_9esjl09";
const EMAILJS_TEMPLATE_ID = "template_r2guo1c";
const db = getFirestore();

emailjs.init(EMAILJS_USER_ID);

export default {
  data() {
    return {
      friendEmail: "",
      generatedReferralLink: "",
      commissionAmount: 0,
      showHowItWorksDialog: false,
      showBecomeBrokerDialog: false,
      showEmailSentDialog: false,
      showBrokerRequestConfirmationDialog: false, // Added dialog for broker request confirmation
      brokerRequestSent: false, // Flag to track if broker request has been sent
    };
  },
  methods: {
    async fetchReferralLink() {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await this.findUserDocument(user.uid);
        if (userDoc) {
          const docRef = doc(db, userDoc.collectionName, userDoc.docId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() && docSnap.data().referralLink) {
            this.generatedReferralLink = docSnap.data().referralLink;
          } else {
            console.error("Referral link not found or document does not exist.");
          }
        } else {
          console.error("User document not found in any collection.");
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    async sendEmail() {
      if (this.friendEmail && this.generatedReferralLink) {
        try {
          const response = await emailjs.send(
            EMAILJS_SERVICE_ID,
            EMAILJS_TEMPLATE_ID,
            { to_email: this.friendEmail, message: this.generatedReferralLink },
            EMAILJS_USER_ID
          );
          console.log("Email successfully sent!", response);
          this.showEmailSentDialog = true; // Show dialog after email is sent
        } catch (error) {
          console.error("Failed to send email.", error);
        }
      } else {
        console.error("Friend email or referral link is missing.");
      }
    },
    async sendRequestEmail() {
      try {
        const user = auth.currentUser;
        if (user) {
          const templateParams = {
            to_email: "hodlwebsite@gmail.com",
            subject: "Broker Request",
            message: `The following person has requested to become a broker with hodl.\n\nemail address: ${user.email}\n\nKindly See admin section to approve or Deny the request from client `,
          };
          const response = await emailjs.send(EMAILJS_SERVICE_ID, "template_vsd7r98", templateParams, EMAILJS_USER_ID);
          console.log("Request email sent successfully", response);
          this.getCurrentUserAndSendbtokerrequestData();
        } else {
          console.error("No user is currently logged in.");
        }
      } catch (error) {
        console.error("Error sending request email", error);
      }
    },
    async handleBrokerRequest() {
      if (!this.brokerRequestSent) {
        await this.sendRequestEmail();
        this.brokerRequestSent = true;
        this.showBrokerRequestConfirmationDialog = true; // Show confirmation dialog
        await this.updateBrokerRequestStatus(); // Update the status in Firestore
      }
    },
    async updateBrokerRequestStatus() {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await this.findUserDocument(user.uid);
        if (userDoc) {
          const docRef = doc(db, userDoc.collectionName, userDoc.docId);
          await setDoc(docRef, { brokerRequestSent: true }, { merge: true });
        } else {
          console.error("User document not found in any collection.");
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    generateUniqueId() {
      const newId = Math.floor(10000000 + Math.random() * 90000000).toString();
      localStorage.setItem("uniqueId", newId);
      return newId;
    },
    async findUserDocument(uid) {
      const collections = ["individuals", "companies", "trusts"];
      for (let collectionName of collections) {
        const q = query(collection(db, collectionName), where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          return { collectionName, docId: querySnapshot.docs[0].id };
        }
      }
      return null;
    },
    async generateReferralLink() {
      const user = auth.currentUser;
      if (user) {
        const uniqueId = this.generateUniqueId();
        const referralLink = `https://dashboard.hodlotc.com/register?code=${uniqueId}`;
        const userDoc = await this.findUserDocument(user.uid);
        if (userDoc) {
          await setDoc(doc(db, userDoc.collectionName, userDoc.docId), { referralLink }, { merge: true });
          this.generatedReferralLink = referralLink;
        } else {
          console.error("User document not found in any collection.");
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    async getCurrentUserAndSendbtokerrequestData() {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await this.findUserDocument(userId);
        if (userDoc) {
          const docRef = doc(db, userDoc.collectionName, userDoc.docId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (!userData.referralLink) {
              const uniqueId = this.generateUniqueId();
              const referralLink = `https://dashboard.hodlotc.com/register?code=${uniqueId}`;
              await setDoc(docRef, { referralLink }, { merge: true });
              userData.referralLink = referralLink;
            }
            const payload = {
              email: userData.email,
              idNumber: userData.idNumber,
              name: userData.name,
              surname: userData.lastName,
              link: userData.referralLink,
              level: "tier1",
              status: "active",
              date: new Date().toISOString(),
            };
            await this.requestbrokerData(payload);
          } else {
            console.error("User document not found.");
          }
        } else {
          console.error("User document not found in any collection.");
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    async requestbrokerData(userData) {
      try {
        const response = await fetch("https://hodl-vm.northeurope.cloudapp.azure.com/api/BrokerInsert", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        });
        const responseText = await response.text();
        if (!response.ok) throw new Error(responseText);
        console.log("User data successfully sent to the API", JSON.parse(responseText));
      } catch (error) {
        console.error("Error sending user data:", error);
      }
    },
    async getCurrentUserAndSendData() {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await this.findUserDocument(userId);
        if (userDoc) {
          const docRef = doc(db, userDoc.collectionName, userDoc.docId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (!userData.referralLink) {
              const uniqueId = this.generateUniqueId();
              const referralLink = `https://dashboard.hodlotc.com/register?code=${uniqueId}`;
              await setDoc(docRef, { referralLink }, { merge: true });
              userData.referralLink = referralLink;
            }
            const payload = {
              email: userData.email,
              idNumber: userData.idNumber,
              name: userData.name,
              surname: userData.lastName,
              link: userData.referralLink,
              level: "tier1",
              status: "active",
              date: new Date().toISOString(),
            };
            await this.insertUserData(payload);
          } else {
            console.error("User document not found.");
          }
        } else {
          console.error("User document not found in any collection.");
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    async insertUserData(userData) {
      try {
        const response = await fetch("https://hodl-vm.northeurope.cloudapp.azure.com/api/FriendReferredInsert", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        });
        const responseText = await response.text();
        if (!response.ok) throw new Error(responseText);
        console.log("User data successfully sent to the API", JSON.parse(responseText));
      } catch (error) {
        console.error("Error sending user data:", error);
      }
    },
    copyReferralLink() {
    if (this.generatedReferralLink) {
      navigator.clipboard.writeText(this.generatedReferralLink).then(() => {
        this.$toast.success("Referral link copied to clipboard!");
      }).catch(err => {
        console.error("Failed to copy text: ", err);
        this.$toast.error("Failed to copy referral link.");
      });
    }
  },
    async updateCommission() {
      const user = auth.currentUser;
      if (user) {
        try {
          const response = await fetch("https://hodl-vm.northeurope.cloudapp.azure.com/api/CompletedReferralUpdate", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: user.email,
              commission: this.commissionAmount,
            }),
          });
          const responseText = await response.text();
          if (!response.ok) throw new Error(responseText);
          console.log("Commission updated successfully", JSON.parse(responseText));
        } catch (error) {
          console.error("Error updating commission:", error);
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    async checkBrokerRequestStatus() {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await this.findUserDocument(user.uid);
        if (userDoc) {
          const docRef = doc(db, userDoc.collectionName, userDoc.docId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() && docSnap.data().brokerRequestSent) {
            this.brokerRequestSent = true;
          }
        } else {
          console.error("User document not found in any collection.");
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
  },
  mounted() {
    this.fetchReferralLink();
    this.checkBrokerRequestStatus(); // Check if broker request has been sent
  },
};
</script>

<style scoped>
  .w-140 {
    width: 140px;
  }

  .friends-list {
    max-height: 300px;
    overflow-y: scroll;
  }

  .dialog-card {
    background-color: #f9f9f9;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .v-card-title {
    color: #2c3e50;
    font-weight: bold;
  }

  .v-card-text {
    color: #7f8c8d;
    font-size: 16px;
  }

  .v-btn {
    font-weight: bold;
  }

  .dark-blue-btn {
    background-color: #34495e !important;
    color: #ffffff !important;
  }

  .dark-blue-text {
    color: #34495e !important;
  }

  .pink-btn {
    background-color: #e91e63 !important;
    color: #ffffff !important;
  }

  .white-card {
    background-color: #ffffff !important;
    border-radius: 15px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  }

  .white-card-1 {
    background-color: #ffffff !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    padding: 20px !important;
  }
</style>
