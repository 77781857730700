<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="pa-4 white-card d-flex flex-column" height="100%">
          <v-skeleton-loader
            v-if="isLoadingBalances"
            type="card"
            class="white-card"
          ></v-skeleton-loader>
          <template v-else>
            <v-row
              justify="center"
              style="font-family: 'Montserrat', sans-serif; font-size: 22px;"
            >
              Balances:
            </v-row>
            <v-row justify="center">
              <v-select
                justify="center"
                v-model="selectedCurrency"
                :items="currenciesWithBalance"
                label="Select Currency"
                dense
                outlined
                class="my-4"
              ></v-select>
            </v-row>

            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 20px;
                    "
                    >Total Withdrawn funds</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 20px;
                    "
                    class="green--text"
                    >R {{ formatNumber(totalBalance) }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 20px;
                    "
                    >Available Balance</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 20px;
                    "
                    class="green--text"
                    >{{
                      isLoadingAvailableBalance ? "LOADING" : availableBalance
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 20px;
                    "
                    >Locked Balance</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 20px;
                    "
                    class="green--text"
                    >{{ lockedBalance }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-4 white-card d-flex flex-column" height="100%">
          <v-skeleton-loader
            v-if="isLoadingWithdrawalDetails"
            type="card"
            class="white-card"
          ></v-skeleton-loader>
          <template v-else>
            <v-card-title
              style="font-family: 'Montserrat', sans-serif; font-size: 22px;"
              class="my-3"
              >Withdrawal Details</v-card-title
            >
            <v-row class="mb-4" justify="center">
              <v-btn
                :color="isFiat ? 'primary' : 'grey'"
                @click="isFiat = true"
                class="mr-2"
                >Fiat</v-btn
              >
              <v-btn
                :color="!isFiat ? 'primary' : 'grey'"
                @click="isFiat = false"
                >Crypto</v-btn
              >
            </v-row>
            <v-form v-if="isFiat">
              <v-select
                v-model="selectedZarAddress"
                :items="zarAddresses"
                item-text="address"
                item-value="address"
                label="Select ZAR Address"
                class="mb-16"
                outlined
                dense
              ></v-select>
              <v-row class="justify-end">
                <v-btn color="primary" @click="openDialog"
                  >Make a request</v-btn
                >
              </v-row>
            </v-form>
            <v-form v-else>
              <v-select
                v-model="selectedBeneficiary"
                :items="cryptoAddresses"
                item-text="name"
                item-value="name"
                label="Select Beneficiary"
                @change="updateAddressField"
                outlined
                dense
              ></v-select>

              <v-text-field
                label="Coin"
                :value="selectedCoin"
                outlined
                dense
                readonly
              ></v-text-field>
              <v-text-field
                label="Address"
                :value="selectedAddress"
                outlined
                dense
                readonly
              ></v-text-field>
              <v-row class="justify-end">
                <v-btn
                  color="primary"
                  @click="dialog = true"
                  class="ml-auto"
                  rounded
                  >Next</v-btn
                >
              </v-row>
            </v-form>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-row cols="12" md="6">
      <v-card outlined class="pa-4 white-card">
        <v-skeleton-loader
          v-if="isLoadingWithdrawalHistory"
          type="table"
          class="white-card"
        ></v-skeleton-loader>
        <template v-else>
          <v-card-title
            style="font-family: 'Montserrat', sans-serif; font-size: 22px;"
            class="my-3"
            >Withdrawal History</v-card-title
          >
          <v-data-table
            :headers="WithdrawalHeaders"
            :items="filteredWithdrawalHistory"
            class="mt-4"
          ></v-data-table>
        </template>
      </v-card>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Confirm Withdrawal</v-card-title>
        <v-card-text>
          <v-alert type="error" dense>
            Please ensure the wallet details are correct. Withdrawals cannot be
            undone once accepted.
          </v-alert>
          <v-alert v-if="success" type="success" dense>
            Withdrawal submitted successfully!
          </v-alert>
          <v-form>
            <v-text-field
              label="Address"
              :value="selectedAddress"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-text-field
              label="Coin"
              :value="selectedCoin"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-text-field
              label="Available Balance"
              :value="availableBalance"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-text-field
  label="Amount to Withdraw"
  v-model="requestAmount"
  outlined
  dense
  :rules="[v => !v || !isNaN(v) || 'Amount must be a number']"
  :error="!!amountErrorMessage"
  :error-messages="amountErrorMessage"
></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" rounded dark @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" rounded dark @click="submitWithdrawal"
            >Confirm Withdrawal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="requestDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Make a Request</v-card-title>
        <v-card-text>
          <v-alert type="info" dense>
            Please review the details below and enter the amount to withdraw.
          </v-alert>
          <v-form ref="requestForm">
            <v-text-field
              label="Recipient Name"
              :value="recipientName"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-text-field
              label="Bank"
              :value="bank"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-text-field
              label="Account Number"
              :value="accountNumber"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-text-field
              label="Branch Code"
              :value="branchCode"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-select
              v-model="accountType"
              :items="['Savings', 'Checkings']"
              label="Account Type"
              outlined
              dense
            ></v-select>
            <v-text-field
              label="Reference"
              :value="clientSN"
              outlined
              dense
              readonly
            ></v-text-field>
            <v-text-field
              label="Amount to Withdraw"
              v-model="requestAmount"
              outlined
              dense
              :rules="[v => !v || !isNaN(v) || 'Amount must be a number']"
  :error="!!amountErrorMessage"
  :error-messages="amountErrorMessage"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" rounded dark @click="requestDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" rounded dark @click="submitRequest"
            >Submit Request</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import { getFirestore, doc, getDoc } from "firebase/firestore";
  import emailjs from "emailjs-com";
  import { useToast } from "vue-toastification";

  emailjs.init("mr1E1BaGMKZMEzWVu");

  export default {
    data() {
      return {
        isLoadingBalances: true,
        isLoadingWithdrawalDetails: true,
        isLoadingWithdrawalHistory: true,
        isLoadingAvailableBalance: false,
        success: false,
        isFiat: true,
        dialog: false,
        requestDialog: false,
        selectedCurrency: "ZAR",
        currenciesWithBalance: [],
        selectedBeneficiary: null,
        selectedAddress: "",
        selectedCoin: "",
        selectedBeneficiaryid: "",
        withdrawalAmount: "",
        WithdrawalCurrency: "BTC",
        allWithdrawalAddresses: [],
        independentCurrencies: [],
        totalBalance: "",
        availableBalance: "",
        currentWithdrawalAddress: "",
        lockedBalance: "0.00",
        banks: ["Standard Bank", "Bank 1", "Bank 2", "Bank 3"],
        WithdrawalHeaders: [
          {
            text: "Date",
            value: "created_at",
            sortable: false,
            align: "start",
          },
          { text: "Asset", value: "currency" },
          { text: "Amount", value: "amount" },
          { text: "Status", value: "state" },
        ],
        WithdrawalHistory: [],
        page: 1,
        pages: 10,
        clientSN: "", // Initialize clientSN here
        zarAddresses: [], // Initialize an array to store ZAR addresses
        selectedZarAddress: "", // Selected ZAR address
        selectedZarAddressDetails: null, // Details of the selected ZAR address
        requestAmount: "", // Amount to withdraw
        recipientName: "",
        bank: "",
        accountNumber: "",
        branchCode: "",
        accountType: "Savings", // Default to "savings"
        reference: "",
        cryptoAddresses: [], 
        requestAmount: "", // Amount to withdraw
        amountErrorMessage: "",
      };
    },
    created() {
      this.fetchUserData();
    },
    computed: {
      filteredWithdrawalHistory() {
        return this.WithdrawalHistory.filter(
          (withdrawal) => withdrawal.sn === this.clientSN
        );
      },
    },
    watch: {
      selectedCurrency(newVal) {
        this.fetchAvailableBalance(newVal);
      },
      selectedCoin(newVal) {
        if (newVal) {
          this.selectedCurrency = newVal;
        }
      },
      selectedBeneficiary(newVal) {
        this.updateAddressField();
        this.updateCurrencyField();
        this.updateBeneficiaryidField();
      },
      selectedZarAddress(newVal) {
        this.populateZarAddressDetails(newVal);
      },
    },
    methods: {
      async fetchUserData() {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const uid = user.uid;
            const db = getFirestore();
            const collections = ["individuals", "companies", "trusts"];
            for (let collection of collections) {
              try {
                const docRef = doc(db, collection, uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists() && docSnap.data().clientSN) {
                  this.clientSN = docSnap.data().clientSN;
                  this.fetchCurrenciesWithBalances();
                  this.fetchAvailableBalance(this.selectedCurrency);
                  this.fetchWithdrawals();
                  this.fetchAllWithdrawalAddresses();
                  break;
                }
              } catch (error) {
                console.error("Error fetching clientSN:", error);
              }
            }
          } else {
            console.log("User is signed out");
          }
        });
      },
      processData(data) {
        this.WithdrawalHistory = data.map((item) => ({
          ...item,
          currency: item.currency.toUpperCase(),
          created_at: this.formatDate(item.created_at),
          state: this.capitalizeFirstLetter(item.state),
          amount: parseFloat(item.amount.replace(/,/g, "")),
        }));
        this.totalBalance = this.WithdrawalHistory.reduce(
          (acc, item) => acc + item.amount,
          0
        ).toFixed(2);
        console.log("Updated total balance:", this.totalBalance);
        this.isLoadingBalances = false;
      },
      async fetchWithdrawals() {
        try {
          console.log("Fetching withdrawals...");
          const withdrawalResponse = await fetch(
            `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetWithdrawalClients?sn=${this.clientSN}`
          );
          const withdrawals = await withdrawalResponse.json();

          // Filter the withdrawals based on clientSN
          const filteredWithdrawals = withdrawals.filter(
            (withdrawal) => withdrawal.sn === this.clientSN
          );

          console.log("Filtered withdrawals:", filteredWithdrawals);
          this.processData(filteredWithdrawals);
          this.isLoadingWithdrawalHistory = false;
        } catch (error) {
          console.error("Failed to fetch withdrawals:", error);
          this.isLoadingWithdrawalHistory = false;
        }
      },
      async fetchAllWithdrawalAddresses() {
        try {
          if (!this.clientSN) {
            this.error = "Client SN is not available.";
            return;
          }

          const response = await fetch(
            `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetBeneficiarybysn?sn=${this.clientSN}`
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("API Response:", data);

          const allData = data.map((beneficiary) => ({
            beneficiaryid: beneficiary.beneficiary_id,
            coin: beneficiary.currency_id.toUpperCase(),
            address: beneficiary.rid,
            name: `${beneficiary.beneficiary_id} - ${beneficiary.name}`,
            bank: beneficiary.bank_name,
            accountNumber: beneficiary.account_number,
            branchCode: beneficiary.branch_code,
            accountType: beneficiary.account_type,
            reference: beneficiary.reference,
          }));

          // Store ZAR addresses separately and log them
          this.zarAddresses = allData.filter((item) => item.coin === "ZAR");
          console.log("ZAR addresses:", this.zarAddresses);

          // Store crypto addresses separately and log them
          this.cryptoAddresses = allData.filter((item) => item.coin !== "ZAR");
          console.log("Crypto addresses:", this.cryptoAddresses);

          this.beneficiaryid = [...allData];
          this.addresses = [...allData];
          this.coin = [...allData];
          console.log(
            "Withdrawal addresses fetched successfully:",
            this.addresses
          );
          this.isLoadingWithdrawalDetails = false;

          // Auto-select the first ZAR address
          if (this.zarAddresses.length > 0) {
            this.selectedZarAddress = this.zarAddresses[0].address;
            this.populateZarAddressDetails(this.selectedZarAddress);
          }

          // Auto-select the first beneficiary
          if (this.cryptoAddresses.length > 0) {
            this.selectedBeneficiary = this.cryptoAddresses[0].name;
            this.updateAddressField();
            this.updateCurrencyField();
            this.updateBeneficiaryidField();
          }

        } catch (error) {
          console.error("Error fetching withdrawal addresses:", error);
          this.error =
            error.message ||
            "An error occurred while fetching withdrawal addresses.";
          this.isLoadingWithdrawalDetails = false;
        }
      },
      populateZarAddressDetails(selectedAddress) {
        if (selectedAddress) {
          const selectedObj = this.zarAddresses.find(
            (addr) => addr.address === selectedAddress
          );

          if (selectedObj) {
            const addressParts = selectedObj.address.split(',').map(part => part.trim());
            this.recipientName = addressParts[0];
            this.bank = addressParts[1];
            this.accountNumber = addressParts[2];
            this.branchCode = addressParts[3];
          } else {
            this.recipientName = "";
            this.bank = "";
            this.accountNumber = "";
            this.branchCode = "";
          }
        } else {
          this.recipientName = "";
          this.bank = "";
          this.accountNumber = "";
          this.branchCode = "";
        }
      },
      updateAddressField() {
        const selectedObj = this.cryptoAddresses.find(
          (addr) => addr.name === this.selectedBeneficiary
        );

        if (selectedObj) {
          this.selectedAddress = selectedObj.address;
        } else {
          this.selectedAddress = "";
        }
      },
      updateCurrencyField() {
        const selectedObj = this.cryptoAddresses.find(
          (addr) => addr.name === this.selectedBeneficiary
        );
        if (selectedObj) {
          this.selectedCoin = selectedObj.coin;
        } else {
          this.selectedCoin = "";
        }
      },
      updateBeneficiaryidField() {
        const selectedObj = this.beneficiaryid.find(
          (addr) => addr.name === this.selectedBeneficiary
        );
        if (selectedObj) {
          this.selectedBeneficiaryid = selectedObj.beneficiaryid;
        } else {
          this.selectedBeneficiaryid = "";
        }
      },
      async fetchCurrenciesWithBalances() {
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
        };
        try {
          const response = await fetch(
            `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetAccounts`,
            requestOptions
          );
          const allData = await response.json();
          this.currenciesWithBalance = allData
            .filter(
              (account) =>
                account.sn === this.clientSN && parseFloat(account.balance) > 0
            )
            .map((account) => account.currency.toUpperCase())
            .filter((value, index, self) => self.indexOf(value) === index);
          this.isLoadingBalances = false; // Stop loading balances after fetching data

          // Auto-select the first currency
          if (this.currenciesWithBalance.length > 0) {
            this.selectedCurrency = this.currenciesWithBalance[0];
            this.fetchAvailableBalance(this.selectedCurrency);
          }

        } catch (error) {
          console.error("Error fetching currencies with balances:", error);
          this.isLoadingBalances = false; // Stop loading even if there is an error
        }
      },
      async fetchAvailableBalance(currency) {
        if (!this.clientSN) {
          console.error("Client SN is not available.");
          return;
        }
        this.isLoadingAvailableBalance = true;
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
        };
        try {
          const response = await fetch(
            `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetAccounts`,
            requestOptions
          );
          const allData = await response.json();
          const filteredData = allData.find(
            (account) =>
              account.sn === this.clientSN &&
              account.currency.toUpperCase() === currency
          );
          this.availableBalance = filteredData ? filteredData.balance : "0.00";
          this.isLoadingAvailableBalance = false; // Stop loading balances after fetching data
        } catch (error) {
          console.error("Failed to fetch available balance:", error);
          this.availableBalance = "Error fetching balance";
          this.isLoadingAvailableBalance = false; // Stop loading even if there is an error
        }
      },
      openDialog() {
        this.requestDialog = true;
      },
      openDialog() {
    this.requestDialog = true;
  },
  async submitRequest() {
    // Check if the amount is a valid number
    if (!this.requestAmount || isNaN(this.requestAmount)) {
      this.amountErrorMessage = "Amount must be a number";
      return;
    } else {
      this.amountErrorMessage = ""; // Clear error message if valid
    }

    const message = `
      Recipient Name: ${this.recipientName}\n
      Bank: ${this.bank}\n
      Account Number: ${this.accountNumber}\n
      Branch Code: ${this.branchCode}\n
      Account Type: ${this.accountType}\n
      Reference: ${this.clientSN}\n
      Amount: ${this.requestAmount}
    `;

    const templateParams = {
      message,
    };

    emailjs
      .send("service_9esjl09", "template_tdikqpu", templateParams)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          this.requestDialog = false; // Close the dialog after successful submission
          this.showSuccessToast("Request sent successfully!"); // Show success toast
        },
        (error) => {
          console.error("FAILED...", error);
        }
      );
  },

  
      async submitWithdrawal() {
        if (!this.withdrawalAmount) {
          this.$refs.withdrawalForm.validate(); // Ensure this references a valid form if using refs
          return;
        }

        const apiUrl =
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/CreateWithdrawalClients";
        const params = new URLSearchParams({
          benID: this.selectedBeneficiaryid,
          amount: this.withdrawalAmount,
        });

        console.log(
          "Attempting to submit withdrawal with amount:",
          this.withdrawalAmount
        );
        console.log("Beneficiary ID:", this.selectedBeneficiaryid);

        try {
          const response = await fetch(`${apiUrl}?${params.toString()}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Withdrawal submitted successfully:", data);
          this.dialog = false; // Close dialog after successful submission
          this.success = true; // Set success to true to show the success alert

          // Hide the success alert after a few seconds
          setTimeout(() => {
            this.success = false;
          }, 5000);

          // Show success toast
          this.$toast.success("Withdrawal submitted successfully!", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } catch (error) {
          console.error("Error submitting withdrawal:", error);
        }
      },

      formatDate(dateStr) {
        const date = new Date(dateStr);
        return `${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")} ${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      },
      capitalizeFirstLetter(text) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      },
      formatNumber(number) {
        return Number(number).toLocaleString();
      },
    },
  };
</script>

<style scoped>
  .v-card-title {
    font-weight: bold;
    color: var(--v-theme-primary);
  }
  .v-btn.icon {
    border-radius: 50%;
  }
  .elevation-10 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  .white-card {
    background: #ffffff;
    border-radius: 15px;
    width: 100%;
  }
  body {
    background: #f5f5f5;
    color: #333;
    font-family: "Roboto", sans-serif;
  }
  .v-container {
    padding: 20px;
  }
  .v-alert {
    background: rgba(255, 0, 0, 0.1);
    color: #000000;
    border-radius: 10px;
  }
  .error--outline .v-input__control .v-input__slot {
  border-color: red !important;
}
</style>
