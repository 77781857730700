var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"view-currency pa-0 full-width"},[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"white-card pb-8 px-12 no-radius full-width",style:({ 'overflow-y': 'hidden' })},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('h2',[_vm._v("Transfer Between Wallets")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-icon',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-select',{attrs:{"rounded":"","outlined":"","dense":"","label":"Select Currency"}})],1),_c('v-col',{staticClass:"pa-0",staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{staticClass:"full-width pa-2 no-radius",attrs:{"outlined":""}},[_c('h5',[_vm._v("From "+_vm._s(_vm.wallet1)+" Wallet")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{staticClass:"full-width pa-2 no-radius",attrs:{"outlined":""}},[_c('h5',[_vm._v("To "+_vm._s(_vm.wallet2)+" Wallet")])])],1),_c('v-card',{staticClass:"top absolute",style:({
            right: '10%',
            top: '20%',
            width: '10%',
            height: '50%',
          }),attrs:{"outlined":""},on:{"click":function($event){return _vm.switchWallets()}}},[_c('v-row',{staticClass:"full-height",attrs:{"justify":"center","align":"center"}},[_c('v-icon',[_vm._v(" mdi-swap-vertical ")])],1)],1)],1),_c('v-row',{staticClass:"relative",attrs:{"justify":"center"}},[_c('v-text-field',{staticClass:"full-width mt-10",attrs:{"hide-details":true,"outlined":"","dense":"","label":"Amount"}}),_c('v-btn',{staticClass:"blue-card absolute",style:({
            top: '45px',
            right: '10px',
            height: '25px',
          }),attrs:{"rounded":"","dense":""}},[_vm._v(" Max ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0"},[_c('h5',[_vm._v("Available For Transfer")])]),_c('v-col',{staticClass:"pa-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('h5',{staticClass:"dark-blue-text"},[_vm._v("BTC 0.34759018")])])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"dark-blue-btn full-width mt-6",attrs:{"rounded":"","dense":""}},[_vm._v(" Transfer ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }