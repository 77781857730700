<template>
  <v-container class="merchants pa-8 full-width">
    <transition name="slide">
      <v-row v-if="showForm" justify="center">
        <v-col cols="12" md="3">
          <v-row justify="center">
            <h4>Payment Links</h4>
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <div class="white-card full-width pa-0 pt-8">
            <v-row justify="center">
              <v-col cols="12" md="6" class="pa-0">
                <v-row
                  class="full-height"
                  justify="center"
                  justify-md="start"
                  align="center"
                >
                  <h4 class="mx-12">Payment Links</h4>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" class="pa-0">
                <v-row class="full-height" justify="center" align="center">
                  <v-col cols="12" md="6" class="pa-0">
                    <v-row justify="center">
                      <v-btn
                        class="purple-btn my-2"
                        @click="$router.push('/transactionhistory')"
                      >
                        Transactions
                      </v-btn>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-0">
                    <v-row justify="center">
                      <v-btn
                        @click="$router.push('/createpaymentlink')"
                        class="purple-btn my-2"
                      >
                        New Payment Link
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-1 full-width mt-6"
              >
                <template v-slot:item.PageName="{ item }">
                  <v-row align="center">
                    <p class="grey-text">GPU Mining rig& NFT Payments</p>
                  </v-row>
                </template>
                <template v-slot:item.Amount="{ item }">
                  <v-row align="center">
                    <p class="grey-text">-</p>
                  </v-row>
                </template>
                <template v-slot:item.LinkType="{ item }">
                  <v-row align="center">
                    <p class="grey-text">Single Charge</p>
                  </v-row>
                </template>
                <template v-slot:item.PaymentLink="{ item }">
                  <v-row align="center">
                    <v-btn class="grey-text" outlined dense>
                      Preview Page
                    </v-btn>
                  </v-row>
                </template>
                <template v-slot:item.DateCreated="{ item }">
                  <v-row align="center">
                    <p class="grey-text">9 Oct 2022</p>
                  </v-row>
                </template>
                <template v-slot:item.TransactionDetails="{ item }">
                  <v-row align="center">
                    <v-icon class="purple-text"> mdi-search-web </v-icon>
                  </v-row>
                </template>
              </v-data-table>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </transition></v-container
  >
</template>

<script>
export default {
  data() {
    return {
      showForm: false,
      items: [""],
      headers: [
        {
          text: "Page Name",
          align: "start",
          sortable: false,
          value: "PageName",
        },
        {
          text: "Amount",
          align: "start",
          sortable: false,
          value: "Amount",
        },
        {
          text: "Link Type",
          align: "start",
          sortable: false,
          value: "LinkType",
        },
        {
          text: "Payment Link",
          align: "start",
          sortable: false,
          value: "PaymentLink",
        },
        {
          text: "Date Created",
          align: "start",
          sortable: false,
          value: "DateCreated",
        },
        {
          text: "Transaction Details",
          align: "start",
          sortable: false,
          value: "TransactionDetails",
        },
      ],
    };
  },
  mounted() {
    this.showForm = true;
  },
};
</script>

<style>
</style>