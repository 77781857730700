<template>
  <v-row class="account-info" justify="center">
    <v-col>
      <v-card rounded class="white-card full-width pb-8">
        <h3 class="mx-3">Account Information</h3>

        <v-row justify="center">
          <!-- Email Section -->
          <v-col cols="12" sm="6" md="6">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-email</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Email</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your Email Address</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData ? accountData.email : 'Loading...'">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- Name Section -->
          <v-col cols="12" sm="6" md="6">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large
                  >mdi-account</v-icon
                >
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Name</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">User Name</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData ? accountData.name : 'Loading...'">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.middleName">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-account-tie</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Middle Name</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your Middle Name</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.middleName">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- Last Name Section -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.lastName">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-account-box</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Last Name</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your Last Name</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.lastName">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- ID Num Section -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.IdNum">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-card-account-details</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>ID Number</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your ID Number</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.IdNum">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.passportNumber">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-passport</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Passport NUMBER</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Passport Number</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.passportNumber">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!--  state Section -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.provinceState">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large
                  >mdi-map-marker</v-icon
                >
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Province</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Current Province</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.provinceState">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.suburb">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large
                  >mdi-home-group</v-icon
                >
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Suburb</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Current Suburb</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.suburb">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.streetName">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large
                  >mdi-road-variant</v-icon
                >
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Street Name</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Current Street Name</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.streetName">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.streetNumber">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large
                  >mdi-home-outline</v-icon
                >
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Street Number</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Current Street Number</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.streetNumber">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- Company Name Section -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.companyName">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-office-building</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Company Name</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your Company Name</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.companyName">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- Country Section -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.country">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-flag</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Country</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your Country</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="countryName">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- Phone Number Section -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-if="accountData && accountData.phoneNumber">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-phone</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>Phone Number</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your Phone Number</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.phoneNumber">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- Company Registration Number Section -->
          
          <!-- Company VAT Number Section -->
          <v-col cols="12" sm="6" md="6" v-if="accountData && accountData.city">
            <v-card outlined class="pa-4">
              <v-row align="start">
                <v-icon class="blue-text" large>mdi-city</v-icon>
                <v-col class="py-0">
                  <v-row justify="start">
                    <h4>CITY</h4>
                  </v-row>
                  <v-row justify="start">
                    <h5 class="mt-6">Your City</h5>
                  </v-row>
                  <v-row justify="start">
                    <v-text-field
                      :style="{ 'background-color': '#EEEEEE' }"
                      rounded
                      :hide-details="true"
                      readonly
                      :value="accountData.city">
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- Company VAT Number Section -->
          <!-- Document Download Section with Dropdown -->
          <v-row justify="center">
            <v-col cols="12" md="7">
              <v-card outlined class="pa-4">
                <v-row align="start">
                  <v-icon class="blue-text" large>mdi-file-download-outline</v-icon>
                  <v-col class="py-0">
                    <h4>Download Documents</h4>
                    <h5>Access and download your uploaded documents.</h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-select
                    v-model="selectedDocument"
                    :items="documentItems"
                    label="Select Document"
                    solo
                    rounded
                    outlined
                    dense
                    class="mt-2">
                  </v-select>
                  <v-btn
                    v-if="selectedDocument"
                    @click="downloadDocument(selectedDocument)"
                    rounded
                    class="dark-blue-btn mt-2">
                    Download
                  </v-btn>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {
    getFirestore,
    query,
    where,
    collection,
    getDocs,
  } from "firebase/firestore";
  import { auth } from "../firebaseConfig.js"; // Update the path as needed
  import countriesData from "@/assets/countries/countries.json";

  export default {
    data() {
      return {
        accountData: null,
        selectedDocument: null,
        countries: countriesData.map((country) => ({
          text: country.name,
          value: country.code,
        })),
        documentItems: [
          { text: "ID Card Front", value: "idCardFrontUrl" },
          { text: "ID Card Back", value: "idCardBackUrl" },
          { text: "Proof of Residence", value: "proofOfResidenceUrl" },
          { text: "Bank Account Proof", value: "bankAccountProofUrl" },
          { text: "Selfie", value: "selfieUrl" }
        ],
      };
    },
    computed: {
      countryName() {
        const country = this.countries.find(
          (c) => c.value === this.accountData.country
        );
        return country ? country.text : "Unknown Country";
      },
    },
    methods: {
      async fetchAccountData() {
        const db = getFirestore();
        const userEmail = auth.currentUser.email;
        let accountCollections = ["individuals", "companies", "trusts"];
        let accountDataFound = false;

        for (let collectionName of accountCollections) {
          if (accountDataFound) break;
          let accountCollectionRef = collection(db, collectionName);
          let q = query(accountCollectionRef, where("email", "==", userEmail));
          let querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            this.accountData = querySnapshot.docs[0].data();
            accountDataFound = true;
          }
        }

        if (!accountDataFound) {
          console.log("No account found for this email.");
        } 
      },

      async downloadDocument(documentType) {
        let docType =
          documentType && documentType.value
            ? documentType.value
            : documentType;
        if (this.accountData && this.accountData[docType]) {
          const url = this.accountData[docType];
          window.open(url, "_blank");
        } else {
          alert("Document not available.");
        }
      },
    },
    created() {
      this.fetchAccountData();
    },
  };
</script>

<style scoped>
  .account-info .white-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f7f7f7;
    border-radius: 15px;
    transition: box-shadow 0.3s ease;
  }

  .info-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .account-info h4 {
    font-weight: bold;
    margin-top: 15px;
  }

  .account-info h5 {
    color: #666;
    margin-top: 20px;
  }

  .info-icon {
    color: #009688; /* Teal color for icons */
    margin-bottom: 10px;
  }
</style>
