import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Account from '../views/Account.vue';
import VerifyAccount from '../views/VerifyAccount.vue';
import TransactionHistory from '../views/TransactionHistory.vue';
import Exchange from '../views/Exchange.vue';
import Markets from '../views/Markets.vue';
import Merchants from '../views/Merchants.vue';
import ViewCurrency from '../views/ViewCurrency.vue';
import Trade from '../views/Trade.vue';
import AddressBook from '../views/AddressBook.vue';
import CreatePaymentLink from '../views/CreatePaymentLink.vue';
import Options from '../views/Options.vue';
import Deposit from '../views/Deposit.vue';
import Withdrawal from '../views/Withdrawal.vue';
import Security from '../views/Security.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import LoginInformation from '../views/LoginInformation.vue';
import Authentication from '../views/2FactorAuthentication.vue';
import TwoFAModal from '../views/TwoFAModal.vue';
import MaintenanceMode from '../views/MaintenanceMode.vue'; // Import the MaintenanceMode component
import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";
import { auth } from "../firebaseConfig.js";
import { onAuthStateChanged } from 'firebase/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/maintenance',
    name: 'MaintenanceMode',
    component: MaintenanceMode,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/twofamodal',
    name: 'TwoFAModal',
    component: TwoFAModal,
    props: (route) => ({ userEmail: route.query.userEmail }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/VerifyAccount',
    name: 'VerifyAccount',
    component: VerifyAccount,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactionhistory',
    name: 'Transaction History',
    component: TransactionHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/exchange',
    name: 'Exchange',
    component: Exchange,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/markets',
    name: 'Markets',
    component: Markets,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/merchant',
    name: 'Merchants',
    component: Merchants,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/viewcurrency',
    name: 'View Currency',
    component: ViewCurrency,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/trade',
    name: 'Trade',
    component: Trade,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addressbook',
    name: 'Address Book',
    component: AddressBook,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createpaymentlink',
    name: 'Create Payment Link',
    component: CreatePaymentLink,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/options',
    name: 'Options',
    component: Options,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/security',
    name: 'Security',
    component: Security,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logininformation',
    name: 'Login Information',
    component: LoginInformation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/2factorauth',
    name: 'Authentication',
    component: Authentication,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "*",
    redirect: "/maintenance", // This wildcard route will redirect all other routes to the maintenance page
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Maintenance mode flag
const isMaintenanceMode = false; // Set this flag to true for maintenance mode, false to disable

router.beforeEach(async (to, from, next) => {
  if (isMaintenanceMode && to.path !== '/maintenance') {
    next('/maintenance'); // Redirect all routes to the maintenance page
  } else {
    const requiresAuth = to.matched.some(route => route.meta.requiresAuth);
    const user = await getUser();

    if (requiresAuth && !user) {
      next('/');
    } else if (to.name === 'Usermanagement') {
      const userEmail = user ? user.email : null;
      const userRole = userEmail ? await fetchUserRole(userEmail) : null;

      if (userRole === 'Admin') {
        next();
      } else {
        next('/'); 
      }
    } else {
      next();
    }
  }
});

const fetchUserRole = async (email) => {
  const db = getFirestore();
  let role = null;

  // Query 'individuals' collection
  const individualsQuery = query(collection(db, "individuals"), where("email", "==", email));
  const individualsSnapshot = await getDocs(individualsQuery);
  if (!individualsSnapshot.empty) {
    role = individualsSnapshot.docs[0].data().role;
  } else {
    // Query 'companies' collection
    const companiesQuery = query(collection(db, "companies"), where("email", "==", email));
    const companiesSnapshot = await getDocs(companiesQuery);
    if (!companiesSnapshot.empty) {
      role = companiesSnapshot.docs[0].data().role;
    } else {
      // Query 'trusts' collection
      const trustsQuery = query(collection(db, "trusts"), where("email", "==", email));
      const trustsSnapshot = await getDocs(trustsQuery);
      if (!trustsSnapshot.empty) {
        role = trustsSnapshot.docs[0].data().role;
      }
    }
  }

  return role;
};

const getUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export default router;
