<template>
  <v-container>
    <v-card class="white-card">
      <v-toolbar-title class="headline font-weight-bold dark-blue-text">
        Dashboard
      </v-toolbar-title>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>

      <div v-if="!loading && currentUserEmailExistsInBrokers">
        <v-card-title class="table-title">Brokers Table</v-card-title>
        <v-card-text>
          <v-data-table
            v-if="filteredEmails.length > 0"
            :headers="brokers"
            :items="filteredEmails"
            class="elevation-1 modern-table"
            :item-class="'table-item'"
            hide-default-footer
          ></v-data-table>
          <v-card v-else class="elevation-1 mt-4">
            <v-card-title class="text-center">Please refer a friend to earn rewards</v-card-title>
            <v-card-actions class="justify-center">
              <v-btn @click="redirectToReferFriend" class="dark-blue-btn">Refer a Friend</v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>

        <!-- Commission Slider -->
        <v-row justify="center" class="mt-4">
          <v-col cols="12" sm="8" md="10">
            <v-slider
              v-model="commissionAmount"
              :min="0"
              :max="100"
              label="Commission Amount (%)"
              class="dark-blue-text"
              thumb-label
              @change="openConfirmationDialog"
            ></v-slider>
          </v-col>
        </v-row>

        <!-- Total Balance and Payout Button -->
        <v-row justify="center" class="mt-4 total-balance-row">
          <v-col cols="12" sm="8" md="10" class="text-right">
            <v-row align="center">
              <v-col cols="6" class="text-left">
                <v-btn @click="openPayoutDialog" rounded class="dark-blue-btn">Request Payout</v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4 class="dark-blue-text total-balance">Total Balance: R{{ totalBalance }}</h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-card v-else-if="!loading && !currentUserEmailExistsInBrokers">
        <v-card-title class="table-title">Friends Table</v-card-title>
        <v-card-text>
          <v-data-table
            v-if="filteredEmails.length > 0"
            :headers="friends"
            :items="filteredEmails"
            class="elevation-1 modern-table"
            :item-class="'table-item'"
            hide-default-footer
          ></v-data-table>
          <v-card v-else class="elevation-1 mt-4">
            <v-card-title class="text-center">Please refer a friend to earn rewards</v-card-title>
            <v-card-actions class="justify-center">
              <v-btn @click="redirectToReferFriend" class="dark-blue-btn">Refer a Friend</v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="showConfirmationDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirm Commission Update</v-card-title>
        <v-card-text>
          Are you sure you want to update the commission amount to {{ commissionAmount }}%?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmUpdateCommission">Yes</v-btn>
          <v-btn color="red darken-1" text @click="showConfirmationDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Payout Confirmation Dialog -->
    <v-dialog v-model="showPayoutDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirm Payout Request</v-card-title>
        <v-card-text>
          Are you sure you want to claim a payout of R{{ payoutAmount }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmPayout">Yes</v-btn>
          <v-btn color="red darken-1" text @click="showPayoutDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import emailjs from "@emailjs/browser";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";

emailjs.init("mr1E1BaGMKZMEzWVu");

export default {
  data() {
    return {
      friendEmail: "",
      friends: [
        { text: "Email", value: "email" },
        { text: "Balance", value: "balance" }
      ],
      brokers: [
        { text: "Email", value: "email" },
        { text: "Balance", value: "balance" }
      ],
      accountData: {},
      referralLink: "",
      filteredEmails: [],
      loading: false,
      currentUserEmail: '',
      items: [],
      totalItems: 0,
      commissionAmount: 0,
      showConfirmationDialog: false, // Add this for dialog visibility
      showPayoutDialog: false, // Controls the payout confirmation dialog visibility
      payoutAmount: 0, // Stores the amount the user wants to claim
    };
  },
  computed: {
    currentUserEmailExistsInBrokers() {
      return this.items.some(item => item.email === this.currentUserEmail);
    },
    totalBalance() {
      return this.filteredEmails.reduce((total, item) => total + item.balance, 0);
    },
  },
  methods: {
    async fetchAccountData() {
      const auth = getAuth();
      const db = getFirestore();
      const userEmail = auth.currentUser ? auth.currentUser.email : null;
      if (!userEmail) {
        console.error("No user logged in.");
        return;
      }
      this.currentUserEmail = userEmail;

      let accountCollections = ["individuals", "companies", "trusts"];
      let accountDataFound = false;

      for (let collectionName of accountCollections) {
        if (accountDataFound) break;
        let accountCollectionRef = collection(db, collectionName);
        let q = query(accountCollectionRef, where("email", "==", userEmail));
        let querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          this.accountData = userData;
          if (userData.referralLink) {
            this.referralLink = userData.referralLink;
            console.log(this.referralLink);
          }
          accountDataFound = true;
          break;
        }
      }

      if (!accountDataFound) {
        console.log("Account data not found in any collections.");
      }
    },
    async fetchFilteredReferralData() {
      if (!this.referralLink) {
        console.error("Referral link is not set.");
        return;
      }

      const endpoint = `https://hodl-vm.northeurope.cloudapp.azure.com/api/CompletedReferralSelectFiltered`;
      const url = new URL(endpoint);
      url.searchParams.append("link", this.referralLink);

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.filteredEmails = data.map((item) => ({
          email: item.email,
          balance: item.balance
        }));
      } catch (error) {
        console.error("Error fetching filtered referral data:", error);
      }
    },
    async fetchItems() {
      this.loading = true;
      const requestOptions = {
        method: "GET",
        headers: {},
        redirect: "follow",
      };

      try {
        const response = await fetch("https://hodl-vm.northeurope.cloudapp.azure.com/api/BrokerSelect", requestOptions);
        if (!response.ok) throw new Error("Network response was not ok.");
        const data = await response.json();
        this.items = data.map((item) => ({
          date: item.date,
          email: item.email,
          idNumber: item.idNumber,
          name: item.name,
          surname: item.surname,
          level: item.level,
          status: item.status,
          link: item.link,
          balance: item.balance
        }));
        this.totalItems = data.length;
      } catch (error) {
        console.error("Error fetching items:", error);
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    openConfirmationDialog() {
      this.showConfirmationDialog = true;
    },
    async confirmUpdateCommission() {
      this.showConfirmationDialog = false;
      const user = getAuth().currentUser;
      if (user) {
        try {
          const response = await fetch("https://hodl-vm.northeurope.cloudapp.azure.com/api/CompletedReferralUpdate", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: user.email,
              commission: this.commissionAmount,
            }),
          });

          const responseText = await response.text();

          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            const data = JSON.parse(responseText);
            console.log("Commission updated successfully", data);
          } else {
            console.log("Received response:", responseText);
          }

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

        } catch (error) {
          console.error("Error updating commission:", error);
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    openPayoutDialog() {
      this.payoutAmount = this.totalBalance; // Set the payout amount to the total balance
      this.showPayoutDialog = true; // Open the dialog
    },
    async confirmPayout() {
      this.showPayoutDialog = false; // Close the dialog
      await this.requestPayout(); // Proceed with the payout request
    },
    async requestPayout() {
      const user = getAuth().currentUser;
      if (user) {
        try {
          const templateParams = {
            to_email: "payout@example.com",
            subject: "Payout Request",
            message: `User ${user.email} has requested a payout. Total Balance: R${this.payoutAmount}`,
          };
          const response = await emailjs.send("service_9esjl09", "template_l0c1mu3", templateParams);
          console.log("Payout request email sent successfully", response);
        } catch (error) {
          console.error("Error sending payout request email:", error);
        }
      } else {
        console.error("No user is currently logged in.");
      }
    },
    redirectToReferFriend() {
      this.$router.push('/options?section=ReferaFriend');
    },
    sendFriendEmail() {
      // Implementation remains unchanged
    },
  },
  async created() {
    await this.fetchItems();
    this.fetchAccountData();
  },
  watch: {
    referralLink(newVal) {
      if (newVal) {
        this.fetchFilteredReferralData();
      }
    },
  },
};
</script>

<style scoped>
.white-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.dark-blue-text {
  color: #0d47a1;
}

.dark-blue-btn {
  background-color: #0d47a1;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 24px;
  border-radius: 25px;
}

.table-title {
  color: #0d47a1;
  font-size: 24px;
  font-weight: bold;
}

.modern-table {
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.table-item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.v-data-table-header th {
  background-color: #0d47a1;
  color: #ffffff;
  font-size: 18px;
}

.v-data-table {
  border-radius: 15px;
  overflow: hidden;
}

.v-data-table__wrapper {
  border-radius: 15px;
}

.v-data-table-header th:nth-child(1) {
  background-color: #ff5722;
}

.v-data-table-header th:nth-child(2) {
  background-color: #4caf50;
}

/* Additional Styling */
.total-balance-row {
  margin-top: 20px;
}

.total-balance {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
</style>
